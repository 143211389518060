const resources = {
  es: {
    translation: {
      Accept: 'Aceptar',
      Accepted: 'Aceptado',
      AccountCreated: 'Cuenta creada.',
      Actions: 'Opciones',
      Add: 'Añadir',
      AddAppointment: 'Añadir Cita',
      AddClosedDays: 'Añadir Días Cerrados',
      AddCompany: 'Añadir Empresa',
      AddCustomer: 'Añadir Cliente',
      AddDayOff: 'Añadir Día Libre',
      AddNewCustomer: 'Añadir Nuevo Cliente',
      Address: 'Dirección',
      AddressOptional: 'Dirección (opcional)',
      AddService: 'Añadir Servicio',
      AddShortDescOptional: 'Añadir una breve descripción (opcional)',
      AddSpecificWorkDays: 'Añadir Días de Trabajo Específicos',
      AddStaff: 'Añadir Personal',
      All: 'Todos',
      AllAppointmentsCancelText: 'Todas las citas futuras serán canceladas.',
      AlreadyHaveAccount: '¿Ya tienes una cuenta?',
      AMD: 'AMD',
      anIndividual: 'Un individual',
      Apply: 'Aplicar',
      ApplyToAll: 'Aplicar a Todos',
      'Appointment Count': 'Conteo de Citas',
      AppointmentAccepted: 'Cita aceptada exitosamente',
      AppointmentCanceled: 'Cita cancelada exitosamente',
      AppointmentCancelModalText: '¿Estás seguro de que deseas cancelar tu cita?',
      AppointmentCreated: 'Cita creada exitosamente',
      AppointmentDeleted: 'Cita eliminada exitosamente',
      AppointmentDeleteModalText: 'La cita desaparecerá del calendario y no se calculará en las estadísticas.',
      AppointmentDeleteQuestion: '¿Estás seguro de que deseas eliminar la cita?',
      AppointmentDetails: 'Detalles de la Cita',
      AppointmentHistory: 'Historial de Citas',
      AppointmentNotes: 'No visible para los clientes',
      AppointmentNotFiltered: 'La cita no está filtrada',
      AppointmentNotFound: 'Cita no encontrada.',
      AppointmentRescheduled: 'Cita reprogramada exitosamente',
      Appointments: 'Citas',
      AppointmentSaveDraft: 'Cita guardada como borrador',
      AppointmentsHistory: 'Historial de Citas',
      AreYouSureCancel: '¿Estás seguro de que deseas cancelar la',
      AreYouSureDelete: '¿Estás seguro de que deseas eliminar la',
      'Assigned Services': 'Servicios Asignados',
      AtLeastOne: 'Al menos uno',
      AutoAccept: 'Aceptar citas automáticamente',
      AutoAcceptLabel: 'Auto Aceptar',
      AutoAcceptTooltipText: 'Las citas recibidas a través de la reserva en línea serán aceptadas automáticamente.',
      AutoFinishLabel: 'Finalización automática',
      AutoFinish: 'Marcar automáticamente las citas confirmadas como finalizadas una vez que haya pasado su hora de finalización',
      AvailableHours: 'Horarios disponibles',
      BookingLink: 'Enlace de Reserva',
      BookingUrl: 'URL de Reserva',
      BreakEndTimeAfter: 'La hora de finalización de la pausa debe ser después de la hora de inicio de la pausa.',
      BreakEndTimeRequired: 'La hora de finalización de la pausa es obligatoria.',
      Breaking: 'Pausa',
      BreakStartTimeRequired: 'La hora de inicio de la pausa es obligatoria.',
      BufferTime: 'Tiempo de Búfer',
      BufferTimeTooltipText: 'Agregar tiempo adicional después del servicio, que no se muestra al usuario.',
      BusinessNotSaved: 'Detalles de la empresa no guardados.',
      BusinessSaved: 'Detalles de la empresa guardados.',
      businessInfo: 'Información del negocio',
      ByDate: 'Por Fecha',
      ByStatus: 'Por Estado',
      businessName: 'Nombre del negocio',
      Calendar: 'Calendario',
      CalendarTimeSlot: 'Rango de Tiempo',
      Cancel: 'Cancelar',
      CancelAllUpcomingAppointments: 'Cancelar todas las citas futuras',
      CancelAppointment: 'Cancelar Cita',
      CancelInvitation: 'Cancelar Invitación',
      Cancelled: 'Cancelado',
      CantInviteYourSelf: 'No puedes invitarte a ti mismo como cliente',
      Categories: 'Categorías',
      Characters: 'caracteres',
      ChooseCustomer: 'Seleccionar un cliente',
      ChooseHour: 'Elegir Hora',
      ChooseMinutes: 'Elegir Minutos',
      ChooseOrCreateService: 'Seleccionar o Crear Servicio',
      CreateService: 'Crear servicio',
      chooseOrCreateIndustry: 'Seleccione o agregue una industria',
      ChooseOrCreateServiceCategory: 'Seleccionar o Crear Categoría de Servicio',
      ChooseServiceCategory: 'Elija la categoría de servicio',
      ChooseService: 'Seleccionar un servicio',
      ChooseServiceProvider: 'Seleccionar Proveedor de Servicios',
      ChooseStaff: 'Seleccionar un miembro del personal',
      City: 'Ciudad',
      Close: 'Cerrado',
      ClosedDayAdded: 'Día(s) cerrado(s) añadido(s) exitosamente.',
      ClosedDays: 'Días Cerrados',
      ClosedDayUpdated: 'Día(s) cerrado(s) actualizado(s) exitosamente.',
      Code: 'Código de País',
      Companies: 'Empresas',
      Company: 'Empresa',
      CompanyDeleted: 'Empresa eliminada exitosamente',
      CompanyEmail: 'Ingresar dirección de correo electrónico de la empresa',
      CompanyName: 'Nombre de la empresa',
      CompanyOverview: 'Escribe una breve descripción de tu empresa',
      Confirm: 'Confirmar',
      ConfirmActionMassagesDelete: '¿Estás seguro de que deseas eliminar esta empresa?',
      ConfirmActionMassagesDeleteError: 'No puedes eliminar esta empresa todavía porque tiene citas futuras.',
      ConfirmActionMassagesLeave: '¿Estás seguro de que deseas abandonar la empresa?',
      ConfirmCancel: 'Confirmar Cancelación',
      ConfirmDelete: 'Confirmar Eliminación',
      ConfirmDeletion: 'Confirmar Eliminación',
      ConfirmPassword: 'Confirmar Contraseña',
      continueDeleting: '¿Continuar eliminando?',
      continueWithFacebook: 'Continuar con Facebook',
      Country: 'País',
      CodeInvalidError: 'La longitud del código debe ser 6.',
      Create: 'Crear',
      CreateAppointment: 'Crear Cita',
      CurrentPassword: 'Contraseña Actual',
      Customer: 'Cliente',
      'Customer Name': 'Nombre del Cliente',
      CustomerAdded: 'Cliente agregado',
      CustomerDeleted: 'Cliente eliminado exitosamente',
      CustomerDeleteText: 'Si existe alguna cita con estos clientes, se cancelará.',
      CustomerDetails: 'Detalles del Cliente',
      CustomerPreview: 'Vista Previa del Cliente',
      Customers: 'Clientes',
      CustomerUpdated: 'Cliente actualizado exitosamente',
      Daily: 'Diario',
      Date: 'Fecha',
      DateAndTime: 'Fecha y Hora',
      companyImageDesc:
        'Como empresa, puedes agregar a tu personal, asignar servicios y crear citas.',
      DatePicker: 'Selector de Fecha',
      DateTime: 'Fecha/Hora',
      DayAdded: 'Día(s) libre(s) añadido(s) exitosamente',
      DayOfBirth: 'Fecha de Nacimiento',
      DayOfBirthOptional: 'Fecha de nacimiento (opcional)',
      BirthDay: 'Cumpleaños',
      DayOffDeleted: 'Día(s) libre(s) eliminado(s) exitosamente.',
      DaysOff: 'Días Libres',
      DayUpdated: 'Día(s) libre(s) actualizado(s) exitosamente.',
      Decline: 'Rechazar',
      DefaultView: 'Vista predeterminada del calendario',
      Delete: 'Eliminar',
      DeleteCategoryText: 'Si elimina esta categoría de servicio, también se eliminarán todos los servicios incluidos en ella.',
      deleteMultipleStaffText: 'Si existe alguna cita con este personal, se cancelará.',
      Description: 'Descripción',
      Details: 'Detalles',
      Digit: 'dígito',
      DontGetCode: '¿No recibes un código?',
      DontHaveAnAccount: '¿No tienes una cuenta?',
      DownloadAndSignUp: '¡Descarga la aplicación y regístrate ahora!',
      Duration: 'Duración',
      Edit: 'Editar',
      EditAppointment: 'Editar Cita',
      EditCategory: 'Editar categoria',
      EditCustomer: 'Editar Cliente',
      EditService: 'Editar Servicio',
      EditSpecificWorkDays: 'Editar Días de Trabajo Específicos',
      EditStaff: 'Editar Personal',
      Email: 'Correo Electrónico',
      EmailOptional: 'Correo Electrónico (opcional)',
      EmailRequired: 'El Correo Electrónico es obligatorio.',
      EmailResent: 'El correo electrónico se ha reenviado exitosamente.',
      EmailValid: 'Por favor, ingresa una dirección de correo electrónico válida.',
      EndDate: 'Fecha de Fin',
      EndDateRequired: 'La fecha de fin es obligatoria.',
      EndTime: 'Hora de Fin',
      EndTimeAfter: 'La hora de fin debe ser después de la hora de inicio.',
      EnterCodeText: 'Ingresa el código de 6 dígitos enviado a tu',
      EnterCustomerEmail: 'Ingresa el correo electrónico del cliente',
      EnterCustomerFirstName: 'Ingresa el primer nombre del cliente',
      EnterCustomerLastName: 'Ingresa el apellido del cliente',
      EnterCustomerPhoneNumber: 'Ingresa el número de teléfono del cliente',
      EnterSignInDetailsText: 'Por favor, ingresa tus detalles para iniciar sesión.',
      EnterStaffFirstName: 'Ingresa el primer nombre del personal',
      EnterStaffLastName: 'Ingresa el apellido del personal',
      EnterStaffPhoneNumber: 'Ingresa el número de teléfono del personal',
      EnterStaffProfession: 'Ingresa la profesión del personal',
      Facebook: 'URL de la página de Facebook',
      FasterAndMoreOrganizedText: 'Hagamos que el proceso de reserva sea más rápido y organizado.',
      Female: 'Femenino',
      FileNotSupported: 'El archivo no es compatible',
      Filter: 'Filtro',
      FinalPriceText: 'Por favor, define el precio final para este servicio.',
      Finish: 'Finalizar',
      FinishAppointment: 'Finalizar Cita',
      Finished: 'Finalizado',
      'First Visit': 'Primera Visita',
      FirstName: 'Nombre',
      FirstNameOrLastNameRequired: 'El nombre o apellido es obligatorio',
      Fixed: 'Fijo',
      FollowUp: 'Seguimiento',
      ForgotPassword1: '¿Olvidaste tu contraseña?',
      ForgotPassword2: 'Olvidé mi contraseña',
      Free: 'Gratis',
      Fri: 'Vie',
      Friday: 'Viernes',
      From: 'Desde',
      Gallery: 'Galería',
      Gender: 'Género',
      General: 'Información General',
      GeneralSchedule: {
        BreakHours: 'Tiempo de Pausa',
        Title: 'Horario General',
        WeekDays: 'Días Laborables',
        WorkingHours: 'Horas de Trabajo',
      },
      H: 'h',
      haveOneStaff: 'Se requiere al menos 1 miembro del personal.',
      Hour: 'Hora',
      ImageDeleted: 'Imagen eliminada exitosamente',
      IndustriesNotSaved: 'Detalles de las industrias no guardados.',
      Industry: 'Industria',
      IndustryDeleted: 'Industria eliminada exitosamente.',
      IndustryNotFound: 'Industria no encontrada.',
      IndustryUpdated: 'Industria actualizada exitosamente.',
      Info: 'Información',
      InfoGeneral: 'Información General',
      InfoLinks: 'Enlaces',
      InfoLocation: 'Ubicación',
      InfoTitle: 'Información',
      Instagram: 'Nombre de usuario de la cuenta de Instagram',
      InvalidData: 'Datos inválidos.',
      InvalidLoginOrPass: 'Inicio de sesión o contraseña inválidos.',
      Invitation: 'Invitación',
      InvitationAccepted: 'Invitación aceptada exitosamente',
      InvitationDeclined: 'Invitación rechazada.',
      InvitationDeleted: 'Invitación eliminada exitosamente',
      InvitationSent: 'Invitación enviada.',
      InvitationUpdated: 'Invitación actualizada exitosamente',
      Invite: 'Invitar',
      InviteCustomer: 'Invitar a Registrarse',
      InviteCustomerDescription: 'Invita a <0>{{name}}</0> a registrarse en SetTime.',
      Invited: 'Invitado',
      InviteToRegister: 'Invitar a registrarse',
      IsNotValid: ' no es válido',
      IsRequired: ' es obligatorio',
      IsTooLong: ' es demasiado largo',
      Language: 'Idioma',
      'Last Visit': 'Última Visita',
      individualImageDesc:
        'Como individuo, ofreces servicios y creas citas para tus clientes.',
      LastName: 'Apellido',
      LastNameOptional: 'Apellido (opcional)',
      Leave: 'Salir',
      LeaveCompany: 'Abandonar la empresa.',
      LoadDraft: 'Cargar Borrador',
      Loading: 'Cargando',
      Location: 'Ubicación',
      Logout: 'Cerrar Sesión',
      Lowercase: 'minúscula',
      lowercasePhoneNumber: 'Número de teléfono',
      MaintenanceTime: 'Hora de Mantenimiento',
      Male: 'Masculino',
      Manager: 'Gerente',
      Min: 'min',
      Mon: 'Lun',
      Monday: 'Lunes',
      Monthly: 'Mensual',
      MyAppointments: 'Mis Citas',
      MyCompany: 'Mi Empresa',
      MyProfile: 'Mi Perfil',
      Name: 'Nombre',
      New: 'Nuevo',
      NewAppointment: 'Nueva Cita',
      NewCategory: 'Nueva Categoría',
      NewCompany: 'Nueva Empresa',
      NewCustomer: 'Nuevo Cliente',
      NewPassMustBeDifferentText: 'Tu nueva contraseña debe ser diferente de la contraseña utilizada anteriormente',
      NewPassword: 'Nueva Contraseña',
      NewService: 'Nuevo Servicio',
      NewStaff: 'Nuevo Personal',
      Next: 'Siguiente',
      No: 'No',
      NoAppointments: 'Aún no hay citas',
      NoNotifications: 'Aún no hay notificaciones',
      NoOptions: 'Sin Opciones',
      Note: 'Nota',
      NoteOptional: 'Nota (opcional)',
      Notes: 'Notas de la Cita',
      Notification: 'Notificación',
      NotificationCanceled: 'Notificar a los clientes cuando se cancele su cita',
      NotificationConfirmed: 'Notificar a los clientes cuando se confirme su cita',
      NotificationRescheduled: 'Notificar a los clientes cuando se reprograma su cita',
      NotRegistered: 'No registrado',
      Off: 'Apagado',
      On: 'Encendido',
      Open: 'Abierto',
      Order: 'Orden de Programación',
      Password: 'Contraseña',
      PasswordMinLength: 'Al menos 8 caracteres',
      PasswordLowercase: 'Contener una letra minúscula',
      PasswordUppercase: 'Contener una letra mayúscula',
      PasswordSpecial: 'Contener un número o carácter especial',
      PasswordRequired: 'La contraseña es obligatoria.',
      PasswordLengthRule: 'La contraseña debe tener al menos 8 caracteres',
      PasswordCapitalRule: 'La contraseña debe contener al menos 1 letra mayúscula',
      PasswordMismatch: 'Las contraseñas no coinciden',
      PasswordSettings: 'Cambiar Contraseña',
      PastDate: 'Permitir crear citas en fechas pasadas',
      Pending: 'Pendiente',
      Canceled: 'Cancelado',
      Request: 'Bajo pedido',
      NoShow: 'No presentado',
      Permalink: 'Permalink',
      PersonalInformation: 'Información Personal',
      Phone: 'Teléfono',
      PhoneCode: 'Código de País',
      PhoneNumber: 'Número de Teléfono',
      PhoneNumberOptional: 'Número de Teléfono (opcional)',
      Position: 'Posición',
      Price: 'Precio',
      PriceType: 'Tipo de Precio',
      Pricing: 'Precios',
      Profession: 'Profesión',
      ProfessionOptional: 'Profesión (opcional)',
      Range: 'Rango',
      ReadAll: 'Leer Todo',
      Registered: 'Registrado',
      RemindCustomer: 'Recordar al cliente',
      Reminder: 'Recordatorios',
      Resend: 'Reenviar',
      ResendCode: 'Reenviar código',
      ResetPassword: 'Restablecer Contraseña',
      RowsPerPage: 'Filas por Página',
      Sat: 'Sáb',
      Saturday: 'Sábado',
      Save: 'Guardar',
      SaveDraft: 'Guardar Borrador',
      SaveDraftText: '¿Te gustaría guardar esta cita como borrador?',
      SearchByCustomerOrStaff: 'Buscar por cliente o personal',
      SearchCustomer: 'Buscar por nombre de cliente',
      SearchService: 'Buscar por nombre de servicio',
      SearchStaff: 'Buscar por nombre de personal',
      SelectedDayNotWork: 'El día seleccionado no es un día de trabajo',
      Send: 'Enviar',
      Service: 'Servicio',
      'Service Name': 'Nombre del Servicio',
      ServiceBased: 'Basado en Servicios',
      ServiceCategory: 'Categoría de Servicio',
      ServiceDeleted: 'Servicio eliminado exitosamente.',
      ServiceDeletionText: 'Este servicio no estará disponible para reserva y, si existe alguna cita, se cancelará.',
      ServiceDuration: 'Duración del Servicio',
      ServiceNotFound: 'Servicio no encontrado.',
      ServiceProvider: 'Proveedor de Servicios',
      Services: 'Servicios',
      Settings: 'Configuración',
      ShortPassword: 'La contraseña es demasiado corta.',
      ShowLess: 'Mostrar Menos',
      ShowMore: 'Mostrar Más',
      ShowOnWidget: 'Mostrar en el Widget',
      ShowOnWidgetTooltipText: 'El servicio estará disponible para reserva en línea.',
      SignIn: 'Iniciar Sesión',
      SlotDuration: 'Duración de la ranura del calendario',
      SomethingWrong: 'Algo salió mal.',
      Source: 'Fuente',
      SpecificDayAdded: 'Día(s) de trabajo específico(s) añadido(s) exitosamente.',
      SpecificDayDeleted: 'Día(s) de trabajo específico(s) eliminado(s) exitosamente.',
      SpecificDaysTitle: 'Días de Trabajo Específicos',
      SpecificDayUpdated: 'Día(s) de trabajo específico(s) actualizado(s) exitosamente.',
      SpecificWorkDayDeleted: 'Día(s) de trabajo específico(s) eliminado(s) exitosamente.',
      Staff: 'Personal',
      'Staff Name': 'Nombre del Personal',
      StaffBased: 'Basado en el Personal',
      StaffDetails: 'Detalles del Personal',
      StaffNotFind: 'No se encuentra al personal',
      StaffPreview: 'Vista Previa del Personal',
      Staffs: 'Personal',
      StartDate: 'Fecha de Inicio',
      StartTime: 'Hora de Inicio',
      StartTimeEarlier: 'La hora de inicio debe ser anterior a la hora de fin.',
      StartTimeRequired: 'La hora de inicio es obligatoria.',
      State: 'Estado',
      Statistics: 'Estadísticas',
      Status: 'Estado',
      Sun: 'Dom',
      Sunday: 'Domingo',
      signUp: 'Registrarse',
      signUpWith: 'O regístrate con',
      signUpWithEmail: 'Registrarse con correo electrónico',
      TableHeader: {
        Actions: 'Opciones',
        BreakTime: 'Pausa',
        Date: 'Fechas',
        Description: 'Descripción',
        Staff: 'Personal',
        Time: 'Horario de Trabajo',
      },
      ThankYou: 'Enviar un mensaje de agradecimiento al cliente',
      ThisMonth: 'Este mes',
      ThisServiceHas: 'Este servicio tiene ',
      ThisWeek: 'Esta semana',
      Thu: 'Jue',
      Thursday: 'Jueves',
      Time: 'Hora',
      TimeZone: 'Zona Horaria',
      Tin: 'TIN',
      Title: 'Título',
      To: 'A',
      Today: 'Hoy',
      Tomorrow: 'Mañana',
      Total: 'Total',
      TotalPayments: 'Ingresos',
      Tue: 'Mar',
      Tuesday: 'Martes',
      Upcoming: 'Próximas',
      UpcomingAppointments: 'Citas Futuras',
      UpcomingAppointmentServiceModalText: ' cita(s) futura(s).',
      upcomingAppointmentWith: 'Próximas citas con',
      Update: 'Actualizar',
      UpdatedSuccess: 'Actualizado exitosamente.',
      Uppercase: 'mayúscula',
      UserNotBreak: 'El usuario está en descanso en las horas seleccionadas',
      UserNotWork: 'El usuario no trabaja en las horas seleccionadas',
      ValidAppointment: 'Campos de cita válidos',
      VerificationCode: 'Código de verificación',
      VerifyCode: 'Verificar código',
      Visits: 'Visitas',
      WalkIn: 'Entrada sin cita previa',
      WalkInDesc: 'Elige la opción de "Entrada sin cita previa" si deseas omitir al cliente.',
      WebSite: 'URL del sitio web de la empresa',
      Wed: 'Mié',
      Wednesday: 'Miércoles',
      Weekly: 'Semanalmente',
      WeWillSendVerificationCode: 'Te enviaremos un código de verificación de 6 dígitos.',
      EnterVerificationCode: 'Ingresa el código de 6 dígitos enviado a tu ',
      willBeCancelled: ' se cancelará.',
      WithDrawInvitation: 'Cancelar Invitación',
      'Work Schedule': 'Horario de trabajo',
      WorkDateEndTimeRequired: 'La hora de finalización del día de trabajo es obligatoria.',
      WorkDateStartTimeRequired: 'La hora de inicio del día de trabajo es obligatoria.',
      WorkdayEnd: 'Fin del día de trabajo',
      WorkdayStart: 'Inicio del día de trabajo',
      WorkingScheduleTab: {
        Company: 'Empresa',
        General: 'General',
        Staff: 'Personal',
      },
      WorkSchedule: 'Horario de trabajo',
      whatIsTypeBusiness: 'Seleccione el tipo de negocio',
      Yes: 'Sí',
      ZipCode: 'Código Postal',
      timeOff: 'Tiempo libre',
      editTimeOff: 'Editar tiempo libre',
      newTimeOff: 'Nuevo tiempo libre',
      timeOffDescription: 'Bloquea franjas horarias específicas en las que no estás disponible, asegurando que no se programen citas durante esos períodos.',
      startTimeRequired: 'Se requiere la hora de inicio',
      startTimeInvalid: 'La hora de inicio debe ser anterior a la hora de fin.',
      endTimeInvalid: 'La hora de fin debe ser posterior a la hora de inicio.',
      endTimeRequired: 'Se requiere la hora de finalización',
      timeOffCreatedSuccess: 'Tiempo libre creado con éxito.',
      timeOffCreatedFailure: 'Error al crear el tiempo libre',
      timeOffUpdatedSuccess: 'Tiempo libre actualizado con éxito.',
      timeOffUpdatedFailure: 'Error al actualizar el tiempo libre',
      titleOptional: 'Título (opcional)',
      TimeOffDeleteModalText: '¿Estás seguro de que quieres eliminar el tiempo libre?',
      TimeOffDeleted: 'Tiempo libre eliminado con éxito',
    },
  },
};

export default resources;
