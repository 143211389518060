const resources = {
  hy: {
    translation: {
      Accept: 'Ընդունել',
      Accepted: 'Հաստատված',
      AccountCreated: 'Հաշիվը ստեղծվել է',
      Actions: 'Գործողություններ',
      Add: 'Ավելացնել',
      AddAppointment: 'Ստեղծել ամրագրում',
      AddClosedDays: 'Ավելացնել հանգստյան օրեր',
      AddCompany: 'Ավելացնել ընկերություն',
      AddCustomer: 'Ավելացնել հաճախորդի',
      AddDayOff: 'Ավելացնել հանգստյան օրեր',
      AddNewCustomer: 'Նոր հաճախորդ',
      Address: 'Հասցե',
      AddressOptional: 'Հասցե (ըստ ցանկության)',
      AddService: 'Ավելացնել Ծառայություն',
      AddShortDescOptional: 'Ավելացնել նկարագրություն (ընտրովի)',
      AddSpecificWorkDays: 'Ավելացնել հատուկ օրեր',
      AddStaff: 'Ավելացնել Աշխատակից',
      All: 'Բոլորը',
      AllAppointmentsCancelText: 'Առաջիկա բոլոր ամրագրումները կչեղարկվեն։',
      AlreadyHaveAccount: 'Արդեն հաշիվ ունե՞ք:',
      AMD: '֏',
      anIndividual: 'Անհատ',
      Apply: 'Կիրառել',
      ApplyToAll: 'Տարածել բոլոր օրերի վրա',
      'Appointment Count': 'Ամրագրումների քանակ',
      AppointmentAccepted: 'Հանդիպումը հաջողությամբ ընդունվեց',
      AppointmentCanceled: 'Հանդիպումը հաջողությամբ չեղարկվեց',
      AppointmentCancelModalText: 'Վստա՞հ եք, որ ցանկանում եք չեղարկել ամրագրումը։',
      AppointmentCreated: 'Նշանակումը հաջողությամբ ստեղծվեց',
      AppointmentDeleted: 'Հանդիպումը հաջողությամբ ջնջվեց',
      AppointmentDeleteModalText: 'Այս ծառայությունը հասանելի չի լինի ամրագրման համար և եթե կա ամրագրում այն կչեղարկվի։',
      AppointmentDeleteQuestion: 'Վստա՞հ եք, որ ցանկանում եք ջնջել հանդիպումը',
      AppointmentDetails: 'Ամրագրման մանրամասներ',
      AppointmentHistory: 'Ամրագրումների պատմություն',
      AppointmentNotes: 'Նշումներ (հաճախորդը չի տեսնի Ձեր նշումները)',
      AppointmentNotFiltered: 'Ամրագրումը ֆիլտրված չէ',
      AppointmentNotFound: 'Ամրագրում չի գտնվել',
      AppointmentRescheduled: 'Ամրագրումը հաջողությամբ փոփոխվել է',
      Appointments: 'Ամրագրումներ',
      AppointmentSaveDraft: 'Ամրագրումը պահպանվել է սևագրում',
      AppointmentsHistory: 'Ամրագրման պատմություն',
      AreYouSureCancel: 'Վստա՞հ եք, որ ցանկանում եք չեղարկել',
      AreYouSureDelete: 'Վստա՞հ եք, որ ցանկանում եք ջնջել',
      'Assigned Services': 'Հանձնարարված ծառայություններ',
      AtLeastOne: 'Առնվազն մեկ ',
      AutoAccept: 'Ինքնաբերաբար հաստատել հաճախորդների ամրագրումները',
      AutoAcceptLabel: 'Ավտոմատ ընդունում',
      AutoAcceptTooltipText: 'Առցանց ամրագրումները կընդունվեն ավտոմատ կերպով:',
      AutoFinishLabel: 'Ինքնաշխատ ավարտ',
      AutoFinish: 'Հաստատված ամրագրումներն ինքնաբերաբար նշել ավարտված՝ ավարտի ակնկալվող ժամն անցնելուց հետո ',
      AvailableHours: 'Հասանելի ժամեր',
      BookingLink: 'Ամրագրման հղում',
      BookingUrl: 'Ամրագրման հղում',
      BreakEndTimeAfter: 'Ընդմիջման ավարտը պետք է լինի ընդմիջման սկսելուց հետո',
      BreakEndTimeRequired: 'Ընդմիջման ավարտը պարտադիր է',
      Breaking: 'Ընդմիջում',
      BreakStartTimeRequired: 'Ընդմիջման սկիզբը պարտադիր է',
      BufferTime: 'Լրացուցիչ ժամանակ',
      BufferTimeTooltipText: 'Ավելացնում է լրացուցիչ ժամանակ ծառայությանը, որը հաճախորդը չի տեսնում։',
      BusinessNotSaved: 'Բիզնեսի մանրամասները չեն պահպանվել',
      businessInfo: 'Բիզնեսի տվյալներ',
      businessName: 'Բիզնեսի անուն',
      BusinessSaved: 'Բիզնեսի մանրամասները պահպանված են',
      ByDate: 'Ըստ ամսաթվի',
      ByStatus: 'Ըստ կարգավիճակի',
      Calendar: 'Օրացույց',
      CalendarTimeSlot: 'Ժամանակահատված',
      Cancel: 'Չեղարկել',
      CancelAllUpcomingAppointments: 'Չեղարկել բոլոր առաջիկա ամրագրումները',
      CancelAppointment: 'Չեղարկելլ ամրագրումը',
      CancelInvitation: 'Չեղարկել հրավերը',
      Cancelled: 'Չեղարկված',
      CantInviteYourSelf: 'Դուք չեք կարող հրավիրել Ձեզ որպես հաճախորդ',
      Categories: 'Կատեգորիաներ',
      Characters: 'սիմվոլներ',
      ChooseCustomer: 'Ընտրել հաճախորդի',
      ChooseHour: 'Ընտրել ժամ',
      ChooseMinutes: 'Ընտրել րոպե',
      ChooseOrCreateService: 'Ընտրել կամ ստեղծել ծառայություն',
      CreateService: 'Ստեղծել ծառայություն',
      chooseOrCreateIndustry: 'Ընտրեք կամ ավելացրեք արդյունաբերություն',
      ChooseOrCreateServiceCategory: 'Ընտրել կամ ստեղծել ծառայության կատեգորիա',
      ChooseServiceCategory: 'Ընտրել ծառայության կատեգորիա',
      ChooseService: 'Ընտրել ծառայություն',
      ChooseServiceProvider: 'Ընտրել ծառայության սպասակող',
      ChooseStaff: 'Ընտրել մասնագետի',
      City: 'Քաղաք',
      Close: 'Փակ',
      ClosedDayAdded: 'Փակ օր(եր)ը հաջողությամբ ավելացվեցին',
      ClosedDays: 'Հանգստյան օրեր',
      ClosedDayUpdated: 'Փակ օր(եր)ը հաջողությամբ թարմացվել են',
      Code: 'Երկրի կոդ',
      Companies: 'Ընկերություններ',
      Company: 'Ընկերություն',
      CompanyDeleted: 'Ընկերությունը հաջողությամբ ջնջվել է',
      CompanyEmail: 'Ընկերության Էլ․ հասցե',
      CompanyName: 'Ընկերության անվանում',
      CompanyOverview: 'Համառոտ նկարագրեք Ձեր ընկերությունը։',
      Confirm: 'Հաստատել',
      ConfirmActionMassagesDelete: 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս ընկերությունը SetTime հարթակից։',
      ConfirmActionMassagesDeleteError: 'Ներկա պահին ընկերությունը չի կարող հեռացվել հարթակից, քանի որ առկա են առաջիկա ամրագրումներ։',
      ConfirmActionMassagesLeave: 'Վստահե՞ք, որ ցանկանում եք թողնել ընկերությունը',
      ConfirmCancel: 'Հաստատել Չեղարկումը',
      ConfirmDelete: 'Ջնջման հաստատում',
      ConfirmDeletion: 'Ջնջման հաստատում',
      ConfirmPassword: 'Հաստատել գաղտնաբառը',
      continueDeleting: 'Ջնջե՞լ',
      continueWithFacebook: 'Շարունակել Facebook-ով',
      Country: 'Երկիր',
      CodeInvalidError: 'Կոդի երկարությունը պետք է լինի 6:',
      Create: 'Ստեղծել',
      CreateAppointment: 'Ստեղծել ամրագրում',
      CurrentPassword: 'Ընթացիկ գաղտնաբառ',
      Customer: 'Հաճախորդ',
      'Customer Name': 'Հաճախորդի անուն',
      CustomerAdded: 'Հաճախորդն ավելացված է',
      CustomerDeleted: 'Հաճախորդը հաջողությամբ ջնջվեց',
      CustomerDeleteText: 'Եթե ​​այս հաճախորդների հետ որևէ հանդիպում լինի, այն կչեղարկվի.',
      CustomerDetails: 'Հաճախորդի տվյալներ',
      CustomerPreview: 'Հաճախորդի նախադիտում',
      Customers: 'Հաճախորդներ',
      CustomerUpdated: 'Հաճախորդը հաջողությամբ ջնջվեց',
      companyImageDesc:
        'Որպես ընկերություն՝ Դուք կարող եք ավելացնել Ձեր անձնակազմը, կցել ծառայություններ և ստեղծել ամրագրումներ:',
      Daily: 'Օրական',
      Date: 'Ամսաթիվ',
      DateAndTime: 'Օր և ժամ',
      DatePicker: 'Ամսաթիվ',
      DateTime: 'Ամսաթիվ/ժամ',
      DayAdded: 'Օր(եր)ը հաջողությամբ ավելացվեց',
      DayOfBirth: 'Ծննդյան ամսաթիվ',
      DayOfBirthOptional: 'Ծննդյան ամսաթիվ (ըստ ցանկության)',
      BirthDay: 'Ծննդյան օր',
      DayOffDeleted: 'Հանգստյան օրերը հաջողությամբ ջնջվեցին',
      DaysOff: 'Հանգստյան օրեր',
      DayUpdated: 'Օր(եր)ը հաջողությամբ թարմացվել է',
      Decline: 'Մերժել',
      DefaultView: 'Օրացույցի լռելյայն տեսարան',
      Delete: 'Ջնջել',
      DeleteCategoryText: 'Եթե ջնջեք ծառայության այս կատեգորիան, կջնջվեն նաև դրա տակ գտնվող բոլոր ծառայությունները:',
      deleteMultipleStaffText: 'Եթե այս մասնագետների հետ ամրագրումներ լինեն դրանք կչեղարկվեն:',
      Description: 'Նկարագրություն',
      Details: 'Մանրամասներ',
      Digit: 'թիվ',
      DontGetCode: 'Չեք ստացե՞լ հաղորդագրություն։',
      DontHaveAnAccount: 'Չունե՞ք հաշիվ',
      DownloadAndSignUp: 'Ներբեռնե՛ք հավելվածը և գրանցվեք հիմա։',
      Duration: 'Տևողություն',
      Edit: 'Խմբագրել',
      EditAppointment: 'Խմբագրել ամրագրումը',
      EditCategory: 'Խմբագրել կատեգորիան',
      EditCustomer: 'Խմբագրել հաճախորդի տվյալները',
      EditService: 'Խմբագրել ծառայությունը',
      EditSpecificWorkDays: 'Խմբագրել հատուկ օրեր',
      EditStaff: 'Խմբագրել Աշխատակցի տվյալները',
      Email: 'Էլ․ հասցե',
      EmailOptional: 'Էլ․ հասցե (ընտրովի)',
      EmailRequired: 'Էլ․ հասցեն պարտադիր է:',
      EmailResent: 'Էլ-հասցեն հաջողությամբ վերաուղարկվել է',
      EmailValid: 'Խնդրում ենք տրամադրել վավեր Էլ․ հասցե։',
      EndDate: 'Ավարտ',
      EndDateRequired: 'Ավարտի ամսաթիվը պարտադիր է',
      EndTime: 'Ավարտ',
      EndTimeAfter: 'Ավարտի ժամը պետք է լինի սկսելու ժամից հետո',
      EnterCodeText: 'Մուտքագրեք վեցանիշ կոդը ուղարկված Ձեր',
      EnterCustomerEmail: 'Մուտքագրեք հաճախորդի էլ․ հասցեն',
      EnterCustomerFirstName: 'Մուտքագրեք հաճախորդի անունը',
      EnterCustomerLastName: 'Մուտքագրեք հաճախորդի ազգանունը',
      EnterCustomerPhoneNumber: 'Մուտքագրեք հաճախորդի հեռախոսահամարը',
      EnterSignInDetailsText: 'Մուտք գործելու համար մուտքագրեք Ձեր տվյալները։',
      EnterStaffFirstName: 'Մուտքագրեք աշխատակցի անունը',
      EnterStaffLastName: 'Մուտքագրեք աշխատակցի ազգանունը',
      EnterStaffPhoneNumber: 'Մուտքագրեք աշխատակցի հեռախոսահամարը',
      EnterStaffProfession: 'Մուտքագրեք աշխատակցի մասնագիտությունը',
      Facebook: 'Ֆեյսբուքյան էջի հասցե',
      FasterAndMoreOrganizedText: 'Դարձրեք ամրագրման ընթացքը ավելի արագ և կազմակերպված։',
      Female: 'Իգական',
      FileNotSupported: 'Ֆայլը չի աջակցվում',
      Filter: 'Ֆիլտր',
      FinalPriceText: 'Հատատեք ծառայության վերջնական արժեքը:',
      Finish: 'Ավարտել',
      FinishAppointment: 'Ավարտել ամրագրումը',
      Finished: 'Ավարտված',
      'First Visit': 'Առաջին այցը',
      FirstName: 'Անուն',
      FirstNameOrLastNameRequired: 'Անունը կամ Ազգանունը պարտադիր է',
      Fixed: 'Ֆիքսված',
      FollowUp: 'Լրացուցիչ',
      ForgotPassword1: 'Մոռացե՞լ եք գաղտնաբառը',
      ForgotPassword2: 'Մոռացե՞լ եք գաղտնաբառը',
      Free: 'Անվճար',
      Fri: 'Ուրբ',
      Friday: 'Ուրբաթ',
      From: 'Սկիզբ',
      Gallery: 'Պատկերասրահ',
      Gender: 'Սեռ',
      General: 'Ընդհանուր տեղեկություն',
      GeneralSchedule: {
        BreakHours: 'Ընդմիջում',
        Title: 'Հիմնական գրաֆիկ',
        WeekDays: 'Աշխատանքային օրեր',
        WorkingHours: 'Աշխատանքային ժամեր',
      },
      H: 'ժամ',
      haveOneStaff: 'Առնվազն 1 աշխատակից ունենալը պարտադիր է։',
      Hour: 'Ժամ',
      ImageDeleted: 'Նկարը հաջողությամբ ջնջվել է',
      IndustriesNotSaved: 'Ինդուստրիայի մանրամասները չեն պահպանվել',
      Industry: 'Արդյունաբերության ճյուղ',
      IndustryDeleted: 'Ինդուստրիան հաջողությամբ ջնջվել է',
      IndustryNotFound: 'Ինդուստրիան չի գտնվել',
      IndustryUpdated: 'Ինդուստրիան հաջողությամբ թարմացվել է',
      Info: 'Ինֆորմացիա',
      InfoGeneral: 'ԸՆդհանուր տեղեկություն',
      InfoLinks: 'Հղումներ',
      InfoLocation: 'Հասցե',
      InfoTitle: 'Տեղեկություն',
      Instagram: 'Ինստագրամյան օգտանուն',
      InvalidData: 'Անվավեր տվյալներ',
      InvalidLoginOrPass: 'Մուտքանունը կամ գաղտնաբառը սխալ է:',
      Invitation: 'հրավերը',
      InvitationAccepted: 'Հրավերը հաջողությամբ ընդունվեց',
      InvitationDeclined: 'Հրավերը մերժվել է',
      InvitationDeleted: 'Հրավերը հաջողությամբ ջնջվել է',
      InvitationSent: 'Հրավերը հաջողությամբ ուղարկվեց',
      InvitationUpdated: 'Հրավերը հաջողությամբ թարմացվեց',
      Invite: 'Հրավիրել',
      InviteCustomer: 'Հրավիրել հարթակ',
      InviteCustomerDescription: 'Հրավիրեք <0>{{name}}</0> -ին SetTime հարթակ՝ ստեղծելու իր անհատական հաշիվը։',
      Invited: 'Հրավիրված',
      InviteToRegister: 'Հրավիրել SetTime',
      IsNotValid: 'անվավեր է։',
      IsRequired: ' դաշտը պարտադիր է։',
      IsTooLong: ' շատ երկար է։',
      individualImageDesc:
        'Որպես անհատ՝ Դուք մատուցում եք ծառայություններ և ստեղծում եք ամրագրումներ Ձեր հաճախորդների համար:',
      Language: 'Լեզու',
      'Last Visit': 'Վերջին այցը',
      LastName: 'Ազգանուն',
      LastNameOptional: 'Ազգանուն (ընտրովի)',
      Leave: 'Թողնել',
      LeaveCompany: 'Թողնել ընկերությունը',
      LoadDraft: 'Բեռնել սևագիրը',
      Loading: 'Բեռնվում է',
      Location: 'Հասցե',
      Logout: 'Դուրս գալ հաշվից',
      Lowercase: 'փոքրատառ',
      lowercasePhoneNumber: 'Հեռախոսահամար',
      MaintenanceTime: 'Ժամանակահատված',
      Male: 'Արական',
      Manager: 'Մենեջեր',
      Min: 'ր',
      Mon: 'Երկ',
      Monday: 'Երկուշաբթի',
      Monthly: 'Ամսական',
      MyAppointments: 'Ամրագրումներ',
      MyCompany: 'Իմ ընկերությունը',
      MyProfile: 'Իմ էջը',
      Name: 'Անուն',
      New: 'Նոր',
      NewAppointment: 'Նոր ամրագրում',
      NewCategory: 'Նոր Կատեգորիա',
      NewCompany: 'Նոր ընկերություն',
      NewCustomer: 'Նոր հաճախորդ',
      NewPassMustBeDifferentText: 'Ձեր նոր գաղտնաբառը պետք է տարբերվի նախկինում օգտագործված գաղտնաբառից',
      NewPassword: 'Նոր գաղտնաբառ',
      NewService: 'Նոր ծառայություն',
      NewStaff: 'Նոր Աշխատակից',
      Next: 'Հաջորդը',
      No: 'Ոչ',
      NoAppointments: 'Դեռ նշանակումներ չկան',
      NoNotifications: 'Ծանուցումներ չկան',
      NoOptions: 'Ընտրություն չկա',
      Note: 'Նշումներ',
      NoteOptional: 'Նշում (ըստ ցանկության)',
      Notes: 'Նշումներ (հաճախորդը չի տեսնի Ձեր նշումները)',
      Notification: 'Ծանուցումներ',
      NotificationCanceled: 'Տեղեկացնել հաճախորդին, երբ իր ամրագրումը չեղարկվել է',
      NotificationConfirmed: 'Տեղեկացնել հաճախորդին, երբ իր ամրագրումը հաստատվել է',
      NotificationRescheduled: 'Տեղեկացնել հաճախորդին, երբ իր ամրագրումը հետաձգվել է',
      NotRegistered: 'Չգրանցված',
      Off: 'Չկա',
      On: 'Կա',
      Open: 'Բաց',
      Order: 'Ամրագրման հերթականություն',
      Password: 'Գաղտնաբառ',
      PasswordMinLength: 'Առնվազն 8 նիշ',
      PasswordLowercase: 'Առնվազն մեկ փոքրատառ տառ',
      PasswordUppercase: 'Առնվազն մեկ մեծատառ տառ',
      PasswordSpecial: 'Առնվազն մեկ թիվ կամ հատուկ նիշ',
      PasswordLengthRule: 'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ',
      PasswordCapitalRule: 'Գաղտնաբառը պետք է պարունակի առնվազն 1 մեծատառ',
      PasswordMismatch: 'Գաղտնաբառերը չեն համապատասխանում',
      PasswordRequired: 'Գաղտնաբառը պարտադիր է:',
      PasswordSettings: 'Փոխել գաղտնաբառը',
      PastDate: 'Թույլատրել անցյալ ամսաթվով ամրագրումների ստեղծումը',
      Pending: 'Սպասում',
      Canceled: 'Չեղարկված',
      Request: 'Հարցման դեպքում',
      NoShow: 'Չի ներկայացել',
      Permalink: 'Հղում',
      PersonalInformation: 'Անձնական տվյալներ',
      Phone: 'Հեռախոս',
      PhoneCode: 'Երկրի կոդ',
      PhoneNumber: 'Հեռախոսահամար',
      PhoneNumberOptional: 'Հեռախոսահամար (ընտրովի)',
      Position: 'Պաշտոն',
      Price: 'Գին',
      PriceType: 'Գնի տեսակ',
      Pricing: 'Արժեք',
      Profession: 'Մասնագիտություն',
      ProfessionOptional: 'Մասնագիտություն (ընտրովի)',
      Range: 'Միջակայք',
      ReadAll: 'Կարդալ բոլորը',
      Registered: 'Գրանցված',
      RemindCustomer: 'Հիշեցում ուղարկել հաճախորդին',
      Reminder: 'Հիշեցումներ',
      Resend: 'Նորից ուղարկել',
      ResendCode: 'Նորից ուղարկել կոդը',
      ResetPassword: 'Վերականգնել գաղտնաբառը',
      RowsPerPage: 'Տողեր մեկ էջի համար',
      Sat: 'Շբթ',
      Saturday: 'Շաբաթ',
      Save: 'Պահպանել',
      SaveDraft: 'Պահպանել սևագիրը',
      SaveDraftText: 'Ցանկանու՞մ եք պահպանել այս նշանակումը որպես սևագիր',
      SearchByCustomerOrStaff: 'Որոնում ըստ հաճախորդի կամ անձնակազմի',
      SearchCustomer: 'Հաճախորդների որոնում',
      SearchService: 'Որոնել ըստ անվանման',
      SearchStaff: 'Աշխատակցի որոնում',
      SelectedDayNotWork: 'Ընտրված օրը աշխատանքային չէ',
      Send: 'Ուղարկել',
      Service: 'Ծառայություն',
      'Service Name': 'Ծառայության անվանում',
      ServiceBased: 'Ծառայություն, մասնագետ',
      ServiceCategory: 'Ծառայության կատեգորիա',
      ServiceDeleted: 'Ծառայությունը հաջողությամբ ջնջվել է',
      ServiceDeletionText: 'Այս ծառայությունը հասանելի չի լինի ամրագրման համար և եթե կա ամրագրում այն կչեղարկվի։',
      ServiceDuration: 'Ծառայության տևողություն',
      ServiceNotFound: 'Ծառայություն չի գտնվել ',
      ServiceProvider: 'ծառայության սպասարկող',
      Services: 'Ծառայություններ',
      Settings: 'Կարգավորումներ',
      ShortPassword: 'Գաղտնաբառը կարճ է:',
      ShowLess: 'Ցուցադրել ավելի քիչ',
      ShowMore: 'Ցուցադրել ավելին',
      ShowOnWidget: 'Ցույց տալ վիդջեթում',
      ShowOnWidgetTooltipText: 'Ծառայությունը հասանելի կլինի առցանց ամրագրման համար:',
      SignIn: 'Մուտք',
      SlotDuration: 'Օրացույցի մեկ ժամանակահատվածի տևողություն',
      SomethingWrong: 'Ինչ-որ բան սխալ է',
      Source: 'Աղբյուր',
      SpecificDayAdded: 'Հատուկ աշխատանքային օր(ներ)ը հաջողությամբ ավելացվել է',
      SpecificDayDeleted: 'Հատուկ աշխատանքային օր(ներ)ը հաջողությամբ ջնջվեցին',
      SpecificDaysTitle: 'Հատուկ աշխատանքային օրեր',
      SpecificDayUpdated: 'Հատուկ աշխատանքային օր(ներ)ը հաջողությամբ թարմացվել են',
      SpecificWorkDayDeleted: 'Հատուկ աշխատանքային օր(եր)ը հաջողությամբ ջնջվել են:',
      Staff: 'Աշխատակազմ',
      'Staff Name': 'Աշխատակցի անուն',
      StaffBased: 'Մասնագետ, ծառայություն',
      StaffDetails: 'Աշխատակցի տվյալները',
      StaffNotFind: 'Անձնակազմը չի գտնվել !',
      StaffPreview: 'Աշխատակցի նախադիտում',
      Staffs: 'Աշխատակից',
      StartDate: 'Սկիզբ',
      StartTime: 'Սկիզբ',
      StartTimeEarlier: 'Սկսվելու ժամը պետք է լինի ավելի շուտ քան ավարտի ժամը',
      StartTimeRequired: 'Սկսելու ժամանակը պարտադիր է',
      State: 'Նահանգ/մարզ',
      Statistics: 'Վիճակագրություն',
      Status: 'Կարգավիճակ',
      Sun: 'Կրկ',
      Sunday: 'Կիրակի',
      signUp: 'Գրանցվել',
      signUpWith: 'Կամ գրանցվեք հետևյալ միջոցներով',
      signUpWithEmail: 'Գրանցվել Էլ․ փոստի միջոցով',
      TableHeader: {
        Actions: 'Գործողություններ',
        BreakTime: 'Ընդմիջում',
        Date: 'Օրեր',
        Description: 'Նկարագրություն',
        Staff: 'Աշխատակից',
        Time: 'Աշխատանքային ժամեր',
      },
      ThankYou: 'Շնորհակալական հաղորդագրություն ուղարկել հաճախորդին',
      ThisMonth: 'Այս ամիս',
      ThisServiceHas: 'Այս ծառայությունն ունի առաջիկա ',
      ThisWeek: 'Այս շաբաթ',
      Thu: 'Հնգ',
      Thursday: 'Հինգշաբթի',
      Time: 'Ժամանակ',
      TimeZone: 'Ժամային գոտի',
      Tin: 'ՀՆՀ',
      Title: 'Վերնագիր',
      To: 'Ավարտ',
      Today: 'Այսօր',
      Tomorrow: 'Վաղը',
      Total: 'Ընդամենը',
      TotalPayments: 'Եկամուտ',
      Tue: 'Երք',
      Tuesday: 'Երեքշաբթի',
      Upcoming: 'Առաջիկա',
      UpcomingAppointments: 'Առաջիկա ամրագրումները',
      UpcomingAppointmentServiceModalText: ' ամրագրում(ներ)',
      upcomingAppointmentWith: 'Առաջիկա ամրագրումները',
      Update: 'Թարմացնել',
      UpdatedSuccess: 'Հաջողությամբ թարմացվել է',
      Uppercase: 'մեծատառ',
      UserNotBreak: 'Օգտագործողը արգելակում է ընտրված ժամերին:',
      UserNotWork: 'Օգտագործողը չի աշխատում ընտրված ժամերին:',
      ValidAppointment: 'Ամրագրման պարտադիր դաշտեր',
      VerificationCode: 'Հաստատման կոդ',
      VerifyCode: 'Հաստատեք կոդը',
      Visits: 'Այցելություններ',
      WalkIn: 'Հաճախորդ',
      WalkInDesc: 'Ընտրեք «Հաճախորդ» եթե ցանկանում եք բաց թողնել այս քայլը։',
      WebSite: 'Ընկերության կայքի հասցե',
      Wed: 'Չրք',
      Wednesday: 'Չորեքշաբթի',
      Weekly: 'Շաբաթական',
      WeWillSendVerificationCode: 'Մենք կուղարկենք Ձեզ վեցանիշ հաստատման կոդ:',
      EnterVerificationCode: 'Մուտքագրեք ձեր հեռախոսին ուղարկված 6 նիշանի կոդը ',
      willBeCancelled: '-ի հետ կչեղարկվի։',
      WithDrawInvitation: 'Չեղարկել հրավերը',
      'Work Schedule': 'Աշխատանքային գրաֆիկ',
      WorkDateEndTimeRequired: 'Աշխատանքային օրվա ավարտը պարտադիր է',
      WorkDateStartTimeRequired: 'Աշխատանքային օրվա սկիզբը պարտադիր է',
      WorkdayEnd: 'Աշխատանքային օրվա ավարտ',
      WorkdayStart: 'Աշխատանքային օրվա սկիզբ',
      WorkingScheduleTab: {
        Company: 'Ընկերություն',
        General: 'Հիմնական',
        Staff: 'Աշխատակից',
      },
      WorkSchedule: 'Աշխատանքային գրաֆիկ',
      whatIsTypeBusiness: 'Ընտրեք ձեր բիզնեսի տեսակը',
      Yes: 'Այո',
      ZipCode: 'Փոստային կոդ',
      timeOff: 'Արձակուրդ',
      editTimeOff: 'Խմբագրել արձակուրդը',
      newTimeOff: 'Նոր արձակուրդ',
      timeOffDescription: 'Արգելափակեք որոշակի ժամեր, երբ դուք անհասանելի եք, ապահովելու համար, որ այդ ժամանակահատվածում հանդիպումներ չլինեն։',
      startTimeRequired: 'Սկսման ժամանակը պարտադիր է',
      startTimeInvalid: 'Սկզբի ժամը պետք է լինի ավելի վաղ, քան ավարտի ժամը:',
      endTimeInvalid: 'Ավարտի ժամը պետք է լինի ավելի ուշ, քան սկզբի ժամը:',
      endTimeRequired: 'Ավարտի ժամանակը պարտադիր է',
      timeOffCreatedSuccess: 'Արձակուրդը հաջողությամբ ստեղծվել է։',
      timeOffCreatedFailure: 'Սխալ անջատում ստեղծելիս',
      timeOffUpdatedSuccess: 'Արձակուրդը հաջողությամբ թարմացվել է։',
      timeOffUpdatedFailure: 'Սխալ անջատումը թարմացնելիս',
      titleOptional: 'Վերնագիր (ընտրովի)',
      TimeOffDeleteModalText: 'Համոզված եք, որ ցանկանում եք ջնջել հանգստի ժամանակը?',
      TimeOffDeleted: 'Հանգստի ժամանակը հաջողությամբ ջնջված է',
    },
  },
};

export default resources;
