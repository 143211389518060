import {
  CircularProgress,
  Drawer,
  Button, FormControl, FormHelperText, TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import DeleteButton from '../../../../../../MaterialComponents/deleteButton';
import {
  createTimeOffRequest,
  deleteAppointmentRequest,
  getCalendarAppointmentsRequest,
  updateTimeOffRequest,
} from '../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import AppointmentActionModal from './ActionModal';
import AppDatePicker from '../../../../../../Modules/AppDatePicker';
import Staff from './Items/staff';

const timeOptions = Array.from({ length: 24 }, (_, hour) => ['00', '15', '30', '45'].map((min) => `${hour.toString().padStart(2, '0')}:${min}`)).flat();
const getDatesPeriod = (calendarMode, date = null) => {
  const currentDate = date ? moment(date) : moment();

  switch (calendarMode) {
    case 'resourceTimeGridDay': {
      return {
        start_date: currentDate.format('YYYY-MM-DD'),
        end_date: currentDate.format('YYYY-MM-DD'),
      };
    }
    case 'resourceTimeGridWeek': {
      return {
        start_date: currentDate.startOf('week').format('YYYY-MM-DD'),
        end_date: currentDate.endOf('week').format('YYYY-MM-DD'),
      };
    }
    case 'resourceDayGridMonth': {
      return {
        start_date: currentDate.startOf('month').format('YYYY-MM-DD'),
        end_date: currentDate.endOf('month').format('YYYY-MM-DD'),
      };
    }
    default: {
      return {
        start_date: currentDate.format('YYYY-MM-DD'),
        end_date: currentDate.format('YYYY-MM-DD'),
      };
    }
  }
};

function TimeOffDrawer({
  isOpen,
  onClose,
  timeOff = {},
  snackBarAlert,
  appointments,
  setAppointments,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyGot } = useSelector((state) => state.company);

  const {
    isDeleteAppointmentSuccess,
    isDeletingAppointment,
    isCreatingTimeOff,
    isUpdatingTimeOff,
    isUpdatingTimeOffSuccess,
    isUpdatingTimeOffError,
    isCreatingTimeOffSuccess,
    isCreatingTimeOffFailure,
  } = useSelector((state) => state.appointment);

  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsUpdatingTimeOffSuccess = usePrevious(isUpdatingTimeOffSuccess);
  const prevIsUpdatingTimeOffError = usePrevious(isUpdatingTimeOffError);
  const prevIsCreatingTimeOffSuccess = usePrevious(isCreatingTimeOffSuccess);
  const prevIsCreatingTimeOffFailure = usePrevious(isCreatingTimeOffFailure);

  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [errors, setErrors] = useState({
    date: { error: false, message: 'Date is required' },
    start_time: { error: false, message: 'Start date is required' },
    end_time: { error: false, message: 'End date is required' },
  });
  const [timeOffItem, setTimeOffItem] = useState({
    ...timeOff,
    title: timeOff?.title || '',
    staff_id: companyGot.is_staff_based ? timeOff.staff_id : companyGot.user_id,
    date: timeOff.date ? timeOff.date : moment(),
    start_time: timeOff?.start_time || '00:00',
    end_time: timeOff.start_time ? moment(timeOff.start_time, 'HH:mm').add(timeOff.duration, 'minutes').format('HH:mm') : '00:00',
  });
  const [staffErrors, setStaffErrors] = useState({
    staff: false,
  });

  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      snackBarAlert(true, t('TimeOffDeleted'), 'success');
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === timeOff.id);
      appointmentsCopy.splice(index, 1);
      setAppointments(appointmentsCopy);
      onClose();
    }
  }, [isDeleteAppointmentSuccess]);

  const getAppointments = (datesPeriodObj) => {
    const checkedStatuses = localStorage.getItem('calendarCheckedStatuses');
    dispatch(getCalendarAppointmentsRequest({
      ...datesPeriodObj,
      status_ids: JSON.parse(checkedStatuses),
    }));
  };

  useEffect(() => {
    if (isCreatingTimeOffSuccess && prevIsCreatingTimeOffSuccess === false) {
      snackBarAlert(true, t('timeOffCreatedSuccess'), 'success');
      getAppointments(getDatesPeriod(window.localStorage.getItem('currentCalendarMode'), timeOffItem.date));
      onClose();
    } else if (isCreatingTimeOffFailure && prevIsCreatingTimeOffFailure === false) {
      snackBarAlert(true, t('timeOffCreatedFailure'), 'error');
    } else if (isUpdatingTimeOffSuccess && prevIsUpdatingTimeOffSuccess === false) {
      snackBarAlert(true, t('timeOffUpdatedSuccess'), 'success');
      getAppointments(getDatesPeriod(window.localStorage.getItem('currentCalendarMode'), timeOffItem.date));
      onClose();
    } else if (isUpdatingTimeOffError && prevIsUpdatingTimeOffError === false) {
      snackBarAlert(true, t('timeOffUpdatedFailure'), 'error');
    }
  }, [isCreatingTimeOffSuccess, isCreatingTimeOffFailure, isUpdatingTimeOffSuccess, isUpdatingTimeOffError]);

  const deleteApp = () => {
    dispatch(deleteAppointmentRequest({ id: timeOff.id }));
  };

  const handleChange = (name, value) => {
    setTimeOffItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!timeOffItem.date) {
      newErrors.date = { error: true, message: 'Date is required' };
      isValid = false;
    } else {
      newErrors.date = { error: false, message: '' };
    }

    if (!timeOffItem.start_time) {
      newErrors.start_time = { error: true, message: t('startTimeRequired') };
      isValid = false;
    } else {
      newErrors.start_time = { error: false, message: '' };
    }

    if (!timeOffItem.end_time) {
      newErrors.end_time = { error: true, message: t('endTimeRequired') };
      isValid = false;
    } else {
      const startTime = moment(timeOffItem.start_time, 'HH:mm');
      const endTime = moment(timeOffItem.end_time, 'HH:mm');

      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        newErrors.end_time = { error: true, message: t('endTimeInvalid') };
        isValid = false;
      } else {
        newErrors.end_time = { error: false, message: '' };
      }

      if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
        newErrors.start_time = { error: true, message: t('startTimeInvalid') };
        isValid = false;
      } else {
        newErrors.start_time = { error: false, message: '' };
      }
    }

    setStaffErrors({
      staff: !timeOffItem.staff_id,
    });

    if (!timeOffItem.staff_id) isValid = false;

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = () => {
    if (!validate()) return;

    const formattedData = {
      title: timeOffItem.title,
      staff_id: companyGot.is_staff_based ? timeOffItem.staff_id : companyGot.user_id,
      date: moment(timeOffItem.date).format('YYYY-MM-DD'),
      start_time: timeOffItem.start_time || '00:00',
      end_time: timeOffItem.end_time || '00:00',
    };

    if (timeOff?.id) {
      dispatch(updateTimeOffRequest({ id: timeOff?.id, data: formattedData }));
    } else {
      dispatch(createTimeOffRequest({ data: formattedData }));
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      className="appointment-drawer"
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          maxWidth: '563px',
          width: '100%',
        },
      }}
      id="appointment-details-drawer"
    >
      <div className="drawer-content">
        <div>
          <div>
            <div className="title-content">
              <div className="time-off-title-content">
                <div className="time-off-actions">
                  <h3 className="title">
                    {timeOff.id ? t('editTimeOff') : t('newTimeOff')}
                  </h3>
                  <CloseIcon className="close-icon" onClick={onClose} />
                </div>
                <p className="time-off-description">{t('timeOffDescription')}</p>
              </div>
            </div>
          </div>

          <div className="input-content details">
            <div>
              <div className="input-title-box">
                <span className="input-title">
                  {t('Title')}
                </span>
              </div>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
              >
                <TextField
                  fullWidth
                  size="small"
                  label={`${t('titleOptional')}`}
                  data-cy="title"
                  data-customer="title"
                  variant="outlined"
                  type="text"
                  name="title"
                  value={timeOffItem.title}
                  onChange={(e) => handleChange('title', e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          {companyGot.is_staff_based && (
            <div className="input-content" style={{ paddingTop: '15px' }}>
              {
                timeOff.id ? (
                  <div className="input-content details">
                    <div className="input-title-box start">
                      <AssignmentIndOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
                      <div>
                        <span className="input-title">{t('Staff')}</span>
                        <div className="info-text">{timeOff?.staff_name}</div>
                      </div>
                    </div>
                  </div>
                ) : <Staff handleChange={handleChange} errors={staffErrors} appointmentItem={timeOff} isFirst />
              }
            </div>
          )}
          <div className="input-content details" style={{ display: 'flex', flexDirection: 'column', paddingTop: '15px' }}>
            <div className="input-title-box">
              <DateRangeOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
              <span className="input-title">
                {t('DateAndTime')}
              </span>
            </div>
            <div>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={!!errors['date']}
              >
                <AppDatePicker
                  inputVariant="outlined"
                  selectedDate={timeOffItem.date}
                  onChange={(date) => handleChange('date', date)}
                  error={errors['date'].error}
                  disablePast
                />

                { errors['date'].error && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {t('Errors.IsRequired') }
                  </FormHelperText>
                </div>
                )}
              </FormControl>

            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', gap: '15px', marginTop: '15px',
            }}
            >
              <FormControl fullWidth size="small" variant="outlined" error={errors.start_time.error}>
                <Autocomplete
                  size="small"
                  fullWidth
                  autoHighlight
                  options={timeOptions}
                  value={timeOffItem.start_time || '00:00'}
                  onChange={(_, newValue) => handleChange('start_time', newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label={`${t('StartTime')} *`} variant="outlined" error={errors.start_time.error} />
                  )}
                />
                {errors.start_time?.error && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {errors.start_time.message}
                  </FormHelperText>
                </div>
                )}
              </FormControl>

              <FormControl fullWidth size="small" variant="outlined" error={errors.end_time.error}>
                <Autocomplete
                  size="small"
                  fullWidth
                  autoHighlight
                  options={timeOptions}
                  value={timeOffItem.end_time || '00:00'}
                  onChange={(_, newValue) => handleChange('end_time', newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label={`${t('EndTime')} *`} variant="outlined" error={errors.end_time.error} />
                  )}
                />
                {errors.end_time?.error && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {errors.end_time.message}
                  </FormHelperText>
                </div>
                )}
              </FormControl>
            </div>
          </div>
        </div>
        <div>
          <div className="buttons-box align-content-start" style={{ paddingLeft: 0, paddingRight: 0, gap: '15px' }}>
            <div className="create-btn btn">
              <Button
                size="small"
                onClick={handleSave}
                data-cy="save"
                type="submit"
                variant="contained"
                color="primary"
                loading={isCreatingTimeOff || isUpdatingTimeOff}
                style={{
                  cursor: 'pointer',
                  borderRadius: 4,
                  backgroundColor: '#0282DA',
                  padding: '8px 16px',
                  font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                  color: '#FFFFFF',
                  width: '100%',
                  height: '100%',
                }}
              >
                {(isCreatingTimeOff || isUpdatingTimeOff) && <CircularProgress color="white" size={20} />}
                {(!isCreatingTimeOff || !isUpdatingTimeOff) && t('Save') }
              </Button>
            </div>
            { timeOff.id && (
            <div className="btn">
              <DeleteButton
                variant="outlined"
                loading={isDeletingAppointment}
                startIcon={<DeleteIcon color="error" />}
                onClick={() => {
                  setActionType('delete');
                  setIsOpenActionModal(true);
                }}
              >
                {isDeletingAppointment && <CircularProgress color="white" size={20} />}
                {!isDeletingAppointment && t('Delete') }
              </DeleteButton>
            </div>
            )}
          </div>
        </div>
      </div>

      <AppointmentActionModal
        type={actionType}
        open={isOpenActionModal}
        onClose={() => setIsOpenActionModal(false)}
        handleDelete={deleteApp}
        handleCancel={() => {}}
        appointmentCancelLoading={false}
        appointmentDeleteLoading={isDeletingAppointment}
        fromTimeOff
      />
    </Drawer>
  );
}

TimeOffDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.object.isRequired,
  timeOff: PropTypes.object.isRequired,
  snackBarAlert: PropTypes.func.isRequired,
  appointments: PropTypes.array.isRequired,
  setAppointments: PropTypes.func.isRequired,
  checkedItems: PropTypes.array.isRequired,
  staffId: PropTypes.number.isRequired,
};

export default TimeOffDrawer;
