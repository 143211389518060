// Common Components
import AcceptInvitation from '../Containers/Private/Invitations/AcceptInvitation/acceptInvitation';
import AcceptCustomerInvitation from '../Containers/Private/Invitations/AcceptCustomerInvitation/acceptCustomerInvitation';
import AcceptIndividualCustomerInvitation from '../Containers/Private/Invitations/AcceptIndividualCustomerInvitation/acceptIndividualCustomerInvitation';
import EmailVerify from '../Containers/Public/EmailVerify/emailVerify';
import Register from '../Containers/Public/Register/register';
import Login from '../Containers/Public/Login/login';
import ForgotPassword from '../Containers/Public/Password/forgotPassword';
import ResetPassword from '../Containers/Public/Password/resetPassword';
import NotVerifiedEmail from '../Containers/Private/Onboarding/NotVerifiedEmail/notVerifiedEmail';
import Onboarding from '../Containers/Private/Onboarding';
import AccountType from '../Containers/Private/Onboarding/AccountType';
import PrivacyAndCookies from '../Containers/Public/PrivacyandCookies/privacyandcookies';
import FavouriteIndustry from '../Containers/Private/Onboarding/PersonalDetails/FavouriteIndustry/favouriteIndustry';
// Owner Component
import PersonalDetails from '../Containers/Private/Onboarding/PersonalDetails';
import BusinessDetails from '../Containers/Private/Onboarding/BusinessDetails';
import CompanyDetails from '../Containers/Private/Roles/Owner/Companies/details/details';
import AppointmentDetails from '../Containers/Private/Roles/Owner/Appointments/details';
import StaffAppointmentDetails from '../Containers/Private/Roles/Staff/Appointments/details';
import Profile from '../Containers/Private/Roles/Owner/Profile/profile';
import OwnerSettings from '../Containers/Private/Roles/Owner/Settings/settings';
import Messages from '../Containers/Private/Roles/Owner/Messages/messages';
import Notifications from '../Containers/Private/Roles/Owner/Notifications/notifications';
import Service from '../Containers/Private/Roles/Owner/Service/service';
import Gallery from '../Containers/Private/Roles/Owner/Gallery/gallery';
import WorkingSchedule from '../Containers/Private/Roles/Owner/WorkingSchedule/workingSchedule';
// Staff Components
import StaffCalendar from '../Containers/Private/Roles/Staff/Calendar/calendar';
import StaffCustomers from '../Containers/Private/Roles/Staff/Customers/customers';
import StaffMessages from '../Containers/Private/Roles/Staff/Messages/messages';
import StaffCompanyDetails from '../Containers/Private/Roles/Staff/Companies/details';
import IndividualUserIndustryDetails from '../Containers/Private/Roles/Individual/Industries/details/details';
import StaffNotifications from '../Containers/Private/Roles/Staff/Notifications/notifications';
// Customer Components
import CustomerCalendar from '../Containers/Private/Roles/Customer/Calendar/calendar';
import CustomerMessages from '../Containers/Private/Roles/Customer/Messages/messages';
import CustomerSettings from '../Containers/Private/Roles/Customer/Settings/settings';
import CustomerReminder from '../Containers/Private/Roles/Customer/Reminder/reminder';
import CreateOrEditAppointment from '../Containers/Private/Roles/Customer/CreateOrEditAppointment/createOrEditAppointment';
import CustomerNotifications from '../Containers/Private/Roles/Customer/Notifications/notifications';
import CustomerAppointmentDetails from '../Containers/Private/Roles/Customer/Appointments/details';
// Individual Components
import IndividualCalendar from '../Containers/Private/Roles/Individual/Calendar/calendar';
import IndividualCustomers from '../Containers/Private/Roles/Individual/Customers/customers';
import IndividualMessages from '../Containers/Private/Roles/Individual/Messages/messages';
import IndividualAppointmentDetails from '../Containers/Private/Roles/Individual/Appointments/details';
import Industries from '../Containers/Private/Roles/Individual/Industries/index';
import IndividualUserAddServices from '../Containers/Private/Roles/Individual/Industries/details/tabs/addServices';
import IndividualSettings from '../Containers/Private/Roles/Individual/Settings/settings';
// General Components
import GeneralCalendar from '../Containers/Private/General/Calendar';
import CustomerAppointments from '../Containers/Private/Roles/Customer/MyAppointments';
import StaffAppointments from '../Containers/Private/Roles/Staff/MyAppointments';
import OwnerAppointments from '../Containers/Private/Roles/Owner/MyAppointments';
import IndividualAppointments from '../Containers/Private/Roles/Individual/MyAppointments';
import GeneralCustomers from '../Containers/Private/General/Customers';
import GeneralMessages from '../Containers/Private/General/Messages';
import GeneralSettings from '../Containers/Private/General/Settings';
import GeneralAppointmentDetails from '../Containers/Private/General/AppointmentDetails';
import AddServices from '../Containers/Private/Roles/Owner/Service/addServices';
import GoogleOAuth from '../Containers/Private/General/GoogleOAuth';
import Unsubscribe from '../Containers/Public/Unsubscribe';
import Statistics from '../Containers/Private/General/Statistics';
import NewCustomers from '../Containers/Private/Roles/Owner/Customers/newCustomers';
import NewStaff from '../Containers/Private/Roles/Owner/Staff/newStaff';
import NewCalendar from '../Containers/Private/Roles/Owner/NewCalendar';
import VerificationCode from '../Containers/Public/VerificationCode/verificationCode';
import EmailVerifyV2 from '../Containers/Public/EmailVerify/emailVerifyV2';
import SendEmail from '../Containers/Public/Register/sendEmail';

const routes = [
  {
    id: 1,
    path: '/',
    component: Login,
    isPrivate: false,
  },
  {
    id: 2,
    path: '/login/:redirect?',
    component: Login,
    isPrivate: false,
  },
  {
    id: 3,
    path: '/register',
    component: Register,
    isPrivate: false,
  },
  {
    id: 4,
    path: '/verify-email',
    component: EmailVerifyV2,
    isPublic: true,
  },
  {
    id: 5,
    path: '/calendar/:id?',
    ownerComponent: NewCalendar,
    staffComponent: StaffCalendar,
    customerComponent: CustomerCalendar,
    individualComponent: IndividualCalendar,
    generalComponent: GeneralCalendar,
    isPrivate: true,
    name: 'calendar',
  },
  {
    id: 6,
    path: '/customers/:filter?',
    ownerComponent: NewCustomers,
    staffComponent: StaffCustomers,
    individualComponent: IndividualCustomers,
    generalComponent: GeneralCustomers,
    isPrivate: true,
    name: 'customers',
  },
  {
    id: 7,
    path: '/accept-invitation/:token',
    component: AcceptInvitation,
    isPublic: true,
  },
  // {
  //   id: 8,
  //   path: '/companies',
  //   ownerComponent: Companies,
  //   staffComponent: StaffCompanies,
  //   isPrivate: true,
  //   name: 'companies',
  // },
  {
    id: 9,
    path: '/company',
    ownerComponent: CompanyDetails,
    staffComponent: StaffCompanyDetails,
    isPrivate: true,
    name: 'company',
  },
  {
    id: 11,
    path: '/messages/:id?',
    ownerComponent: Messages,
    staffComponent: StaffMessages,
    customerComponent: CustomerMessages,
    individualComponent: IndividualMessages,
    generalComponent: GeneralMessages,
    isPrivate: true,
    name: 'messages',
  },
  {
    id: 12,
    path: '/reminders',
    component: CustomerReminder,
    isPrivate: true,
    name: 'reminders',
  },
  {
    id: 13,
    path: '/profile',
    component: Profile,
    isPrivate: true,
    name: 'profile',
  },
  {
    id: 14,
    path: '/create-appointment',
    customerComponent: CreateOrEditAppointment,
    isPrivate: true,
    name: 'create-appointment',
  },
  {
    id: 15,
    path: '/forgot-password',
    component: ForgotPassword,
    isPrivate: false,
    name: 'forgot-password',
  },
  {
    id: 16,
    path: '/reset-password',
    component: ResetPassword,
    isPrivate: false,
    name: 'reset-password',
  },
  {
    id: 17,
    path: '/accept-customer-invitation/:token',
    component: AcceptCustomerInvitation,
    isPublic: true,
  },
  {
    id: 18,
    path: '/my-appointments',
    ownerComponent: OwnerAppointments,
    staffComponent: StaffAppointments,
    customerComponent: CustomerAppointments,
    individualComponent: IndividualAppointments,
    isPrivate: true,
    name: 'appointments',
  },
  {
    id: 19,
    path: '/my-appointments/:appointmentId',
    customerComponent: CustomerAppointmentDetails,
    ownerComponent: AppointmentDetails,
    staffComponent: StaffAppointmentDetails,
    individualComponent: IndividualAppointmentDetails,
    generalComponent: GeneralAppointmentDetails,
    isPrivate: true,
    name: 'appointments',
  },
  {
    id: 20,
    path: '/industries',
    individualComponent: Industries,
    isPrivate: true,
    name: 'industries',
  },
  {
    id: 21,
    path: '/industries/:id/:tab?/:filter?',
    individualComponent: IndividualUserIndustryDetails,
    isPrivate: true,
    name: 'industries',
  },
  {
    id: 22,
    path: '/accept-individual-customer-invitation/:token',
    component: AcceptIndividualCustomerInvitation,
    isPublic: true,
  },
  {
    id: 23,
    path: '/notifications',
    ownerComponent: Notifications,
    customerComponent: CustomerNotifications,
    staffComponent: StaffNotifications,
    isPrivate: true,
    name: 'notifications',
  },
  {
    id: 24,
    path: '/account-type',
    component: AccountType,
    isPrivate: true,
    name: 'account-type',
  },
  {
    id: 25,
    path: '/personal-details',
    component: PersonalDetails,
    isPrivate: true,
    name: 'personal-details',
  },
  {
    id: 26,
    path: '/business-details',
    component: BusinessDetails,
    isPrivate: true,
    name: 'business-details',
  },
  {
    id: 27,
    path: '/email/not-verified',
    component: NotVerifiedEmail,
    isPrivate: false,
  },
  {
    id: 28,
    path: '/onboarding',
    component: Onboarding,
    isPrivate: false,
  },
  {
    id: 29,
    path: '/privacy',
    component: PrivacyAndCookies,
    isPrivate: false,
  },
  {
    id: 30,
    path: '/favourite-industry',
    component: FavouriteIndustry,
    isPrivate: false,
  },
  {
    id: 31,
    path: '/create-account',
    component: SendEmail,
    isPrivate: false,
  },
  {
    id: 32,
    path: '/f/:companyId/service/:serviceId',
    ownerComponent: AddServices,
    isPrivate: true,
  },
  {
    id: 33,
    path: '/settings:id?',
    isPrivate: true,
    name: 'settings',
    ownerComponent: OwnerSettings,
    customerComponent: CustomerSettings,
    generalComponent: GeneralSettings,
    individualComponent: IndividualSettings,
  },
  {
    id: 34,
    path: '/individual-user/:id/service/:serviceId',
    individualComponent: IndividualUserAddServices,
    isPrivate: true,
    name: 'industries',
  },
  {
    id: 35,
    path: '/oauth2callback',
    component: GoogleOAuth,
    isPrivate: true,
    name: 'googleAuthentication',
  },
  {
    id: 36,
    path: '/staff',
    ownerComponent: NewStaff,
    isPrivate: true,
    name: 'staff',
  },
  {
    id: 36,
    path: '/service',
    ownerComponent: Service,
    isPrivate: true,
    name: 'Service',
  },
  {
    id: 36,
    path: '/service/:id',
    ownerComponent: AddServices,
    isPrivate: true,
    name: 'Service',
  },
  {
    id: 37,
    path: '/gallery',
    ownerComponent: Gallery,
    isPrivate: true,
    name: 'Gallery',
  },
  {
    id: 38,
    path: '/working-schedule/:tab?',
    ownerComponent: WorkingSchedule,
    isPrivate: true,
    name: 'workingSchedule',
  },
  {
    id: 39,
    path: '/unsubscribe/:token',
    component: Unsubscribe,
    isPublic: true,
  },
  {
    id: 40,
    path: '/statistics',
    component: Statistics,
    isPrivate: true,
  },
  {
    id: 41,
    path: '/verification-code',
    component: VerificationCode,
    isPrivate: false,
    name: 'verification-code',
  },
];

export default routes;
