import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { getUserLocationRequest } from 'redux/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import i18n from 'i18next';
import { languages } from 'Modules/constants';

import flag from 'assets/flags/flag';
import LogoImg from 'assets/img/logo.svg';
import WhiteLogoImg from 'assets/img/white-logo.svg';
import GooglePlayImg from 'assets/img/google-play.svg';
import AppStoreImg from 'assets/img/app-store.svg';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

import '../styles.scss';
import liveChat from 'Modules/crisp';
import usePrevious from '../../../CustomHooks/usePrevious';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    locationInfo,
    isGetUserLocationSuccess,
    isGetUserLocationError,
  } = useSelector((state) => state.account);

  const prevIsGetUserLocationSuccess = usePrevious(isGetUserLocationSuccess);

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    if (!prevIsGetUserLocationSuccess && isGetUserLocationSuccess && !isGetUserLocationError) {
      sessionStorage.setItem('realCountry', locationInfo.real_country);
    }
  }, [isGetUserLocationSuccess]);

  useEffect(() => {
    if (!locationInfo.real_country) {
      dispatch(getUserLocationRequest());
    }

    liveChat();

    if (location) {
      const path = handleBackTo(location.pathname);
      setPathName(path);
    }
  }, []);

  const handleChangeLanguage = (lng) => {
    const { value } = lng.target;
    i18n.changeLanguage(value).then((r) => r);
    setSelectedLanguage(value);
  };

  const handleBackTo = (pathname) => {
    let path = '';

    switch (pathname) {
      case '/forgot-password': {
        path = '/';
        break;
      }
      case '/verification-code': {
        path = '/forgot-password';
        break;
      }
      case '/reset-password': {
        path = '/forgot-password';
        break;
      }
    }

    return path;
  };

  return (
    <Box className="page-base">
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={6} md={0} className="grid">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://settime.io">
            <img
              src={WhiteLogoImg}
              alt="logo"
              height={49}
              className="logo"
            />
          </a>
          <h1 className="h1">Appointment Scheduling Software</h1>
          <Box className="login-container" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width="100%">
            <Box className="header-box">
              <Box className="logo-box">
                <Link to="/login">
                  <img src={LogoImg} alt="logo" />
                </Link>
              </Box>
              <Box className="content-header">
                <Box>
                  <Link
                    to={pathName}
                    style={{ display: (location.pathname === '/') ? 'none' : 'flex', alignItems: 'center' }}
                  >
                    <KeyboardBackspaceRoundedIcon style={{ color: '#393939', marginRight: '5px' }} />
                    <span className="back-text">Back</span>
                  </Link>
                </Box>
                <Box>
                  <Box className="languages-dropdown">
                    {locationInfo && locationInfo.real_country === 'Armenia' && (
                    <div>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="open-select" />
                        <Select
                          onChange={handleChangeLanguage}
                          value={selectedLanguage}
                          disableUnderline
                          inputProps={{
                            id: 'open-select',
                          }}
                          variant="standard"
                          style={{ boxShadow: 'none', border: 0 }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            style: { zIndex: 99999, marginTop: 10 },
                            getContentAnchorEl: null,
                          }}
                          name="country"
                        >
                          {languages && languages.map((option, index) => (
                            <MenuItem
                              key={`${option.id}-${index}`}
                              value={option.value}
                              selected={selectedLanguage === option.label}
                            >
                              <img
                                src={flag[option.value]}
                                alt="flag"
                                className="country-flag"
                              />
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            {children}
            <Box className="app-box">
              <Box className="store-links">
                <Box>
                  <a href="https://play.google.com/store/apps/details?id=com.settime.business" target="_blank" rel="noopener noreferrer">
                    <img src={GooglePlayImg} alt="google play" />
                  </a>
                </Box>
                <Box>
                  <a href="https://apps.apple.com/am/app/settime-appointment-scheduler/id1605815683" target="_blank" rel="noopener noreferrer">
                    <img src={AppStoreImg} alt="app store" />
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
