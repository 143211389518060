const momentRelativeTime = {
  hy: {
    h: '1 ժամ',
    d: '1 օր',
    M: '1 ամիս',
    w: '1 շաբաթ',
  },
  en: {
    h: '1 hour',
    d: '1 day',
    M: '1 month',
    w: '1 week',
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    hh: '%dh',
    dd: '%d days',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
  fr: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    ss: '%d secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    w: 'une semaine',
    ww: '%d semaines',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
  es: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    w: 'una semana',
    ww: '%d semanas',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  de: {
    h: '1 Stunde',
    d: '1 Tag',
    M: '1 Monat',
    w: '1 Woche',
    future: 'in %s',
    past: 'vor %s',
    s: 'ein paar Sekunden',
    ss: '%d Sekunden',
    m: 'eine Minute',
    mm: '%d Minuten',
    hh: '%dst',
    dd: '%d Tage',
    MM: '%d Monate',
    y: 'ein Jahr',
    yy: '%d Jahre',
  },
  pt: {
    h: '1 hora',
    d: '1 dia',
    M: '1 mês',
    w: '1 semana',
    future: 'em %s',
    past: 'há %s',
    s: 'alguns segundos',
    ss: '%d segundos',
    m: 'um minuto',
    mm: '%d minutos',
    hh: '%dh',
    dd: '%d dias',
    MM: '%d meses',
    y: 'um ano',
    yy: '%d anos',
  },
  ar: {
    h: 'ساعة واحدة',
    d: 'يوم واحد',
    M: 'شهر واحد',
    w: 'أسبوع واحد',
    future: 'في %s',
    past: 'منذ %s',
    s: 'بضع ثوانٍ',
    ss: '%d ثانية',
    m: 'دقيقة واحدة',
    mm: '%d دقائق',
    hh: '%d س',
    dd: '%d أيام',
    MM: '%d أشهر',
    y: 'سنة واحدة',
    yy: '%d سنوات',
  },
  ru: {
    h: '1 час',
    d: '1 день',
    M: '1 месяц',
    w: '1 неделя',
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    ss: '%d секунд',
    m: 'минута',
    mm: '%d минут',
    hh: '%dч',
    dd: '%d дней',
    MM: '%d месяцев',
    y: 'год',
    yy: '%d лет',
  },
  it: {
    h: '1 ora',
    d: '1 giorno',
    M: '1 mese',
    w: '1 settimana',
    future: 'tra %s',
    past: '%s fa',
    s: 'alcuni secondi',
    ss: '%d secondi',
    m: 'un minuto',
    mm: '%d minuti',
    hh: '%dh',
    dd: '%d giorni',
    MM: '%d mesi',
    y: 'un anno',
    yy: '%d anni',
  },
};

const momentWeekDaysShortName = {
  hy: ['Կրկ', 'Երկ', 'Երք', 'Չրք', 'Հնգ', 'Ուրբ', 'Շբթ'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  pt: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  ar: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  it: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
};

const momentMonthsName = {
  hy: [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  pt: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  ar: [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  it: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
};

const momentMonthShortName = {
  hy: [
    'Հնվ.',
    'Փտր.',
    'Մրտ.',
    'Ապր.',
    'Մյս.',
    'Հնս.',
    'Հլս.',
    'Օգս.',
    'Սպտ.',
    'Հկտ.',
    'Նյմ.',
    'Դկտ.',
  ],
  en: [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May.',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ],
  fr: [
    'Janv.',
    'Févr.',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juil.',
    'Août',
    'Sept.',
    'Oct.',
    'Nov.',
    'Déc.',
  ],
  es: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.',
  ],
  de: [
    'Jan.',
    'Feb.',
    'März',
    'Apr.',
    'Mai',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sep.',
    'Okt.',
    'Nov.',
    'Dez.',
  ],
  pt: [
    'Jan.',
    'Fev.',
    'Mar.',
    'Abr.',
    'Mai.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Set.',
    'Out.',
    'Nov.',
    'Dez.',
  ],
  ar: [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  ru: [
    'Янв.',
    'Фев.',
    'Мар.',
    'Апр.',
    'Май.',
    'Июн.',
    'Июл.',
    'Авг.',
    'Сен.',
    'Окт.',
    'Ноя.',
    'Дек.',
  ],
  it: [
    'Gen.',
    'Feb.',
    'Mar.',
    'Apr.',
    'Mag.',
    'Giu.',
    'Lug.',
    'Ago.',
    'Set.',
    'Ott.',
    'Nov.',
    'Dic.',
  ],
};

export {
  momentRelativeTime,
  momentWeekDaysShortName,
  momentMonthsName,
  momentMonthShortName,
};
