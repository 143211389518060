import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import i18n from 'i18next';

import {
  AppBar,
  TextField,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Toolbar,
  Typography,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

import { emailRegex } from '../../../Modules/regexValidations';
import '../styles.scss';

import {
  sendEmailRequest,
} from 'redux/auth/actions';

import Logo from 'assets/img/logo.svg';
import googleSocial from 'assets/img/google.svg';
import fbSocial from 'assets/img/facebook.svg';

const emailErrorMessages = {
  isRequired: i18n.t('EmailRequired'),
  invalidEmail: i18n.t('EmailValid'),
};

function SendEmail() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isSendEmailSuccess,
    isSendEmailError,
    sendEmailErrors,
  } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Send email success
  useEffect(() => {
    if (isSendEmailSuccess) {
      history.push('/verify-email');
    } else if (isSendEmailError) {
      setErrors(sendEmailErrors);
      setLoading(false);
    }
  }, [isSendEmailSuccess, isSendEmailError]);

  // TODO: Change overall oAuth logic

  const isEmailInvalid = () => {
    const errorsCopy = { ...errors };
    errorsCopy.email = email === '' ? emailErrorMessages.isRequired : '';
    errorsCopy.validEmail = !emailRegex.test(email.trim()) ? emailErrorMessages.invalidEmail : '';

    setErrors(errorsCopy);
    return errorsCopy.email || errorsCopy.validEmail;
  };

  const send = () => {
    if (!isEmailInvalid()) {
      setLoading(true);
      dispatch(sendEmailRequest({ email, lang: i18n.language, userAgent: '' }));
    }
  };

  const handleSubmit = (e) => {
    if (e.key !== 'Enter') return;

    e.preventDefault();
    send();
  };

  return (
    <div className="auth page-base">
      <Container>
        <AppBar color="transparent" position="static" elevation={0} className="login-header">
          <Toolbar>
            <Box mx="auto" className="auth-logo">
              <Link to="/login">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        <Grid container justifyContent="center">
          <Grid xs={12} sm={7} md={4}>
            <div className="form-container" style={{ margin: 0 }}>
              <Box className="auth-box form-box" px="40px" py="30px">
                <Link to="/login" className="back-button">
                  <KeyboardBackspaceRoundedIcon style={{ color: '#393939' }} />
                </Link>

                <p className="title">
                  {i18n.t('signUpWithEmail')}
                </p>
                <p className="sub-title">{i18n.t('WeWillSendVerificationCode')}</p>

                <FormControl fullWidth variant="outlined" margin="dense" error={!!errors.email || !!errors.validEmail}>
                  <TextField
                    fullWidth
                    error={!!errors.email || !!errors.validEmail}
                    id="outlined-basic"
                    data-cy="email"
                    type="email"
                    size="small"
                    name="email"
                    label={i18n.t('Email')}
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => handleSubmit(e)}
                    autoFocus
                  />

                  {errors.email && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{errors.email}</FormHelperText>
                    </div>
                  )}

                  {errors.validEmail && !errors.email && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{errors.validEmail}</FormHelperText>
                    </div>
                  )}
                </FormControl>

                <Box my="24px">
                  <Button
                    disabled={loading}
                    fullWidth
                    color="primary"
                    variant="contained"
                    data-cy="button"
                    onClick={send}
                  >
                    {loading && <CircularProgress color="white" size={20} />}
                    {!loading && i18n.t('Next')}
                  </Button>
                </Box>
                <Box align="center" mt="15px">
                  <Typography color="secondary">{i18n.t('signUpWith')}</Typography>
                  <Box mt="15px">
                    <Box style={{ cursor: 'pointer' }} display="inline-block" width="30px">
                      <GoogleLogin
                        clientId="791612533528-m5p17bs00r0j5uskodsg6ctnpr1anu4o.apps.googleusercontent.com"
                        render={(renderProps) => (
                          <img onClick={renderProps.onClick} src={googleSocial} alt="google-icon" />
                        )}
                        buttonText="Login"
                        onSuccess={/*responseGoogle*/ () => null}
                        onFailure={/*responseGoogle*/ () => null}
                        cookiePolicy="single_host_origin"
                      />
                    </Box>
                    <Box style={{ cursor: 'pointer' }} display="inline-block" width="30px" mx="15px">
                      <FacebookLogin
                        appId="274739430714027"
                        fields="name,email,picture"
                        callback={/*responseFacebook*/ () => null}
                        render={(renderProps) => <img onClick={renderProps.onClick} src={fbSocial} alt="facebook-icon" />}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box mt="24px">
                  <Typography align="center" color="secondary">
                    {i18n.t('AlreadyHaveAccount') + ' '}
                    <Link to="/login">
                      <Typography component="span" color="primary">
                        {i18n.t('SignIn')}
                      </Typography>
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SendEmail;
