import moment from 'moment';
import {
  momentRelativeTime, momentWeekDaysShortName, momentMonthsName, momentMonthShortName,
} from 'Modules/momentLocales';

import getMomentLocale from 'Modules/getMomentLocale';

function getNotificationTimeDifference(date, tz, result) {
  const locale = getMomentLocale(result);
  moment.locale(locale, {
    relativeTime: momentRelativeTime[result],
    weekdaysShort: momentWeekDaysShortName[result],
    months: momentMonthsName[result],
    monthsShort: momentMonthShortName[result],
    weekdaysMin: momentWeekDaysShortName[result],
  });

  return moment(date).fromNow();
}

export default getNotificationTimeDifference;
