import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locals/en';
import hy from './locals/hy';
import fr from './locals/fr';
import es from './locals/es';
import de from './locals/de';
import pt from './locals/pt';
import ar from './locals/ar';
import it from './locals/it';
import ru from './locals/ru';

const Languages = ['en', 'hy', 'fr', 'es', 'de', 'pt', 'ar', 'it', 'ru'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ...en,
      ...hy,
      ...fr,
      ...es,
      ...de,
      ...pt,
      ...ar,
      ...it,
      ...ru,
    },
    fallbackLng: {
      en: ['en'],
      hy: ['hy'],
      fr: ['fr'],
      es: ['es'],
      de: ['de'],
      pt: ['pt'],
      ar: ['ar'],
      it: ['it'],
      ru: ['ru'],
    },
    debug: false,
    whitelist: Languages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
