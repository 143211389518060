import React, { useState, useEffect } from 'react';
import { Popover, TextField } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const ColorPicker = ({ defaultColor, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (updatedColor) => {
    setColor(updatedColor.hex);
    onChange(updatedColor.hex);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setColor(value);
    onChange(value);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div
        style={{
          backgroundColor: color,
          color: '#fff',
          width: '30px',
          height: '30px',
          borderRadius: '8px',
          cursor: 'pointer',
          border: '1px solid #ccc',
        }}
        onClick={handleClick}
      />

      <TextField
        label="Color"
        variant="outlined"
        size="small"
        value={color}
        onChange={handleInputChange}
        style={{ width: '120px' }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SketchPicker
          color={color}
          onChange={handleColorChange}
        />
      </Popover>
    </div>
  );
};

ColorPicker.propTypes = {
  defaultColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
