const flag = {
  en: require('./en.svg'),
  hy: require('./hy.svg'),
  fr: require('./fr.svg'),
  es: require('./es.svg'),
  de: require('./de.svg'),
  pt: require('./pt.svg'),
  ar: require('./ar.svg'),
  it: require('./it.svg'),
  ru: require('./ru.svg'),
};

export default flag;
