import React, { Component } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  OutlinedInput,
  CircularProgress,
  AppBar,
  InputAdornment,
  FormControl,
  IconButton,
  Button,
  Box,
  Grid,
  Toolbar,
  FormHelperText,
  Container,
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ErrorIcon from '@material-ui/icons/Error';
import Logo from 'assets/img/logo.svg';
import './style.scss';

class Registration extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      loading: false,
      password: '',
      confirmPassword: '',
      formErrors: {},
      registrationErrors: {},
      showPassword: false,
      showConfirmPassword: false,
      validPassword: false,
    };
  }

  componentDidMount() {
    const { history, userAccount } = this.props;

    if (!userAccount || Object.keys(userAccount).length === 0) {
      history.push('/login');
    }
  }

  validateForm = (field) => {
    const { password, confirmPassword, firstName } = this.state;

    let { formErrors } = this.state;
    formErrors = { ...formErrors };

    const passwordError = !(password.length > 0);
    const passwordLengthError = !(password.length >= 8);
    const passwordFormatError = !password.match(/(?=.*[A-Z])/);
    const confirmPasswordLengthError = !(confirmPassword.length > 0);
    const confirmPasswordError = !(confirmPassword === password);
    const firstNameError = !(firstName.length > 0);

    switch (field) {
      case 'firstName': {
        formErrors.firstName = firstNameError;
        break;
      } case 'password': {
        formErrors.password = passwordError;
        formErrors.passwordLength = passwordLengthError;
        formErrors.passwordFormat = passwordFormatError;
        break;
      } case 'confirmPassword': {
        formErrors.confirmPasswordLength = confirmPasswordLengthError;
        formErrors.confirmPassword = confirmPasswordError;
        break;
      } default: {
        formErrors.password = passwordError;
        formErrors.passwordLength = passwordLengthError;
        formErrors.passwordFormat = passwordFormatError;
        formErrors.confirmPasswordLength = confirmPasswordLengthError;
        formErrors.confirmPassword = confirmPasswordError;
        formErrors.firstName = firstNameError;
      }
    }

    this.setState({ formErrors });
    return Object.values(formErrors).some((error) => error === true);
  };

  handleClickShowPassword = (type) => {
    const { showPassword, showConfirmPassword } = this.state;
    if (type === 'password') {
      this.setState({
        showPassword: !showPassword,
      });
    } else {
      this.setState({
        showConfirmPassword: !showConfirmPassword,
      });
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange = (event, isPassword = true) => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => this.validateForm(name));

    if (isPassword) {
      this.validationPassword(value);
    }
  };

  // TODO: Improve logic, get rid of non react patterns
  validationPassword = (value) => {
    const password = document.querySelector('.password');
    const helperText = {
      charLength: document.querySelector('.helper-text .length'),
      lowercase: document.querySelector('.helper-text .lowercase'),
      uppercase: document.querySelector('.helper-text .uppercase'),
      special: document.querySelector('.helper-text .special'),
    };
    const pattern = {
      charLength() {
        if (value.length >= 8) {
          return true;
        }
      },
      lowercase() {
        const regex = /^(?=.*[a-z]).+$/; // Lowercase character pattern

        if (regex.test(value)) {
          return true;
        }
      },
      uppercase() {
        const regex = /^(?=.*[A-Z]).+$/; // Uppercase character pattern

        if (regex.test(value)) {
          return true;
        }
      },
      special() {
        const regex = /^(?=.*[0-9_\W]).+$/; // Special character or number pattern

        if (regex.test(value)) {
          return true;
        }
      },
    };
    // Listen for keyup action on password field
    password.addEventListener('keyup', () => {
      // Check that password is a minimum of 8 characters
      this.patternTest(pattern.charLength(), helperText.charLength);

      // Check that password contains a lowercase letter
      this.patternTest(pattern.lowercase(), helperText.lowercase);

      // Check that password contains an uppercase letter
      this.patternTest(pattern.uppercase(), helperText.uppercase);

      // Check that password contains a number or special character
      this.patternTest(pattern.special(), helperText.special);

      // Check that all requirements are fulfilled
      if (
        this.hasClass(helperText.charLength, 'valid') &&
        this.hasClass(helperText.lowercase, 'valid') &&
        this.hasClass(helperText.uppercase, 'valid') &&
        this.hasClass(helperText.special, 'valid')
      ) {
        this.addClass(password.parentElement, 'valid');
        this.setState({
          validPassword: true,
        });
      } else {
        this.removeClass(password.parentElement, 'valid');
        this.setState({
          validPassword: false,
        });
      }
    });
  };

  addClass = (el, className) => {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ` ${className}`;
    }
  };

  removeClass = (el, className) => {
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
  };

  patternTest = (pattern, response) => {
    if (pattern) {
      this.addClass(response, 'valid');
    } else {
      this.removeClass(response, 'valid');
    }
  };

  hasClass = (el, className) => {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      new RegExp(`(^| )${className}( |$)`, 'gi').test(el.className);
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    const { firstName, lastName, password, confirmPassword, validPassword } = this.state;

    const { history } = this.props;
    this.setState({ registrationErrors: {} });

    if (this.validateForm()) {
      return false;
    } else if (validPassword && password === confirmPassword) {
      history.push({
        pathname: '/onboarding',
        state: { firstName, lastName, password },
      });
    }
  }

  render() {
    const {
      loading,
      firstName,
      lastName,
      password,
      formErrors,
      showPassword,
      confirmPassword,
      registrationErrors,
      showConfirmPassword,
    } = this.state;

    return (
      <div className="auth page-base">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <Link to="/login">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          <div className="form-container">
            <Grid container justifyContent="center">
              <Grid container item direction="row" justifyContent="center" xs={12} sm={7} md={4}>
                <div className="auth-box form-box">
                  <Box px="40px" py="30px">
                    <Box mb="30px">
                      <p className="title">
                        {i18n.t('PersonalInformation')}
                      </p>
                    </Box>
                    <form onSubmit={(e) => this.handleSubmit(e, password)}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <InputLabel htmlFor="first-name">{i18n.t('FirstName')}</InputLabel>
                              <OutlinedInput
                                autoComplete="off"
                                type="text"
                                id="first-name"
                                name="firstName"
                                label={i18n.t('FirstName')}
                                value={firstName}
                                onChange={(e) => this.handleChange(e, false)}
                                autoFocus
                              />

                              {formErrors.firstName && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('FirstName') + i18n.t('IsRequired')}</FormHelperText>
                                </div>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <InputLabel htmlFor="last-name">{i18n.t('LastName')}</InputLabel>
                              <OutlinedInput
                                autoComplete="off"
                                type="text"
                                id="last-name"
                                name="last-name"
                                label={i18n.t('LastName')}
                                value={lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              error={
                                !!formErrors.password ||
                                !!formErrors.passwordLength ||
                                !!formErrors.passwordFormat ||
                                !!registrationErrors.password
                              }
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-password"
                                error={
                                  !!formErrors.password ||
                                  !!formErrors.passwordLength ||
                                  !!formErrors.passwordFormat ||
                                  !!registrationErrors.password
                                }
                              >
                                {i18n.t('Password')}
                              </InputLabel>
                              <OutlinedInput
                                error={
                                  !!formErrors.password ||
                                  !!formErrors.passwordLength ||
                                  !!formErrors.passwordFormat ||
                                  !!registrationErrors.password
                                }
                                type={showPassword ? 'text' : 'password'}
                                id="password-icon"
                                name="password"
                                label="Password"
                                className="password"
                                value={password}
                                onChange={this.handleChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      aria-label="toggle password visibility"
                                      onClick={() => this.handleClickShowPassword('password')}
                                      onMouseDown={this.handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility color="primary" fontSize="small" />
                                      ) : (
                                        <VisibilityOff color="secondary" fontSize="small" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />

                              {registrationErrors.password && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{registrationErrors.password}</FormHelperText>
                                </div>
                              )}

                              {formErrors.password && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('PasswordRequired')}</FormHelperText>
                                </div>
                              )}

                              {formErrors.passwordLength && !formErrors.password && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('PasswordLengthRule')}</FormHelperText>
                                </div>
                              )}

                              {formErrors.passwordFormat && !formErrors.password && !formErrors.passwordLength && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('PasswordCapitalRule')}</FormHelperText>
                                </div>
                              )}
                            </FormControl>

                            <ul className="helper-text show">
                              <li className="length">{i18n.t('PasswordMinLength')}</li>
                              <li className="lowercase">{i18n.t('PasswordLowercase')}</li>
                              <li className="uppercase">{i18n.t('PasswordUppercase')}</li>
                              <li className="special">{i18n.t('PasswordSpecial')}</li>
                            </ul>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-password"
                                error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}
                              >
                                {i18n.t('ConfirmPassword')}
                              </InputLabel>
                              <OutlinedInput
                                error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="icon-password"
                                name="confirmPassword"
                                label="Confirm password"
                                value={confirmPassword}
                                onChange={this.handleChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this.handleClickShowPassword('confirm')}
                                      onMouseDown={this.handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <Visibility color="primary" fontSize="small" />
                                      ) : (
                                        <VisibilityOff color="secondary" fontSize="small" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />

                              {formErrors.confirmPasswordLength && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('ConfirmPassword') + i18n.t('IsRequired')}</FormHelperText>
                                </div>
                              )}

                              {!formErrors.confirmPasswordLength && formErrors.confirmPassword && (
                                <div className="error-message-content">
                                  <ErrorIcon fontSize="small" color="error" />
                                  <FormHelperText>{i18n.t('PasswordMismatch')}</FormHelperText>
                                </div>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                      <Box my="30px" align="center">
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          data-cy="submit"
                          disabled={this.loading}
                          type="submit"
                          loading={loading}
                        >
                          {loading && <CircularProgress color="white" size={20} />}
                          {!loading && i18n.t('Next')}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
});

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Registration);
