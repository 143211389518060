const getMomentLocale = (lang, mode = null) => {
  if (mode === 'date-picker') {
    switch (lang) {
      case 'en':
        return 'en_GB';
      case 'fr':
        return 'fr_CA';
      case 'ar':
        return 'ar_SA';
      default:
        return lang;
    }
  } else {
    switch (lang) {
      case 'hy':
        return 'hy-am';
      case 'ar':
        return 'ar-ly';
      case 'pt':
        return 'pt-br';
      default:
        return lang;
    }
  }
};

export default getMomentLocale;
