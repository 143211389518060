import { put, takeLatest } from 'redux-saga/effects';
import {
  loginRequest,
  loginSuccess,
  loginError,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailError,
  checkTokenRequest,
  checkTokenSuccess,
  checkTokenError,
  registerRequest,
  registerSuccess,
  registerError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  loginWithGoogleRequest,
  loginWithGoogleSuccess,
  loginWithGoogleError,
  loginWithFacebookSuccess,
  loginWithFacebookError,
  loginWithFacebookRequest,
  confirmVerificationCodeSuccess,
  confirmVerificationCodeError,
  confirmVerificationCodeRequest,
  resendVerificationCodeSuccess,
  resendVerificationCodeError,
  resendVerificationCodeRequest,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* authUser(action) {
  const { email, password, isRememberChecked } = action.payload;
  try {
    const response = yield axiosInstance.post('login', {
      email,
      password,
      remember_me: isRememberChecked,
    });
    if (response && response.status === 200) {
      yield put(loginSuccess(response.data));
    } else {
      yield put(loginError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(loginError(e.response.data));
    }
  }
}

function* sendEmail(action) {
  try {
    const response = yield axiosInstance.post('account/send-email-code', action.payload);
    if (response && response.status === 202) {
      sessionStorage.setItem('currentVerificationEmail', action.payload.email);
      yield put(sendEmailSuccess());
    } else {
      yield put(sendEmailError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(sendEmailError(e.response.data));
    }
  }
}

function* registerUser(action) {
  const {
    registerAs, password, confirmPassword, token,
  } = action.payload;
  try {
    const accountType = (registerAs === 'customer' ? '2' : (registerAs === 'owner' ? '1' : '3'));
    const response = yield axiosInstance.post('register', {
      token,
      password,
      password_confirmation: confirmPassword,
      account_type: accountType,
    });
    if (response && response.status === 200) {
      yield put(registerSuccess(response.data));
    } else {
      yield put(registerError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(registerError(e.response.data));
    }
  }
}

function* checkToken(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.get(`register/${token}`);
    if (response && response.status === 200) {
      yield put(checkTokenSuccess(response.data));
    } else {
      yield put(checkTokenError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(checkTokenError(e.response.data));
    }
  }
}

function* forgotPassword(action) {
  const { email } = action.payload;

  const response = yield axiosInstance.post('forgot-password/email', { email });
  if (response && response.status === 202) {
    yield put(forgotPasswordSuccess(JSON.parse(response.config.data).email));
  } else {
    yield put(forgotPasswordError(response.response.data));
  }
}

function* confirmVerificationCode(action) {
  const { payload } = action;

  const response = yield axiosInstance.post('forgot-password/confirm', payload);
  if (response && response.status === 200) {
    yield put(confirmVerificationCodeSuccess(response.data));
  } else {
    yield put(confirmVerificationCodeError(response.response.data));
  }
}

function* resetPassword(action) {
  const response = yield axiosInstance.post('forgot-password/reset', action.payload);
  if (response && response.status === 200) {
    yield put(resetPasswordSuccess(response.data));
  } else {
    yield put(resetPasswordError(response.response.data));
  }
}

function* resendVerificationCode(action) {
  const { email } = action.payload;

  const response = yield axiosInstance.post('forgot-password/email', { email });
  if (response && response.status === 202) {
    yield put(resendVerificationCodeSuccess(JSON.parse(response.config.data).email));
  } else {
    yield put(resendVerificationCodeError(response.response.data));
  }
}

function* loginWithGoogle(action) {
  const {
    email,
    token,
    clientId,
    ...props
  } = action.payload;
  const response = yield axiosInstance.post('login-with-google', {
    email,
    token,
    clientId,
    ...props,
  });
  if (response && response.status === 200) {
    yield put(loginWithGoogleSuccess(response.data));
  } else {
    yield put(loginWithGoogleError(response.response.data));
  }
}

function* loginWithFacebook(action) {
  const {
    email,
    token,
  } = action.payload;
  const response = yield axiosInstance.post('login-with-facebook', {
    email,
    token,
  });
  if (response && response.status === 200) {
    yield put(loginWithFacebookSuccess(response.data));
  } else {
    yield put(loginWithFacebookError(response.response.data));
  }
}

export default function* () {
  yield takeLatest(loginRequest, authUser);
  yield takeLatest(registerRequest, registerUser);
  yield takeLatest(sendEmailRequest, sendEmail);
  yield takeLatest(checkTokenRequest, checkToken);
  yield takeLatest(forgotPasswordRequest, forgotPassword);
  yield takeLatest(resetPasswordRequest, resetPassword);
  yield takeLatest(loginWithGoogleRequest, loginWithGoogle);
  yield takeLatest(loginWithFacebookRequest, loginWithFacebook);
  yield takeLatest(confirmVerificationCodeRequest, confirmVerificationCode);
  yield takeLatest(resendVerificationCodeRequest, resendVerificationCode);
}
