import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';

const AppDatePicker = ({
  selectedDate, onChange, placeholder, required, ...props
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleClickPicker = () => {
    setIsDatePickerOpen(true);
  };

  const pickerValue = required
    ? moment(selectedDate || new Date()).toDate()
    : selectedDate
      ? moment(selectedDate).toDate()
      : null;

  return (
    <KeyboardDatePicker
      open={isDatePickerOpen}
      disableToolbar={!!required}
      variant="inline"
      margin="dense"
      InputProps={{ style: { pointerEvents: 'none' } }}
      onClick={handleClickPicker}
      onClose={() => setIsDatePickerOpen(false)}
      style={{ cursor: 'pointer' }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      keyboardIcon={<CalendarTodayIcon fontSize="small" />}
      placeholder={!required ? placeholder : ''}
      {...props}
      format="MM/dd/yyyy"
      value={pickerValue}
      onChange={(date) => {
        onChange(date ? moment(date).format('YYYY-MM-DD') : null);
        setIsDatePickerOpen(false);
      }}
    />
  );
};

AppDatePicker.propTypes = {
  selectedDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disablePast: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

AppDatePicker.defaultProps = {
  selectedDate: null,
  disablePast: false,
  placeholder: '',
  required: true,
};

export default AppDatePicker;
