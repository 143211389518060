const resources = {
  ru: {
    translation: {
      Accept: 'Принять',
      Accepted: 'Принято',
      AccountCreated: 'Аккаунт создан.',
      Actions: 'Действия',
      Add: 'Добавить',
      AddAppointment: 'Добавить запись',
      AddClosedDays: 'Добавить выходные дни',
      AddCompany: 'Добавить компанию',
      AddCustomer: 'Добавить клиента',
      AddDayOff: 'Добавить дни отдыха',
      AddNewCustomer: 'Добавить нового клиента',
      Address: 'Адрес',
      AddService: 'Добавить услугу',
      AddShortDescOptional: 'Добавить краткое описание (опционально)',
      AddSpecificWorkDays: 'Добавить особые рабочие дни',
      AddStaff: 'Добавить сотрудника',
      All: 'Все',
      AllAppointmentsCancelText: 'Все предстоящие записи будут отменены.',
      AlreadyHaveAccount: 'Уже есть аккаунт?',
      AMD: 'AMD',
      anIndividual: 'Частное лицо',
      Apply: 'Применить',
      ApplyToAll: 'Применить ко всем',
      'Appointment Count': 'Количество записей',
      AppointmentAccepted: 'Запись успешно принята',
      AppointmentCanceled: 'Запись успешно отменена',
      AppointmentCancelModalText: 'Вы уверены, что хотите отменить запись?',
      AppointmentCreated: 'Запись успешно создана',
      AppointmentDeleted: 'Запись успешно удалена',
      AppointmentDeleteModalText: 'Запись исчезнет из календаря и не будет учитываться в статистике.',
      AppointmentDeleteQuestion: 'Вы уверены, что хотите удалить запись?',
      AppointmentDetails: 'Детали записи',
      AppointmentHistory: 'История записей',
      AppointmentNotes: 'Не видно клиентам',
      AppointmentNotFiltered: 'Запись не отфильтрована',
      AppointmentNotFound: 'Запись не найдена.',
      AppointmentRescheduled: 'Запись успешно перенесена',
      Appointments: 'Записи',
      AppointmentSaveDraft: 'Запись сохранена как черновик',
      AppointmentsHistory: 'История записей',
      AreYouSureCancel: 'Вы уверены, что хотите отменить',
      AreYouSureDelete: 'Вы уверены, что хотите удалить',
      'Assigned Services': 'Назначенные услуги',
      AtLeastOne: 'Как минимум один ',
      AutoAccept: 'Автоматически принимать записи',
      AutoAcceptLabel: 'Авто-принятие',
      AutoAcceptTooltipText: 'Записи из онлайн-бронирования будут автоматически приняты.',
      AutoFinishLabel: 'Авто-завершение',
      AutoFinish: 'Автоматически отмечать подтвержденные записи как завершенные после времени окончания',
      AvailableHours: 'Доступные слоты',
      BookingLink: 'Ссылка для записи',
      BookingUrl: 'URL бронирования',
      BreakEndTimeAfter: 'Время окончания перерыва должно быть после начала.',
      BreakEndTimeRequired: 'Требуется время окончания перерыва.',
      Breaking: 'Перерыв',
      BreakStartTimeRequired: 'Требуется время начала перерыва.',
      BufferTime: 'Буферное время',
      BufferTimeTooltipText: 'Добавляет дополнительное время после услуги, не показываемое пользователю.',
      BusinessNotSaved: 'Данные компании не сохранены.',
      BusinessSaved: 'Данные компании сохранены.',
      businessInfo: 'Информация о компании',
      businessName: 'Название компании',
      ByDate: 'По дате',
      ByStatus: 'По статусу',
      Calendar: 'Календарь',
      Category: 'Категория',
      CategoryIsRequired: 'Категория обязательна',
      CalendarTimeSlot: 'Временной диапазон',
      Cancel: 'Отмена',
      CancelAllUpcomingAppointments: 'Отменить все предстоящие записи',
      CancelAppointment: 'Отменить запись',
      CancelInvitation: 'Отменить приглашение',
      Cancelled: 'Отменено',
      CantInviteYourSelf: 'Нельзя пригласить себя как клиента',
      Categories: 'Категории',
      Characters: 'символов',
      ChooseCustomer: 'Выберите клиента',
      ChooseHour: 'Выберите час',
      ChooseMinutes: 'Выберите минуты',
      chooseOrCreateIndustry: 'Выберите или добавьте отрасль',
      ChooseOrCreateService: 'Выбрать или создать услугу',
      CreateService: 'Создать услугу',
      ChooseOrCreateServiceCategory: 'Выбрать или создать категорию услуги',
      ChooseServiceCategory: 'Выберите категорию услуги',
      ChooseService: 'Выберите услугу',
      ChooseServiceProvider: 'Выберите поставщика услуг',
      ChooseStaff: 'Выберите сотрудника',
      City: 'Город',
      Close: 'Закрыто',
      ClosedDayAdded: 'Выходные дни успешно добавлены.',
      ClosedDays: 'Выходные дни',
      ClosedDayUpdated: 'Выходные дни успешно обновлены.',
      Code: 'Код страны',
      Companies: 'Компании',
      Company: 'Компания',
      CompanyDeleted: 'Компания успешно удалена',
      CompanyEmail: 'Введите email компании',
      CompanyName: 'Название компании',
      CompanyOverview: 'Напишите краткое описание вашей компании',
      Confirm: 'Подтвердить',
      ConfirmActionMassagesDelete: 'Вы уверены, что хотите удалить эту компанию?',
      ConfirmActionMassagesDeleteError: 'Нельзя удалить компанию с предстоящими записями.',
      ConfirmActionMassagesLeave: 'Вы уверены, что хотите покинуть компанию?',
      ConfirmCancel: 'Подтвердить отмену',
      ConfirmDelete: 'Подтвердить удаление',
      ConfirmDeletion: 'Подтвердить удаление',
      ConfirmPassword: 'Подтвердите пароль',
      continueDeleting: 'Продолжить удаление?',
      continueWithFacebook: 'Продолжить через Facebook',
      Country: 'Страна',
      CodeInvalidError: 'Код должен состоять из 6 цифр.',
      Create: 'Создать',
      CreateAppointment: 'Создать запись',
      CurrentPassword: 'Текущий пароль',
      Customer: 'Клиент',
      'Customer Name': 'Имя клиента',
      CustomerAdded: 'Клиент добавлен',
      CustomerDeleted: 'Клиент успешно удален',
      CustomerDeleteText: 'Все записи с этим клиентом будут отменены.',
      CustomerDetails: 'Детали клиента',
      CustomerPreview: 'Просмотр клиента',
      Customers: 'Клиенты',
      CustomerUpdated: 'Клиент успешно обновлен',
      companyImageDesc:
        'Как компания вы можете добавлять сотрудников, назначать услуги и создавать записи.',
      Daily: 'Ежедневно',
      Date: 'Дата',
      DateAndTime: 'Дата и время',
      DatePicker: 'Выбор даты',
      DateTime: 'Дата/Время',
      DayAdded: 'Дни отдыха успешно добавлены',
      DayOfBirth: 'Дата рождения',
      DayOffDeleted: 'Дни отдыха успешно удалены.',
      DaysOff: 'Дни отдыха',
      DayUpdated: 'Дни отдыха успешно обновлены.',
      Decline: 'Отклонить',
      DefaultView: 'Вид календаря по умолчанию',
      Delete: 'Удалить',
      DeleteCategoryText: 'Удаление категории приведет к удалению всех связанных услуг.',
      deleteMultipleStaffText: 'Все записи с этими сотрудниками будут отменены.',
      Description: 'Описание',
      Details: 'Детали',
      Digit: 'цифра',
      DontGetCode: 'Не получили код?',
      DontHaveAnAccount: 'Нет аккаунта?',
      DownloadAndSignUp: 'Скачайте приложение и зарегистрируйтесь!',
      Duration: 'Продолжительность',
      Edit: 'Редактировать',
      EditAppointment: 'Редактировать запись',
      EditCategory: 'Редактировать категорию',
      EditCustomer: 'Редактировать клиента',
      EditService: 'Редактировать услугу',
      EditSpecificWorkDays: 'Редактировать особые рабочие дни',
      EditStaff: 'Редактировать сотрудника',
      Email: 'Email',
      EmailOptional: 'Email (опционально)',
      EmailRequired: 'Email обязателен.',
      EmailResent: 'Email успешно переотправлен.',
      EmailValid: 'Введите корректный email.',
      EndDate: 'Дата окончания',
      EndDateRequired: 'Дата окончания обязательна.',
      EndTime: 'Время окончания',
      EndTimeAfter: 'Время окончания должно быть после начала.',
      EnterCodeText: 'Введите 6-значный код, отправленный на ваш',
      EnterCustomerEmail: 'Введите email клиента',
      EnterCustomerFirstName: 'Введите имя клиента',
      EnterCustomerLastName: 'Введите фамилию клиента',
      EnterCustomerPhoneNumber: 'Введите телефон клиента',
      EnterSignInDetailsText: 'Введите данные для входа.',
      EnterStaffFirstName: 'Введите имя сотрудника',
      EnterStaffLastName: 'Введите фамилию сотрудника',
      EnterStaffPhoneNumber: 'Введите телефон сотрудника',
      EnterStaffProfession: 'Введите профессию сотрудника',
      Facebook: 'URL страницы Facebook',
      FasterAndMoreOrganizedText: 'Сделаем процесс записи быстрее и удобнее.',
      Female: 'Женский',
      FileNotSupported: 'Формат файла не поддерживается',
      Filter: 'Фильтр',
      FinalPriceText: 'Укажите итоговую стоимость услуги.',
      Finish: 'Завершить',
      FinishAppointment: 'Завершить запись',
      Finished: 'Завершено',
      'First Visit': 'Первый визит',
      FirstName: 'Имя',
      FirstNameOrLastNameRequired: 'Требуется имя или фамилия',
      Fixed: 'Фиксированная',
      FollowUp: 'Последующее',
      ForgotPassword1: 'Забыли пароль?',
      ForgotPassword2: 'Восстановить пароль',
      Free: 'Бесплатно',
      Fri: 'Пт',
      Friday: 'Пятница',
      From: 'От',
      Gallery: 'Галерея',
      Gender: 'Пол',
      General: 'Общая информация',
      GeneralSchedule: {
        BreakHours: 'Перерывы',
        Title: 'Общий график',
        WeekDays: 'Рабочие дни',
        WorkingHours: 'Рабочие часы',
      },
      H: 'ч',
      haveOneStaff: 'Требуется как минимум 1 сотрудник.',
      Hour: 'Час',
      ImageDeleted: 'Изображение успешно удалено',
      IndustriesNotSaved: 'Данные отрасли не сохранены.',
      Industry: 'Отрасль',
      IndustryDeleted: 'Отрасль успешно удалена.',
      IndustryNotFound: 'Отрасль не найдена.',
      IndustryUpdated: 'Отрасль успешно обновлена.',
      Info: 'Информация',
      InfoGeneral: 'Общая информация',
      InfoLinks: 'Ссылки',
      InfoLocation: 'Местоположение',
      InfoTitle: 'Информация',
      Instagram: 'Аккаунт Instagram',
      InvalidData: 'Некорректные данные.',
      InvalidLoginOrPass: 'Неверный логин или пароль.',
      Invitation: 'Приглашение',
      InvitationAccepted: 'Приглашение принято',
      InvitationDeclined: 'Приглашение отклонено.',
      InvitationDeleted: 'Приглашение удалено',
      InvitationSent: 'Приглашение отправлено.',
      InvitationUpdated: 'Приглашение обновлено',
      Invite: 'Пригласить',
      InviteCustomer: 'Пригласить зарегистрироваться',
      InviteCustomerDescription: 'Пригласите <0>{{name}}</0> зарегистрироваться в SetTime.',
      Invited: 'Приглашен',
      InviteToRegister: 'Пригласить зарегистрироваться',
      IsNotValid: ' недействителен',
      IsRequired: ' обязательно',
      IsTooLong: ' слишком длинный',
      individualImageDesc:
        'Как частное лицо вы можете предоставлять услуги и создавать записи для клиентов.',
      Language: 'Язык',
      'Last Visit': 'Последний визит',
      LastName: 'Фамилия',
      LastNameOptional: 'Фамилия (опционально)',
      Leave: 'Покинуть',
      LeaveCompany: 'Покинуть компанию.',
      LoadDraft: 'Загрузить черновик',
      Loading: 'Загрузка',
      Location: 'Локация',
      Logout: 'Выйти',
      Lowercase: 'нижний регистр',
      lowercasePhoneNumber: 'Номер телефона',
      MaintenanceTime: 'Техническое обслуживание',
      Male: 'Мужской',
      Manager: 'Менеджер',
      Min: 'мин',
      Mon: 'Пн',
      Monday: 'Понедельник',
      Monthly: 'Ежемесячно',
      MyAppointments: 'Мои записи',
      MyCompany: 'Моя компания',
      MyProfile: 'Мой профиль',
      Name: 'Имя',
      New: 'Новое',
      NewAppointment: 'Новая запись',
      NewCategory: 'Новая категория',
      NewCompany: 'Новая компания',
      NewCustomer: 'Новый клиент',
      NewPassMustBeDifferentText: 'Новый пароль должен отличаться от предыдущего',
      NewPassword: 'Новый пароль',
      NewService: 'Новая услуга',
      NewStaff: 'Новый сотрудник',
      Next: 'Далее',
      No: 'Нет',
      NoAppointments: 'Нет записей',
      NoNotifications: 'Нет уведомлений',
      NoOptions: 'Нет опций',
      Note: 'Примечание',
      Notes: 'Примечания к записи',
      Notification: 'Уведомление',
      NotificationCanceled: 'Уведомлять клиентов об отмене',
      NotificationConfirmed: 'Уведомлять клиентов о подтверждении',
      NotificationRescheduled: 'Уведомлять клиентов о переносе',
      NotRegistered: 'Не зарегистрирован',
      Off: 'Выкл',
      On: 'Вкл',
      Open: 'Открыто',
      Order: 'Порядок записи',
      Password: 'Пароль',
      PasswordRequired: 'Пароль обязателен.',
      PasswordSettings: 'Сменить пароль',
      PasswordMinLength: 'Минимум 8 символов',
      PasswordLowercase: 'Содержит строчную букву.',
      PasswordUppercase: 'Содержит заглавную букву.',
      PasswordSpecial: 'Содержит цифру или спецсимвол.',
      PasswordLengthRule: 'Пароль должен содержать минимум 8 символов',
      PasswordCapitalRule: 'Пароль должен содержать минимум 1 заглавную букву',
      PasswordMismatch: 'Пароли не совпадают',
      PastDate: 'Разрешить создание записей в прошлом',
      Pending: 'В ожидании',
      Canceled: 'Отменено',
      Request: 'По запросу',
      NoShow: 'Неявка',
      Permalink: 'Постоянная ссылка',
      PersonalInformation: 'Личные данные',
      Phone: 'Телефон',
      PhoneCode: 'Код страны',
      PhoneNumber: 'Номер телефона',
      PhoneNumberOptional: 'Номер телефона (опционально)',
      Position: 'Должность',
      Price: 'Цена',
      PriceType: 'Тип цены',
      Pricing: 'Цены',
      Profession: 'Профессия',
      ProfessionOptional: 'Профессия (опционально)',
      Range: 'Диапазон',
      ReadAll: 'Прочитать все',
      Registered: 'Зарегистрирован',
      RemindCustomer: 'Напомнить клиенту',
      Reminder: 'Напоминания',
      Resend: 'Повторно отправить',
      ResendCode: 'Повторить код',
      ResetPassword: 'Сброс пароля',
      RowsPerPage: 'Строк на странице',
      Sat: 'Сб',
      Saturday: 'Суббота',
      Save: 'Сохранить',
      SaveDraft: 'Сохранить черновик',
      SaveDraftText: 'Сохранить эту запись как черновик?',
      SearchByCustomerOrStaff: 'Поиск по клиенту или сотруднику',
      SearchCustomer: 'Поиск по имени клиента',
      SearchService: 'Поиск по названию услуги',
      SearchStaff: 'Поиск по имени сотрудника',
      SelectedDayNotWork: 'Выбранный день не рабочий',
      Send: 'Отправить',
      Service: 'Услуга',
      'Service Name': 'Название услуги',
      ServiceBased: 'Основано на услуге',
      ServiceCategory: 'Категория услуги',
      ServiceDeleted: 'Услуга успешно удалена.',
      ServiceDeletionText: 'Услуга станет недоступной для бронирования, все связанные записи будут отменены.',
      ServiceDuration: 'Продолжительность услуги',
      ServiceNotFound: 'Услуга не найдена.',
      ServiceProvider: 'поставщик услуг',
      Services: 'Услуги',
      Settings: 'Настройки',
      ShortPassword: 'Слишком короткий пароль.',
      ShowLess: 'Скрыть',
      ShowMore: 'Показать больше',
      ShowOnWidget: 'Показывать в виджете',
      ShowOnWidgetTooltipText: 'Услуга будет доступна для онлайн-записи.',
      SignIn: 'Войти',
      SlotDuration: 'Длительность слота',
      SomethingWrong: 'Что-то пошло не так.',
      Source: 'Источник',
      SpecificDayAdded: 'Особые рабочие дни добавлены.',
      SpecificDayDeleted: 'Особые рабочие дни удалены.',
      SpecificDaysTitle: 'Особые рабочие дни',
      SpecificDayUpdated: 'Особые рабочие дни обновлены.',
      SpecificWorkDayDeleted: 'Особые рабочие дни удалены.',
      Staff: 'Сотрудники',
      'Staff Name': 'Имя сотрудника',
      StaffBased: 'Основано на сотруднике',
      StaffDetails: 'Детали сотрудника',
      StaffNotFind: 'Сотрудник не найден!',
      StaffPreview: 'Просмотр сотрудника',
      Staffs: 'Сотрудники',
      StartDate: 'Дата начала',
      StartTime: 'Время начала',
      StartTimeEarlier: 'Время начала должно быть раньше окончания.',
      StartTimeRequired: 'Время начала обязательно.',
      State: 'Штат/Область',
      Statistics: 'Статистика',
      Status: 'Статус',
      Sun: 'Вс',
      Sunday: 'Воскресенье',
      signUp: 'Зарегистрироваться',
      signUpWith: 'Или зарегистрироваться через',
      signUpWithEmail: 'Регистрация по email',
      TableHeader: {
        Actions: 'Действия',
        BreakTime: 'Перерыв',
        Date: 'Даты',
        Description: 'Описание',
        Staff: 'Сотрудник',
        Time: 'Рабочие часы',
      },
      ThankYou: 'Отправить благодарственное сообщение',
      ThisMonth: 'Этот месяц',
      ThisServiceHas: 'У этой услуги ',
      ThisWeek: 'Эта неделя',
      Thu: 'Чт',
      Thursday: 'Четверг',
      Time: 'Время',
      TimeZone: 'Часовой пояс',
      Tin: 'ИНН',
      Title: 'Название',
      To: 'До',
      Today: 'Сегодня',
      Tomorrow: 'Завтра',
      Total: 'Всего',
      TotalPayments: 'Доход',
      Tue: 'Вт',
      Tuesday: 'Вторник',
      Upcoming: 'Предстоящие',
      UpcomingAppointments: 'Предстоящие записи',
      UpcomingAppointmentServiceModalText: ' предстоящих записей.',
      upcomingAppointmentWith: 'Предстоящие записи с',
      Update: 'Обновить',
      UpdatedSuccess: 'Успешно обновлено.',
      Uppercase: 'ВЕРХНИЙ РЕГИСТР',
      UserNotBreak: 'Пользователь в перерыве в это время',
      UserNotWork: 'Пользователь не работает в это время',
      ValidAppointment: 'Корректные поля записи',
      VerificationCode: 'Проверочный код',
      VerifyCode: 'Подтвердить код',
      Visits: 'Визиты',
      WalkIn: 'Без записи',
      WalkInDesc: 'Выберите, если хотите пропустить выбор клиента.',
      WebSite: 'URL сайта компании',
      Wed: 'Ср',
      Wednesday: 'Среда',
      Weekly: 'Еженедельно',
      WeWillSendVerificationCode: 'Мы отправим 6-значный проверочный код.',
      EnterVerificationCode: 'Введите 6-значный код, отправленный на ваш ',
      willBeCancelled: ' будет отменено.',
      WithDrawInvitation: 'Отменить приглашение',
      'Work Schedule': 'Рабочий график',
      WorkDateEndTimeRequired: 'Требуется время окончания рабочего дня.',
      WorkDateStartTimeRequired: 'Требуется время начала рабочего дня.',
      WorkdayEnd: 'Конец рабочего дня',
      WorkdayStart: 'Начало рабочего дня',
      WorkingScheduleTab: {
        Company: 'Компания',
        General: 'Общий',
        Staff: 'Сотрудник',
      },
      WorkSchedule: 'Рабочий график',
      whatIsTypeBusiness: 'Выберите тип бизнеса',
      Yes: 'Да',
      ZipCode: 'Почтовый индекс',
      timeOff: 'Время отдыха',
      editTimeOff: 'Редактировать время отдыха',
      newTimeOff: 'Новое время отдыха',
      timeOffDescription: 'Заблокируйте определенные периоды для предотвращения записи.',
      startTimeRequired: 'Требуется время начала',
      startTimeInvalid: 'Время начала должно быть раньше окончания.',
      endTimeRequired: 'Требуется время окончания',
      endTimeInvalid: 'Время окончания должно быть позже начала',
      timeOffCreatedSuccess: 'Время отдыха создано успешно.',
      timeOffCreatedFailure: 'Ошибка создания времени отдыха',
      timeOffUpdatedSuccess: 'Время отдыха обновлено успешно.',
      timeOffUpdatedFailure: 'Ошибка обновления времени отдыха',
      titleOptional: 'Название (опционально)',
      TimeOffDeleteModalText: 'Удалить время отдыха?',
      TimeOffDeleted: 'Время отдыха удалено успешно',
    },
  },
};

export default resources;
