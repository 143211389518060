import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/notifications.scss';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Typography,
  Button,
  Divider,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import finished from 'assets/notification/badges/finished.svg';
import upcoming from 'assets/notification/badges/upcoming.svg';
import booked from 'assets/notification/badges/booked.svg';
import canceled from 'assets/notification/badges/canceled.svg';
import pending from 'assets/notification/badges/pending.svg';
import deleteIcon from 'assets/icons/delete.svg';
import defaultAvatar from 'assets/notification/default-avatar.svg';
import bookedOnline from 'assets/notification/booked-online.svg';
import dailyReminder from 'assets/notification/daily-reminder.svg';
import RenderActivityButtons from '../ActivityButtonsContent/activityButtonsContent';
import getNotificationTimeDifference from '../GetNotificationTimeDifference/getNotificationTimeDifference';
import Loading from '../../Loading/loading';

const useStyles = makeStyles(() => ({
  avatarImg: {
    width: 50,
    height: 50,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  imgAvatar: {
    width: '100%',
    height: '100%',
  },
  actionIcon: {
    width: 20,
    height: 20,
    bottom: '-5%',
  },
}));

function TopBarNotificationItemContent(props) {
  const {
    notifications,
    redirectNotification,
    handleAcceptAppointment,
    handleCancelAppointment,
    handleAcceptCustomerInvitation,
    handleDeclineCustomerInvitation,
    handleAcceptIndustryCustomerInvitation,
    handleDeclineIndustryCustomerInvitation,
    handleAcceptStaffInvitation,
    handleDeclineStaffInvitation,
    handleDelete,
    handleOpenMenu,
    anchorEl,
    setAnchorEl,
    loading,
  } = props;

  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const getBadges = (notification) => {
    switch (notification.action.type_message) {
      case 'CreatedFromWidget':
        if (notification.appointment.status_id === 1) {
          return <img src={booked} alt="booked" />;
        } else if (notification.appointment.status_id === 2) {
          return <img src={pending} alt="pending" />;
        }
        break;
      case 'Created':
        if (notification.appointment.status_id === 1) {
          return <img src={upcoming} alt="upcoming" />;
        }
        break;
      case 'Finished':
        return <img src={finished} alt="finished" />;
      case 'Canceled':
        return <img src={canceled} alt="canceled" />;
      case 'Declined':
        return <img src={canceled} alt="canceled" />;
    }
  };

  const getAvatar = (notification) => {
    if (notification.action.type_message === 'DailyReminder') {
      return dailyReminder;
    } else if (notification.action.type_message === 'BookingReminder') {
      return bookedOnline;
    } else if (notification.appointment && notification.appointment.staff_with_trashed.avatar) {
      return notification.appointment.staff_with_trashed.avatar;
    } else {
      return defaultAvatar;
    }
  };

  return (
    <>
      {!loading ? (
        notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <Box
              key={`notificationItem${notification.id}`}
              className={`notification-item ${!notification.read_at && 'unread'}`}
              onClick={(event) => redirectNotification(event, notification)}
            >
              <Box className="content">
                <Box className="item-box">
                  <Box className="seen-avatar-box">
                    {!notification.read_at && (
                      <Box height="100%" display="flex" alignItems="center">
                        <Box display="block" className="unread-notification" />
                      </Box>
                    )}
                    <Box>
                      {notification && notification.user
                        ? notification.user.active_role_id === 2
                          ? (
                            <Badge
                              invisible={notification.action.type_message === 'DailyReminder' || notification.action.type_message === 'BookingReminder'}
                              className={classes.avatarImg}
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              badgeContent={(
                                <Avatar className={classes.actionIcon}>
                                  {getBadges(notification)}
                                </Avatar>
                                            )}
                            >
                              <Avatar alt="avatar" className={classes.imgAvatar} src={getAvatar(notification)} />
                            </Badge>
                          )
                          : (
                            <Badge
                              className={classes.avatarImg}
                              invisible={notification.action.type_message === 'DailyReminder' || notification.action.type_message === 'BookingReminder'}
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              badgeContent={(
                                <Avatar className={classes.actionIcon}>
                                  {getBadges(notification)}
                                </Avatar>
                                            )}
                            >
                              <Avatar alt="avatar" className={classes.imgAvatar} src={getAvatar(notification)} />
                            </Badge>
                          )
                        : ''}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                      <p className="notification-title">
                        {notification.title}
                      </p>
                      <p className="notification-accepted">
                        {notification.message}
                      </p>
                      <div className="accepted-time">
                        {getNotificationTimeDifference(notification.created_at, notification.company.owner.timezone, i18n.language)}
                      </div>
                    </Box>
                    <Box className="notification-option-menu">
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleOpenMenu(e, notification.id)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu
                        disablePortal
                        anchorEl={anchorEl}
                        elevation={0}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: '48px',
                            width: '108px',
                            opacity: 1,
                            visibility: 'visible',
                          },
                        }}
                      >
                        <MenuItem onClick={() => handleDelete(notification.id)}>
                          <img src={deleteIcon} alt="delete" />
                          <span className="menu-delete-btn">
                            {i18n.t('Delete')}
                          </span>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
                {notification.action.type_message === 'CreatedFromWidget' && notification.appointment.status_id === 2 ? (
                  <RenderActivityButtons
                    notification={notification}
                    handleAcceptAppointment={handleAcceptAppointment}
                    handleCancelAppointment={handleCancelAppointment}
                    handleAcceptCustomerInvitation={handleAcceptCustomerInvitation}
                    handleDeclineCustomerInvitation={handleDeclineCustomerInvitation}
                    handleAcceptIndustryCustomerInvitation={handleAcceptIndustryCustomerInvitation}
                    handleDeclineIndustryCustomerInvitation={handleDeclineIndustryCustomerInvitation}
                    handleAcceptStaffInvitation={handleAcceptStaffInvitation}
                    handleDeclineStaffInvitation={handleDeclineStaffInvitation}
                  />
                ) : null}
                {notification.action.name === 'staff.invitation.accepted' && (
                <Box display="flex" justifyContent="flex-end" mb="6px" px="16px">
                  <Link to={notification.link}>
                    <Button
                      className="activity"
                      size="small"
                      variant="contained"
                      color="primary"
                    >
                      <span className="activity">{i18n.t('AddService')}</span>
                    </Button>
                  </Link>
                </Box>
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Box>
            <Box align="center" px="40px" py="30px">
              <Box mb="5px">
                <NotificationsIcon fontSize="large" color="secondary" />
              </Box>
              <Typography variant="body2" color="secondary">
                {i18n.t('NoNotifications')}
              </Typography>
            </Box>
            <Divider />
          </Box>
        )
      ) : <Loading />}
    </>
  );
}

TopBarNotificationItemContent.propTypes = {
  notifications: PropTypes.array.isRequired,
  handleAcceptAppointment: PropTypes.func.isRequired,
  handleCancelAppointment: PropTypes.func.isRequired,
  handleAcceptCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineCustomerInvitation: PropTypes.func.isRequired,
  handleAcceptIndustryCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineIndustryCustomerInvitation: PropTypes.func.isRequired,
  handleAcceptStaffInvitation: PropTypes.func.isRequired,
  handleDeclineStaffInvitation: PropTypes.func.isRequired,
  redirectNotification: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.object]),
  setAnchorEl: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TopBarNotificationItemContent;
