import { put, takeLatest } from 'redux-saga/effects';
import {
  getIndustriesRequest,
  getIndustriesSuccess,
  getIndustriesError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getIndustries(action) {
  try {
    const { payload } = action;
    let url = 'industries/customer?page=1&q=&limit=50&mobile=true';
    if (payload && payload.filterBy) {
      url = `${url}&filterBy=${payload.filterBy}`;
    }
    const res = yield axiosInstance.get(url);
    if (res && res.status === 200) {
      yield put(getIndustriesSuccess(res.data));
    }
  } catch (e) {
    yield put(getIndustriesError());
  }
}

export default function* () {
  yield takeLatest(getIndustriesRequest, getIndustries);
}
