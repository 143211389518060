import React, { useState } from 'react';
import {
  Avatar, Button, List, ListItem, ListItemIcon, ListItemText, Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import getInitials from 'Modules/getInitials';

import editIcon from 'assets/icons/edit.svg';
import editDisabledIcon from 'assets/icons/edit-disabled.svg';
import deleteIcon from 'assets/icons/delete.svg';
import DefaultProfileImage from 'assets/img/default-profile.png';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getCustomerAppointmentsHistoryRequest } from 'redux/customer/actions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import AppointmentsDrawer from '../../MyAppointments/Drawer';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    '&$selected': {
      backgroundColor: '#F2F9FD !important',
      borderRadius: '4px',
    },
  },
  icon: {
    minWidth: '30px',
  },
  selected: {},
}));

const CustomizedTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#E8E6E6',
    color: '#000000',
    fontSize: 11,
    fontWeight: 400,
  },
}))(Tooltip);

const LeftSide = (props) => {
  const {
    selectedCustomer, isUpdate, setIsUpdate, selectedIndex, setSelectedIndex, deleteCustomer, setLoading,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = useStyles();
  const [isRequest, setIsRequest] = useState(true);
  const [isShowAppointmentDrawer, setIsShowAppointmentDrawer] = useState(false);
  const [appointment, setAppointment] = useState({});
  // Todo check if possible do not use these items
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [appointments, setAppointments] = useState([]);

  const handleListItemClick = (event, index, customerId) => {
    setSelectedIndex(index);
    if (index === 0) {
      if (isRequest) {
        dispatch(getCustomerAppointmentsHistoryRequest({ id: customerId, page: 1 }));
        setLoading(true);
        setIsRequest(false);
      }
    }
    setIsUpdate(false);
  };

  const renderAvatar = () => {
    const { avatar, first_name, last_name } = selectedCustomer;
    if (avatar) {
      return (
        <Avatar alt="Remy Sharp" src={avatar} style={{ width: 100, height: 100 }} />
      );
    } else {
      return (
        <div className="no-avatar-circle">
          {first_name ? (
            /^[a-zA-Z]*$/.test(first_name[0]) ? (
              getInitials(first_name)
            ) : last_name ? (
              /^[a-zA-Z]*$/.test(last_name[0]) ? (
                getInitials(last_name)
              ) : (
                <img src={DefaultProfileImage} alt="default" width={100} height={100} />
              )
            ) : (
              <img src={DefaultProfileImage} alt="default" width={100} height={100} />
            )
          ) : (
            <img src={DefaultProfileImage} alt="default" width={100} height={100} />
          )}
        </div>
      );
    }
  };

  const onEdit = () => {
    setIsUpdate(!isUpdate);
    setSelectedIndex(null);
  };

  const handleOpenCreateAppointmentModal = () => {
    setAppointment({
      ...appointment,
      customer_id: selectedCustomer.id,
    });
    setIsShowAppointmentDrawer(true);
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <div className="left-container">
      <div>
        <div className="edit-btn">
          <Button onClick={onEdit} disabled={isUpdate}>
            {isUpdate ? (
              <img src={editDisabledIcon} alt="edit" />
            ) : (
              <img src={editIcon} alt="edit" />
            )}
            <span style={{ color: isUpdate ? '#D3D3D3' : '#393939' }}>
              {t('Edit')}
            </span>
          </Button>
        </div>
        <div className="detail-section">
          <div className="avatar-section">
            <div className="avatar">
              {renderAvatar()}
            </div>
            <p className="customer-name">
              {selectedCustomer.full_name}
            </p>
          </div>
          <div className="customer-details">
            {selectedCustomer.email && (
              <div className="mail-detail">
                <MailOutlineIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
                <CustomizedTooltip title={selectedCustomer.email} placement="top-start" interactive>
                  <span className="mail-text">
                    {selectedCustomer.email}
                  </span>
                </CustomizedTooltip>
              </div>
            )}
            {selectedCustomer.phone_number && (
              <div className="phone-detail">
                <PhoneOutlinedIcon style={{ fill: '#393939', fontSize: '19px' }} />
                <span className="phone-text">
                  {selectedCustomer.phone_number}
                </span>
              </div>
            )}
            {selectedCustomer.address && (
              <div className="address-detail">
                <h4>{ t('Address') }</h4>
                <span className="address-text">
                  {selectedCustomer.address}
                </span>
              </div>
            )}
            {selectedCustomer.birthday && (
              <div className="birthday-detail">
                <h4>{ t('BirthDay') }</h4>
                <span className="birthday-text">
                  {moment(selectedCustomer.birthday).format('DD/MM/YY')}
                </span>
              </div>
            )}
            {selectedCustomer.note && (
              <div className="note-detail">
                <h4>{ t('Note') }</h4>
                <span className="note-text">
                  {selectedCustomer.note}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="divider-border" />
        <div className="detail-items">
          <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0, selectedCustomer.id)}
              >
                <ListItemIcon className={classes.icon}>
                  <HistoryOutlinedIcon style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary={t('AppointmentsHistory')} />
              </ListItem>
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1, selectedCustomer.id)}
              >
                <ListItemIcon className={classes.icon}>
                  <DateRangeOutlinedIcon style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary={t('UpcomingAppointments')} />
              </ListItem>
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={selectedIndex === 2}
                onClick={() => handleOpenCreateAppointmentModal()}
              >
                <ListItemIcon className={classes.icon}>
                  <AddIcon style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary={t('NewAppointment')} />
              </ListItem>
              {/* <ListItem */}
              {/*  classes={{ root: classes.root, selected: classes.selected }} */}
              {/*  button */}
              {/*  selected={selectedIndex === 2} */}
              {/*  onClick={(event) => handleListItemClick(event, 2)} */}
              {/* > */}
              {/*  <ListItemIcon className={classes.icon}> */}
              {/*    <PermContactCalendarOutlinedIcon style={{ fontSize: '18px' }} /> */}
              {/*  </ListItemIcon> */}
              {/*  <ListItemText primary="Visits Overview" /> */}
              {/* </ListItem> */}
            </List>
          </div>
        </div>
        {/* <div style={{ borderBottom: '1px solid #E3E5E6', margin: '8px 24px 10px 24px' }} /> */}
        {/* <div className="new-appointment"> */}
        {/*  <Button> */}
        {/*    <AddIcon style={{ fill: '#0282DA', fontSize: '18px' }} /> */}
        {/*    <span className="new-appointment-text"> */}
        {/*      New Appointment */}
        {/*    </span> */}
        {/*  </Button> */}
        {/* </div> */}
      </div>
      {isShowAppointmentDrawer && (
        <AppointmentsDrawer
          setSelectedAppointmentId={setSelectedAppointmentId}
          isOpen={isShowAppointmentDrawer}
          onClose={() => setIsShowAppointmentDrawer(false)}
          staffId={null}
          snackBarAlert={snackBarAlert}
          setAppointments={setAppointments}
          appointments={appointments}
          startTime=""
          date=""
          appointment={appointment}
        />
      )}
      <div className="delete-btn-section">
        <Button onClick={(e) => deleteCustomer(e, selectedCustomer.id)}>
          <img src={deleteIcon} alt="delete" />
          <span className="delete-btn-text">
            {t('Delete')}
          </span>
        </Button>
      </div>
    </div>
  );
};

LeftSide.propTypes = {
  selectedCustomer: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  setIsUpdate: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default LeftSide;
