const resources = {
  ar: {
    translation: {
      Accept: 'قبول',
      Accepted: 'تم القبول',
      AccountCreated: 'تم إنشاء الحساب.',
      Actions: 'الخيارات',
      Add: 'إضافة',
      AddAppointment: 'إضافة موعد',
      AddClosedDays: 'إضافة أيام مغلقة',
      AddCompany: 'إضافة شركة',
      AddCustomer: 'إضافة عميل',
      AddDayOff: 'إضافة أيام إجازة',
      AddNewCustomer: 'إضافة عميل جديد',
      Address: 'عنوان الشارع',
      AddressOptional: 'عنوان (اختياري)',
      AddService: 'إضافة خدمة',
      AddShortDescOptional: 'إضافة وصف مختصر (اختياري)',
      AddSpecificWorkDays: 'إضافة أيام عمل محددة',
      AddStaff: 'إضافة موظف',
      All: 'الكل',
      AllAppointmentsCancelText: 'سيتم إلغاء جميع المواعيد القادمة.',
      AlreadyHaveAccount: 'هل لديك حساب بالفعل',
      AMD: 'درهم',
      anIndividual: 'شخص فردي',
      Apply: 'تطبيق',
      ApplyToAll: 'تطبيق على الكل',
      'Appointment Count': 'عدد المواعيد',
      AppointmentAccepted: 'تم قبول الموعد بنجاح',
      AppointmentCanceled: 'تم إلغاء الموعد بنجاح',
      AppointmentCancelModalText: 'هل أنت متأكد أنك تريد إلغاء موعدك؟',
      AppointmentCreated: 'تم إنشاء الموعد بنجاح',
      AppointmentDeleted: 'تم حذف الموعد بنجاح',
      AppointmentDeleteModalText: 'سيختفي الموعد من التقويم ولن يتم احتسابه في الإحصائيات.',
      AppointmentDeleteQuestion: 'هل أنت متأكد أنك تريد حذف الموعد؟',
      AppointmentDetails: 'تفاصيل الموعد',
      AppointmentHistory: 'تاريخ المواعيد',
      AppointmentNotes: 'غير مرئي للعملاء',
      AppointmentNotFiltered: 'الموعد غير مرشح',
      AppointmentNotFound: 'الموعد غير موجود.',
      AppointmentRescheduled: 'تم إعادة جدولة الموعد بنجاح',
      Appointments: 'المواعيد',
      AppointmentSaveDraft: 'تم حفظ الموعد كمسودة',
      AppointmentsHistory: 'تاريخ المواعيد',
      AreYouSureCancel: 'هل أنت متأكد أنك تريد إلغاء',
      AreYouSureDelete: 'هل أنت متأكد أنك تريد حذف',
      'Assigned Services': 'الخدمات المخصصة',
      AtLeastOne: 'على الأقل واحد ',
      AutoAccept: 'قبول المواعيد تلقائياً',
      AutoAcceptLabel: 'تلقائياً قبول',
      AutoAcceptTooltipText: 'سيتم قبول المواعيد المستلمة من الحجوزات عبر الإنترنت تلقائياً.',
      AutoFinishLabel: 'إنهاء تلقائي',
      AutoFinish: 'وضع علامة تلقائيًا على المواعيد المؤكدة كمكتملة بمجرد انتهاء وقت نهايتها',
      AvailableHours: 'فترات زمنية متاحة',
      BookingLink: 'رابط الحجز',
      BookingUrl: 'رابط الحجز',
      BreakEndTimeAfter: 'يجب أن يكون وقت الانتهاء من الاستراحة بعد بداية الاستراحة.',
      BreakEndTimeRequired: 'وقت نهاية الاستراحة مطلوب.',
      Breaking: 'الاستراحة',
      BreakStartTimeRequired: 'وقت بداية الاستراحة مطلوب.',
      BufferTime: 'وقت التأخير',
      BufferTimeTooltipText: 'إضافة وقت إضافي بعد الخدمة، والذي لا يظهر للمستخدم.',
      BusinessNotSaved: 'لم يتم حفظ تفاصيل العمل.',
      BusinessSaved: 'تم حفظ تفاصيل العمل.',
      ByDate: 'حسب التاريخ',
      ByStatus: 'حسب الحالة',
      businessName: 'اسم العمل',
      Calendar: 'التقويم',
      CalendarTimeSlot: 'نطاق الوقت',
      Cancel: 'إلغاء',
      CancelAllUpcomingAppointments: 'إلغاء جميع المواعيد القادمة',
      CancelAppointment: 'إلغاء الموعد',
      CancelInvitation: 'إلغاء الدعوة',
      Cancelled: 'تم الإلغاء',
      CantInviteYourSelf: 'لا يمكنك دعوة نفسك كعميل',
      Categories: 'الفئات',
      Category: 'الفئة',
      CategoryIsRequired: 'الفئة مطلوبة',
      Characters: 'أحرف',
      ChooseCustomer: 'اختر عميلاً',
      ChooseHour: 'اختر الساعة',
      ChooseMinutes: 'اختر الدقائق',
      chooseOrCreateIndustry: 'اختر أو أضف صناعة',
      ChooseOrCreateService: 'اختر أو أنشئ خدمة',
      ChooseOrCreateServiceCategory: 'اختر أو أنشئ فئة الخدمة',
      ChooseService: 'اختر خدمة',
      ChooseServiceCategory: 'اختر فئة الخدمة',
      ChooseServiceProvider: 'اختر مقدم الخدمة',
      ChooseStaff: 'اختر موظفًا',
      City: 'المدينة',
      Close: 'مغلق',
      ClosedDayAdded: 'تمت إضافة يوم/أيام مغلقة بنجاح.',
      ClosedDays: 'الأيام المغلقة',
      ClosedDayUpdated: 'تم تحديث الأيام المغلقة بنجاح.',
      Code: 'كود الدولة',
      Companies: 'الشركات',
      Company: 'الشركة',
      CompanyDeleted: 'تم حذف الشركة بنجاح',
      CompanyEmail: 'أدخل عنوان البريد الإلكتروني للشركة',
      CompanyName: 'اسم الشركة',
      CompanyOverview: 'اكتب نظرة عامة موجزة عن شركتك',
      Confirm: 'تأكيد',
      ConfirmActionMassagesDelete: 'هل أنت متأكد أنك تريد إزالة هذه الشركة؟',
      ConfirmActionMassagesDeleteError: 'لا يمكنك إزالة هذه الشركة بعد الآن لأنها لديها مواعيد قادمة.',
      ConfirmActionMassagesLeave: 'هل أنت متأكد من رغبتك في مغادرة الشركة؟',
      ConfirmCancel: 'تأكيد الإلغاء',
      ConfirmDelete: 'تأكيد الحذف',
      ConfirmDeletion: 'تأكيد الحذف',
      ConfirmPassword: 'تأكيد كلمة المرور',
      continueDeleting: 'الاستمرار في الحذف؟',
      continueWithFacebook: 'تواصل مع Facebook',
      Country: 'البلد',
      CodeInvalidError: 'يجب أن يكون طول الكود 6.',
      Create: 'إنشاء',
      CreateAppointment: 'إنشاء موعد',
      CreateService: 'إنشاء خدمة',
      CurrentPassword: 'كلمة المرور الحالية',
      Customer: 'العميل',
      'Customer Name': 'اسم العميل',
      CustomerAdded: 'تمت إضافة العميل',
      CustomerDeleted: 'تم حذف العميل بنجاح',
      CustomerDeleteText: 'إذا كان هناك أي موعد مع هؤلاء العملاء، فسيتم إلغاؤه.',
      CustomerDetails: 'تفاصيل العميل',
      CustomerPreview: 'معاينة العميل',
      Customers: 'العملاء',
      CustomerUpdated: 'تم تحديث العميل بنجاح',
      companyImageDesc:
        'باعتبارك شركة، يمكنك إضافة موظفيك، وتعيين الخدمات، وإنشاء المواعيد.',
      Daily: 'يوميًا',
      Date: 'التاريخ',
      DateAndTime: 'التاريخ والوقت',
      DatePicker: 'محدد التاريخ',
      DateTime: 'التاريخ/الوقت',
      DayAdded: 'تمت إضافة يوم/أيام إجازة بنجاح',
      DayOfBirth: 'تاريخ الميلاد',
      DayOfBirthOptional: 'تاريخ الميلاد (اختياري)',
      BirthDay: 'عيد ميلاد',
      DayOffDeleted: 'تم حذف اليوم/الأيام العطلة بنجاح.',
      DaysOff: 'أيام الإجازة',
      DayUpdated: 'تم تحديث اليوم/الأيام العطلة بنجاح.',
      Decline: 'رفض',
      DefaultView: 'عرض التقويم الافتراضي',
      Delete: 'حذف',
      DeleteCategoryText: 'إذا قمت بحذف فئة الخدمة هذه، فسيتم حذف جميع الخدمات التابعة لها أيضًا.',
      deleteMultipleStaffText: 'إذا كان هناك أي موعد مع هؤلاء الموظفين، فسيتم إلغاؤه.',
      Description: 'الوصف',
      Details: 'التفاصيل',
      Digit: 'رقم',
      DontGetCode: 'لم تتلقى الرمز؟',
      DontHaveAnAccount: 'ليس لديك حساب؟',
      DownloadAndSignUp: 'قم بتنزيل التطبيق والتسجيل الآن!',
      Duration: 'المدة',
      Edit: 'تعديل',
      EditAppointment: 'تعديل الموعد',
      EditCategory: 'تعديل الفئة',
      EditCustomer: 'تعديل العميل',
      EditService: 'تعديل الخدمة',
      EditSpecificWorkDays: 'تعديل أيام العمل المحددة',
      EditStaff: 'تعديل الموظف',
      Email: 'البريد الإلكتروني',
      EmailOptional: 'البريد الإلكتروني (اختياري)',
      EmailRequired: 'البريد الإلكتروني مطلوب.',
      EmailResent: 'تم إعادة إرسال البريد الإلكتروني بنجاح.',
      EmailValid: 'الرجاء إدخال عنوان بريد إلكتروني صحيح.',
      EndDate: 'تاريخ الانتهاء',
      EndDateRequired: 'تاريخ الانتهاء مطلوب.',
      EndTime: 'وقت الانتهاء',
      EndTimeAfter: 'يجب أن يكون وقت الانتهاء بعد وقت البدء.',
      EnterCodeText: 'أدخل الرمز المكون من 6 أرقام المرسل إلى',
      EnterCustomerEmail: 'أدخل بريد العميل الإلكتروني',
      EnterCustomerFirstName: 'أدخل اسم العميل الأول',
      EnterCustomerLastName: 'أدخل اسم العميل الأخير',
      EnterCustomerPhoneNumber: 'أدخل رقم هاتف العميل',
      EnterSignInDetailsText: 'الرجاء إدخال تفاصيلك لتسجيل الدخول.',
      EnterStaffFirstName: 'أدخل اسم الموظف الأول',
      EnterStaffLastName: 'أدخل اسم الموظف الأخير',
      EnterStaffPhoneNumber: 'أدخل رقم هاتف الموظف',
      EnterStaffProfession: 'أدخل مهنة الموظف',
      Facebook: 'رابط صفحة الفيسبوك',
      FasterAndMoreOrganizedText: 'لنجعل عملية الحجز أسرع وأكثر تنظيمًا.',
      Female: 'أنثى',
      FileNotSupported: 'الملف غير مدعوم',
      Filter: 'تصفية',
      FinalPriceText: 'يرجى تحديد السعر النهائي لهذه الخدمة.',
      Finish: 'انتهاء',
      FinishAppointment: 'انتهاء الموعد',
      Finished: 'مكتمل',
      'First Visit': 'أول زيارة',
      FirstName: 'الاسم الأول',
      FirstNameOrLastNameRequired: 'الاسم الأول أو الاسم الأخير مطلوب',
      Fixed: 'ثابت',
      FollowUp: 'متابعة',
      ForgotPassword1: 'هل نسيت كلمة المرور؟',
      ForgotPassword2: 'هل نسيت كلمة المرور',
      Free: 'مجاني',
      Fri: 'الجمعة',
      Friday: 'الجمعة',
      From: 'من',
      Gallery: 'المعرض',
      Gender: 'الجنس',
      General: 'معلومات عامة',
      GeneralSchedule: {
        BreakHours: 'وقت الاستراحة',
        Title: 'الجدول الزمني العام',
        WeekDays: 'أيام العمل',
        WorkingHours: 'ساعات العمل',
      },
      H: 'س',
      haveOneStaff: 'يتطلب وجود موظف واحد على الأقل.',
      Hour: 'ساعة',
      ImageDeleted: 'تم حذف الصورة بنجاح',
      IndustriesNotSaved: 'لم يتم حفظ تفاصيل الصناعات.',
      Industry: 'الصناعة',
      IndustryDeleted: 'تم حذف الصناعة بنجاح.',
      IndustryNotFound: 'الصناعة غير موجودة.',
      IndustryUpdated: 'تم تحديث الصناعة بنجاح.',
      Info: 'معلومات',
      InfoGeneral: 'معلومات عامة',
      InfoLinks: 'روابط',
      InfoLocation: 'الموقع',
      InfoTitle: 'معلومات',
      Instagram: 'اسم الحساب على انستجرام',
      InvalidData: 'بيانات غير صالحة.',
      InvalidLoginOrPass: 'اسم المستخدم أو كلمة المرور غير صالحة.',
      Invitation: 'دعوة',
      InvitationAccepted: 'تم قبول الدعوة بنجاح',
      InvitationDeclined: 'تم رفض الدعوة.',
      InvitationDeleted: 'تم حذف الدعوة بنجاح',
      InvitationSent: 'تم إرسال الدعوة.',
      InvitationUpdated: 'تم تحديث الدعوة بنجاح',
      Invite: 'دعوة',
      InviteCustomer: 'دعوة للتسجيل',
      InviteCustomerDescription: 'ادعو <0>{{name}}</0> للتسجيل في SetTime.',
      Invited: 'تمت الدعوة',
      InviteToRegister: 'دعوة للتسجيل',
      IsNotValid: 'غير صالح',
      IsRequired: 'مطلوب',
      IsTooLong: 'طويل جدًا',
      individualImageDesc: 'كفرد، يمكنك تقديم الخدمات وإنشاء المواعيد لعملائك.',
      Language: 'اللغة',
      'Last Visit': 'آخر زيارة',
      LastName: 'اللقب',
      LastNameOptional: 'اللقب (اختياري)',
      Leave: 'مغادرة',
      LeaveCompany: 'مغادرة الشركة.',
      LoadDraft: 'تحميل المسودة',
      Loading: 'جار التحميل',
      Location: 'الموقع',
      Logout: 'تسجيل الخروج',
      Lowercase: 'حروف صغيرة',
      lowercasePhoneNumber: 'رقم الهاتف',
      MaintenanceTime: 'وقت الصيانة',
      Male: 'ذكر',
      Manager: 'مدير',
      Min: 'دقيقة',
      Mon: 'الاثنين',
      Monday: 'الاثنين',
      Monthly: 'شهريًا',
      MyAppointments: 'المواعيد',
      MyCompany: 'شركتي',
      MyProfile: 'ملفي الشخصي',
      Name: 'الاسم',
      New: 'جديد',
      NewAppointment: 'موعد جديد',
      NewCategory: 'فئة جديدة',
      NewCompany: 'شركة جديدة',
      NewCustomer: 'عميل جديد',
      NewPassMustBeDifferentText: 'يجب أن تكون كلمة المرور الجديدة مختلفة عن السابقة',
      NewPassword: 'كلمة مرور جديدة',
      NewService: 'خدمة جديدة',
      NewStaff: 'موظف جديد',
      Next: 'التالي',
      No: 'لا',
      NoAppointments: 'لا توجد مواعيد بعد',
      NoNotifications: 'لا توجد إشعارات بعد',
      NoOptions: 'لا توجد خيارات',
      Note: 'ملاحظة',
      NoteOptional: 'ملاحظة (اختيارية)',
      Notes: 'ملاحظات الموعد',
      Notification: 'إشعار',
      NotificationCanceled: 'إشعار العملاء عند إلغاء موعدهم',
      NotificationConfirmed: 'إشعار العملاء عند تأكيد موعدهم',
      NotificationRescheduled: 'إشعار العملاء عند إعادة جدولة موعدهم',
      NotRegistered: 'غير مسجل',
      Off: 'معطل',
      On: 'تشغيل',
      Open: 'مفتوح',
      Order: 'ترتيب الجدولة',
      Password: 'كلمة المرور',
      PasswordMinLength: '8 أحرف على الأقل',
      PasswordLowercase: 'تحتوي على حرف صغير',
      PasswordUppercase: 'تحتوي على حرف كبير',
      PasswordSpecial: 'تحتوي على رقم أو رمز خاص',
      PasswordLengthRule: 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل',
      PasswordCapitalRule: 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل',
      PasswordMismatch: 'كلمات المرور غير متطابقة',
      PasswordRequired: 'كلمة المرور مطلوبة.',
      PasswordSettings: 'تغيير كلمة المرور',
      PastDate: 'السماح بإنشاء مواعيد في التاريخ السابق',
      Pending: 'قيد الانتظار',
      Canceled: 'ملغى',
      Request: 'عند الطلب',
      NoShow: 'غير موجود',
      Permalink: 'رابط دائم',
      PersonalInformation: 'المعلومات الشخصية',
      Phone: 'الهاتف',
      PhoneCode: 'رمز الدولة',
      PhoneNumber: 'رقم الهاتف',
      PhoneNumberOptional: 'رقم الهاتف (اختياري)',
      Position: 'المنصب',
      Price: 'السعر',
      PriceType: 'نوع السعر',
      Pricing: 'التسعير',
      Profession: 'المهنة',
      ProfessionOptional: 'المهنة (اختياري)',
      Range: 'النطاق',
      ReadAll: 'قراءة الكل',
      Registered: 'مسجل',
      RemindCustomer: 'تذكير العميل',
      Reminder: 'تذكيرات',
      Resend: 'إعادة الإرسال',
      ResendCode: 'إعادة إرسال الرمز',
      ResetPassword: 'إعادة تعيين كلمة المرور',
      RowsPerPage: 'الصفوف لكل صفحة',
      Sat: 'السبت',
      Saturday: 'السبت',
      Save: 'حفظ',
      SaveDraft: 'حفظ المسودة',
      SaveDraftText: 'هل ترغب في حفظ هذا الموعد كمسودة؟',
      SearchByCustomerOrStaff: 'البحث حسب العميل أو الموظف',
      SearchCustomer: 'البحث باسم العميل',
      SearchService: 'البحث باسم الخدمة',
      SearchStaff: 'البحث باسم الموظف',
      SelectedDayNotWork: 'اليوم المحدد ليس يوم عمل',
      Send: 'إرسال',
      Service: 'الخدمة',
      'Service Name': 'اسم الخدمة',
      ServiceBased: 'مستند إلى الخدمة',
      ServiceCategory: 'فئة الخدمة',
      ServiceDeleted: 'تم حذف الخدمة بنجاح.',
      ServiceDeletionText: 'ستصبح هذه الخدمة غير متاحة للحجز وإذا كان هناك أي مواعيد فسيتم إلغاؤها.',
      ServiceDuration: 'مدة الخدمة',
      ServiceNotFound: 'الخدمة غير موجودة.',
      ServiceProvider: 'مقدم الخدمة',
      Services: 'الخدمات',
      Settings: 'الإعدادات',
      ShortPassword: 'كلمة المرور قصيرة جدًا.',
      ShowLess: 'عرض أقل',
      ShowMore: 'عرض المزيد',
      ShowOnWidget: 'عرض على القائمة',
      ShowOnWidgetTooltipText: 'ستكون الخدمة متاحة للحجز عبر الإنترنت.',
      SignIn: 'تسجيل الدخول',
      SlotDuration: 'مدة الفتحة في التقويم',
      SomethingWrong: 'حدث خطأ ما.',
      Source: 'المصدر',
      SpecificDayAdded: 'تمت إضافة أيام العمل المحددة بنجاح.',
      SpecificDayDeleted: 'تم حذف أيام العمل المحددة بنجاح.',
      SpecificDaysTitle: 'أيام العمل المحددة',
      SpecificDayUpdated: 'تم تحديث أيام العمل المحددة بنجاح.',
      SpecificWorkDayDeleted: 'تم حذف أيام العمل المحددة بنجاح.',
      Staff: 'الموظفون',
      'Staff Name': 'اسم الموظف',
      StaffBased: 'مبني على الموظف',
      StaffDetails: 'تفاصيل الموظف',
      StaffNotFind: 'الموظف غير موجود!',
      StaffPreview: 'معاينة الموظف',
      Staffs: 'الموظفون',
      StartDate: 'تاريخ البدء',
      StartTime: 'وقت البدء',
      StartTimeEarlier: 'يجب أن يكون وقت البدء قبل وقت الانتهاء.',
      StartTimeRequired: 'وقت البدء مطلوب.',
      State: 'الولاية',
      Statistics: 'الإحصائيات',
      Status: 'الحالة',
      Sun: 'الأحد',
      Sunday: 'الأحد',
      signUpWith: 'أو سجل عن طريق',
      signUpWithEmail: 'سجل باستخدام البريد الإلكتروني',
      TableHeader: {
        Actions: 'الخيارات',
        BreakTime: 'الاستراحة',
        Date: 'التواريخ',
        Description: 'الوصف',
        Staff: 'الموظفين',
        Time: 'ساعات العمل',
      },
      ThankYou: 'إرسال رسالة شكر إلى العميل',
      ThisMonth: 'هذا الشهر',
      ThisServiceHas: 'هذه الخدمة بها ',
      ThisWeek: 'هذا الأسبوع',
      Thu: 'الخميس',
      Thursday: 'الخميس',
      Time: 'الوقت',
      TimeZone: 'المنطقة الزمنية',
      Tin: 'رقم الضريبة',
      Title: 'العنوان',
      To: 'إلى',
      Today: 'اليوم',
      Tomorrow: 'غدًا',
      Total: 'المجموع',
      TotalPayments: 'الإيرادات',
      Tue: 'الثلاثاء',
      Tuesday: 'الثلاثاء',
      Upcoming: 'القادمة',
      UpcomingAppointments: 'المواعيد القادمة',
      UpcomingAppointmentServiceModalText: 'الموعد(المواعيد) القادمة.',
      upcomingAppointmentWith: 'المواعيد القادمة مع',
      Update: 'تحديث',
      UpdatedSuccess: 'تم التحديث بنجاح.',
      Uppercase: 'حروف كبيرة',
      UserNotBreak: 'المستخدم في فترة استراحة خلال الساعات المحددة',
      UserNotWork: 'المستخدم لا يعمل في الساعات المحددة',
      ValidAppointment: 'حقول المواعيد الصحيحة',
      VerificationCode: 'رمز التحقق',
      VerifyCode: 'تحقق من الرمز',
      Visits: 'الزيارات',
      WalkIn: 'دخول فوري',
      WalkInDesc: 'اختر الدخول الفوري إذا كنت ترغب في تخطي العميل.',
      WebSite: 'عنوان موقع الويب للشركة',
      Wed: 'الأربعاء',
      Wednesday: 'الأربعاء',
      Weekly: 'أسبوعيًا',
      WeWillSendVerificationCode: 'سوف نرسل لك رمز التحقق المكون من 6 أرقام.',
      EnterVerificationCode: 'أدخل الرمز المكون من 6 أرقام المرسل إلى ',
      willBeCancelled: 'سيتم إلغاء.',
      WithDrawInvitation: 'إلغاء الدعوة',
      'Work Schedule': 'جدول العمل',
      WorkDateEndTimeRequired: 'وقت نهاية اليوم العمل مطلوب.',
      WorkDateStartTimeRequired: 'وقت بداية اليوم العمل مطلوب.',
      WorkdayEnd: 'نهاية اليوم العمل',
      WorkdayStart: 'بداية اليوم العمل',
      WorkingScheduleTab: {
        Company: 'الشركة',
        General: 'عام',
        Staff: 'الموظفين',
      },
      WorkSchedule: 'جدول العمل',
      Yes: 'نعم',
      whatIsTypeBusiness: 'اختر نوع عملك',
      ZipCode: 'الرمز البريدي',
      timeOff: 'إجازة',
      editTimeOff: 'تعديل الإجازة',
      newTimeOff: 'إجازة جديدة',
      timeOffDescription: 'قم بحجز فترات زمنية محددة عندما تكون غير متاح، مما يضمن عدم جدولة أي مواعيد خلال تلك الفترات.',
      startTimeRequired: 'وقت البدء مطلوب',
      startTimeInvalid: 'يجب أن يكون وقت البدء أبكر من وقت الانتهاء.',
      endTimeInvalid: 'يجب أن يكون وقت الانتهاء لاحقًا لوقت البدء.',
      endTimeRequired: 'وقت الانتهاء مطلوب',
      timeOffCreatedSuccess: 'تم إنشاء الإجازة بنجاح.',
      timeOffCreatedFailure: 'حدث خطأ أثناء إنشاء الإجازة',
      timeOffUpdatedSuccess: 'تم تحديث الإجازة بنجاح.',
      timeOffUpdatedFailure: 'حدث خطأ أثناء تحديث الإجازة',
      titleOptional: 'عنوان (اختياري)',
      TimeOffDeleteModalText: 'هل أنت متأكد أنك تريد حذف الإجازة؟',
      TimeOffDeleted: 'تم حذف الإجازة بنجاح',
    },
  },
};
export default resources;
