import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Drawer,
  FormHelperText,
  Button, FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import DefaultProfileImage from 'assets/img/default-profile.png';
import usePrevious from 'CustomHooks/usePrevious';
import { uploadImageToAmazonRequest } from 'redux/company/actions';
import { getAvatarPreSignUrlRequest } from 'redux/account/actions';
import { storeCustomerToCompanyRequest } from '../../../../../../redux/company/actions';
import { emailRegex } from '../../../../../../Modules/regexValidations';
import CustomerContext from '../customerContext';
import { updateCustomerRequest } from '../../../../../../redux/customer/actions';
import trimObject from '../../../../../../Modules/objectTrim';
import AppDatePicker from '../../../../../../Modules/AppDatePicker';
import CustomPhone from '../../../../../../Modules/CustomPhone';

function CustomerDrawer(props) {
  const { selectedCustomer } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, setOpen } = useContext(CustomerContext);

  const customer = {
    is_web: true,
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    note: '',
    phone_number: '',
    birthday: null,
    aws_avatar: null,
  };
  const defaultCustomerErrors = {
    name: { first_name: false, last_name: false },
    email: { isValid: false },
    address: { isValid: false },
    note: { isValid: false },
    birthday: { isValid: false },
    phone_number: { isRequired: false, isValid: false },
  };
  const { isGetAvatarPreSignedUrlSuccess, avatarPreSignUrl } = useSelector((state) => state.account);
  const {
    isStoreCustomerToCompanySuccess,
  } = useSelector((state) => state.company);
  const [data, setData] = useState(customer);
  const [errors, setErrors] = useState(defaultCustomerErrors);
  const [files, setFiles] = useState(null);
  const [avatarName, setAvatarName] = useState('');
  const [avatar, setAvatar] = useState(null);
  const phoneRef = useRef();
  const prevIsGetAvatarPreSignedUrlSuccess = usePrevious(isGetAvatarPreSignedUrlSuccess);
  const prevIsStoreCustomerToCompanySuccess = usePrevious(isStoreCustomerToCompanySuccess);

  useEffect(() => {
    if (open) {
      if (Object.keys(selectedCustomer).length === 0) {
        setData(customer);
        setAvatar(null);
      }

      setErrors(defaultCustomerErrors);

      if (Object.keys(selectedCustomer).length > 0) {
        setData({
          is_web: true,
          id: selectedCustomer.id,
          first_name: selectedCustomer.first_name,
          last_name: selectedCustomer.last_name,
          email: selectedCustomer.email || '',
          address: selectedCustomer.address || '',
          note: selectedCustomer.note || '',
          birthday: selectedCustomer.birthday || null,
          phone_number: selectedCustomer.phone_number,
        });
        setAvatar(selectedCustomer.avatar);
      }
    }
  }, [open]);

  useEffect(() => {
    if (prevIsStoreCustomerToCompanySuccess !== isStoreCustomerToCompanySuccess) {
      if (files) {
        dispatch(getAvatarPreSignUrlRequest({ logo: avatarName, for_customer: true }));
      }
    }
  }, [isStoreCustomerToCompanySuccess]);

  useEffect(() => {
    if (
      prevIsGetAvatarPreSignedUrlSuccess === false
      && isGetAvatarPreSignedUrlSuccess
      && Object.keys(selectedCustomer).length === 0
    ) {
      const uploadImageOption = {
        preSignUrl: avatarPreSignUrl,
        logo: avatar,
      };
      dispatch(uploadImageToAmazonRequest(uploadImageOption));
    }
  }, [isGetAvatarPreSignedUrlSuccess]);

  const handleChange = (name, value) => {
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const {
      first_name, last_name, email,
    } = data;
    errorsCopy.name.first_name = first_name.length === 0;
    errorsCopy.name.last_name = last_name.length === 0;
    errorsCopy.email.isValid = email.length
      ? !emailRegex.test(email.trim())
      : false;
    errorsCopy.phone_number.isValid = !phoneRef.current.validatePhone();
    setErrors(errorsCopy);

    return (
      Object.values(errors.phone_number).some((error) => error === true)
      || Object.values(errors.name).every((error) => error === true)
      || Object.values(errors.email).some((error) => error === true)
    );
  };

  const save = () => {
    if (!validateForm()) {
      const customerData = {
        ...data,
        email: !data.email.length ? null : data.email,
        birthday: data.birthday ? moment(data.birthday).format('YYYY-MM-DD') : null,
      };
      trimObject(customerData);
      if (Object.keys(selectedCustomer).length > 0) {
        dispatch(updateCustomerRequest(customerData));
      } else {
        dispatch(storeCustomerToCompanyRequest({ customerData }));
      }
    }
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setAvatar(acceptedFiles[0]);
      const name = moment().format('DDMMYYYYHHmmss') + acceptedFiles[0].name;
      handleChange('aws_avatar', name);
      setAvatarName(name);
    },
  });

  const handleDeletePic = (e) => {
    e.stopPropagation();
    setFiles(null);
    setAvatar(null);
    handleChange('aws_avatar', null);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      className="drawer"
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
        },
      }}
    >
      <div className="drawer-content">
        <div className="title-content">
          <h3 className="title">
            {Object.keys(selectedCustomer).length > 0 ? t('EditCustomer') : t('NewCustomer')}
          </h3>
          <CloseIcon className="close-icon" onClick={() => setOpen(false)} />
        </div>
        <div {...getRootPropsCompany({ className: 'dropzone' })}>
          <div>
            <img
              src={
                files || avatar ? files || (Object.keys(selectedCustomer).length > 0 ? avatar : null) : DefaultProfileImage
              }
              className="image"
              alt="profile"
            />
            <input {...getInputPropsCompany()} />
            <div className="action" onClick={(files || avatar) && handleDeletePic}>
              {files || avatar ? <DeleteOutlineIcon width={14} height={14} /> : <AddIcon width={14} height={14} />}
            </div>
          </div>
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={`${t('FirstName')} *`}
            label={`${t('FirstName')} *`}
            variant="outlined"
            error={errors.name.first_name && errors.name.last_name}
            value={data.first_name}
            onChange={(e) => handleChange('first_name', e.target.value)}
          />
          {errors.name.first_name
            && errors.name.last_name && (
              <FormHelperText error>
                {t('FirstNameOrLastNameRequired')}
              </FormHelperText>
          )}
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={t('LastNameOptional')}
            label={t('LastName')}
            variant="outlined"
            error={errors.name.first_name && errors.name.last_name}
            value={data.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
          />
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={t('EmailOptional')}
            label={t('Email')}
            variant="outlined"
            error={errors.email.isValid}
            value={data.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </div>
        <div className="input-content">
          <CustomPhone handleChange={handleChange} ref={phoneRef} />
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={t('AddressOptional')}
            label={t('Address')}
            variant="outlined"
            error={errors.address.isValid}
            value={data.address}
            onChange={(e) => handleChange('address', e.target.value)}
          />
        </div>
        <div className="input-content">
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
          >
            <AppDatePicker
              inputVariant="outlined"
              selectedDate={data?.birthday || null}
              onChange={(date) => handleChange('birthday', date)}
              error={errors.birthday.isValid}
              placeholder={t('DayOfBirthOptional')}
              required={false}
              className="birthPicker"
              disableFuture
            />
          </FormControl>
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={t('NoteOptional')}
            label={t('Note')}
            variant="outlined"
            error={errors.note.isValid}
            value={data.note}
            onChange={(e) => handleChange('note', e.target.value)}
          />
        </div>
      </div>
      <div className="save-btn">
        <Button variant="contained" color="primary" onClick={save}>{t('Save')}</Button>
      </div>
    </Drawer>
  );
}

export default CustomerDrawer;

CustomerDrawer.propTypes = {
  selectedCustomer: PropTypes.object,
};

CustomerDrawer.defaultProps = {
  selectedCustomer: {},
};
