import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Drawer,
  FormHelperText,
  InputAdornment,
  Button, FormControl,
} from '@material-ui/core';
import moment from 'moment/moment';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { useTranslation } from 'react-i18next';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useDispatch, useSelector } from 'react-redux';
import { storeCustomerToCompanyRequest, uploadImageToAmazonRequest } from 'redux/company/actions';
import { emailRegex } from 'Modules/regexValidations';
import { updateCustomerRequest } from 'redux/customer/actions';
import { getAvatarPreSignUrlRequest } from 'redux/account/actions';
import DefaultProfileImage from 'assets/img/default-profile.png';
import usePrevious from 'CustomHooks/usePrevious';
import trimObject from 'Modules/objectTrim';
import { useDropzone } from 'react-dropzone';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import CustomerContext from '../customerContext';
import LeftSide from './leftSide';
import AppointmentBox from './appointmentBox';
import AppDatePicker from '../../../../../../Modules/AppDatePicker';
import CustomPhone from '../../../../../../Modules/CustomPhone';

function CustomerDrawer(props) {
  const {
    selectedCustomer,
  } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    openEditDrawer, setOpenEditDrawer, isUpdate, setIsUpdate, selectedIndex, setSelectedIndex, deleteCustomer, setSnackbarData,
  } = useContext(CustomerContext);

  const {
    updateCustomerErrors,
    isUpdateCustomerError,
    customerUpcomingAppointments,
    customerUpcomingAppointmentsCount,
    isGetCustomerUpcomingAppointmentsSuccess,
    isGetCustomerUpcomingAppointmentsError,
    getCustomerUpcomingAppointmentsErrorMessage,
    customerAppointmentsHistory,
    customerAppointmentsHistoryCount,
    isGetCustomerAppointmentsHistorySuccess,
    isGetCustomerAppointmentsHistoryError,
    getCustomerAppointmentsHistoryErrorMessage,
    isUpdateCustomerSuccess,
  } = useSelector((state) => state.customers);
  const {
    storeCustomerToCompanyErrors,
    isStoreCustomerToCompanyError,
  } = useSelector((state) => state.company);
  const { isGetAvatarPreSignedUrlSuccess, avatarPreSignUrl } = useSelector((state) => state.account);

  const prevIsStoreCustomerToCompanyError = usePrevious(isStoreCustomerToCompanyError);
  const prevIsUpdateCustomerError = usePrevious(isUpdateCustomerError);
  const prevIsGetCustomerUpcomingAppointmentsSuccess = usePrevious(isGetCustomerUpcomingAppointmentsSuccess);
  const prevIsGetCustomerUpcomingAppointmentsError = usePrevious(isGetCustomerUpcomingAppointmentsError);
  const prevIsGetCustomerAppointmentsHistorySuccess = usePrevious(isGetCustomerAppointmentsHistorySuccess);
  const prevIsGetCustomerAppointmentsHistoryError = usePrevious(isGetCustomerAppointmentsHistoryError);
  const prevIsGetAvatarPreSignedUrlSuccess = usePrevious(isGetAvatarPreSignedUrlSuccess);
  const prevIsUpdateCustomerSuccess = usePrevious(isUpdateCustomerSuccess);

  const customer = {
    is_web: true,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    isGuest: false,
    address: '',
    note: '',
    birthday: null,
    aws_avatar: null,
  };
  const defaultCustomerErrors = {
    name: { first_name: false, last_name: false },
    email: { isValid: false },
    address: { isValid: false },
    note: { isValid: false },
    birthday: { isValid: false },
    phone_number: { isRequired: false, isValid: false },
  };

  const [data, setData] = useState(customer);
  const [errors, setErrors] = useState(defaultCustomerErrors);
  const [actionError, setActionErrors] = useState({});
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState({});
  const [appointmentsHistory, setAppointmentsHistory] = useState({});
  const [upcomingAppointmentsCount, setUpcomingAppointmentsCount] = useState(0);
  const [appointmentsHistoryCount, setAppointmentsHistoryCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState(null);
  const [avatarName, setAvatarName] = useState('');
  const [avatar, setAvatar] = useState(null);
  const phoneRef = useRef();

  useEffect(() => {
    if (prevIsGetCustomerUpcomingAppointmentsSuccess === false && isGetCustomerUpcomingAppointmentsSuccess) {
      // const sortedAppointments = sortByDate(customerUpcomingAppointments);
      setUpcomingAppointmentsCount(customerUpcomingAppointmentsCount);
      setUpcomingAppointments(customerUpcomingAppointments);
      setLoading(false);
    } else if (prevIsGetCustomerUpcomingAppointmentsError === false && isGetCustomerUpcomingAppointmentsError) {
      setLoading(false);
      setSnackbarData({
        message: getCustomerUpcomingAppointmentsErrorMessage,
        type: 'error',
        open: true,
      });
    }
  }, [isGetCustomerUpcomingAppointmentsSuccess, isGetCustomerUpcomingAppointmentsError]);

  useEffect(() => {
    if (prevIsGetCustomerAppointmentsHistorySuccess === false && isGetCustomerAppointmentsHistorySuccess) {
      // const sortedAppointmentsHistory = sortByDate(customerAppointmentsHistory);
      setAppointmentsHistoryCount(customerAppointmentsHistoryCount);
      setAppointmentsHistory(customerAppointmentsHistory);
      setLoading(false);
    } else if (prevIsGetCustomerAppointmentsHistoryError === false && isGetCustomerAppointmentsHistoryError) {
      setLoading(false);
      setSnackbarData({
        message: getCustomerAppointmentsHistoryErrorMessage,
        type: 'error',
        open: true,
      });
    }
  }, [isGetCustomerAppointmentsHistorySuccess, isGetCustomerAppointmentsHistoryError]);

  useEffect(() => {
    if (openEditDrawer) {
      if (Object.keys(selectedCustomer).length === 0) {
        setData(customer);
        setAvatar(null);
      }

      setErrors(defaultCustomerErrors);
      setActionErrors({});

      if (Object.keys(selectedCustomer).length > 0) {
        setData({
          is_web: true,
          id: selectedCustomer.id,
          first_name: selectedCustomer.first_name,
          last_name: selectedCustomer.last_name,
          email: selectedCustomer.email || '',
          phone_number: selectedCustomer.phone_number,
          address: selectedCustomer.address || '',
          note: selectedCustomer.note || '',
          birthday: selectedCustomer.birthday || null,
          is_guest: selectedCustomer.is_guest,
        });

        setAvatar(selectedCustomer.avatar);
      }
    }
  }, [openEditDrawer]);

  useEffect(() => {
    if (prevIsUpdateCustomerSuccess !== isUpdateCustomerSuccess) {
      if (files) {
        dispatch(getAvatarPreSignUrlRequest({ logo: avatarName, for_customer: true }));
      }
    }
  }, [isUpdateCustomerSuccess]);

  useEffect(() => {
    if (
      prevIsGetAvatarPreSignedUrlSuccess === false
      && isGetAvatarPreSignedUrlSuccess
    ) {
      const uploadImageOption = {
        preSignUrl: avatarPreSignUrl,
        logo: avatar,
      };
      dispatch(uploadImageToAmazonRequest(uploadImageOption));
    }
  }, [isGetAvatarPreSignedUrlSuccess]);

  useEffect(() => {
    if (prevIsStoreCustomerToCompanyError === false && isStoreCustomerToCompanyError) {
      setActionErrors(storeCustomerToCompanyErrors);
    }
  }, [isStoreCustomerToCompanyError]);

  useEffect(() => {
    if (prevIsUpdateCustomerError === false && isUpdateCustomerError) {
      setActionErrors(updateCustomerErrors);
    }
  }, [isUpdateCustomerError]);

  const handleChange = (name, value) => {
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const {
      first_name, last_name, email,
    } = data;
    errorsCopy.name.first_name = first_name.length === 0;
    errorsCopy.name.last_name = last_name.length === 0;
    errorsCopy.email.isValid = email.length
      ? !emailRegex.test(email.trim())
      : false;
    errorsCopy.phone_number.isValid = !phoneRef.current.validatePhone();
    setErrors(errorsCopy);

    return (
      Object.values(errors.phone_number).some((error) => error === true)
      || Object.values(errors.name).every((error) => error === true)
      || Object.values(errors.email).some((error) => error === true)
    );
  };

  const save = () => {
    if (!validateForm()) {
      const customerData = {
        ...data,
        email: !data.email.length ? null : data.email,
        birthday: data.birthday ? moment(data.birthday).format('YYYY-MM-DD') : null,
      };
      trimObject(customerData);
      if (Object.keys(selectedCustomer).length > 0) {
        dispatch(updateCustomerRequest(customerData));
      } else {
        dispatch(storeCustomerToCompanyRequest({ customerData }));
      }
    }
  };

  const onClose = () => {
    setOpenEditDrawer(false);
    setAppointmentsHistory({});
    setUpcomingAppointments({});
    setLoading(true);
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setAvatar(acceptedFiles[0]);
      const name = moment().format('DDMMYYYYHHmmss') + acceptedFiles[0].name;
      handleChange('aws_avatar', name);
      setAvatarName(name);
    },
  });

  const handleDeletePic = (e) => {
    e.stopPropagation();
    setFiles(null);
    setAvatar(null);
    handleChange('aws_avatar', null);
  };

  return (
    <Drawer
      anchor="right"
      open={openEditDrawer}
      onClose={onClose}
      className="drawer2"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          padding: '20px 0 0 0',
        },
      }}
    >
      <div className="title-content">
        <h3 className="title">
          {t('CustomerDetails')}
        </h3>
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
      <div className="content">
        <div className="left-content">
          <LeftSide
            appointmentsHistory={appointmentsHistory}
            setLoading={setLoading}
            deleteCustomer={deleteCustomer}
            selectedCustomer={selectedCustomer}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
        <div className="rightContent">
          {isUpdate && (
            <>
              <div className="drawer-content">
                <p className="editCustomerTitle">
                  {t('EditCustomer')}
                </p>
                <div {...getRootPropsCompany({ className: 'dropzone' })}>
                  <div>
                    <img
                      src={
                        files || avatar ? files || (Object.keys(selectedCustomer).length > 0 ? avatar : null) : DefaultProfileImage
                      }
                      className="image"
                      alt="profile"
                    />
                    <input {...getInputPropsCompany()} />
                    <div className="action" onClick={(files || avatar) && handleDeletePic}>
                      {files || avatar ? <DeleteOutlineIcon width={14} height={14} /> : <AddIcon width={14} height={14} />}
                    </div>
                  </div>
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={`${t('FirstName')} *`}
                    label={`${t('FirstName')} *`}
                    variant="outlined"
                    error={errors.name.first_name && errors.name.last_name}
                    value={data.first_name}
                    onChange={(e) => handleChange('first_name', e.target.value)}
                  />
                  {errors.name.first_name
                    && errors.name.last_name && (
                    <FormHelperText error>
                      {t('FirstNameOrLastNameRequired')}
                    </FormHelperText>
                  )}
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('LastNameOptional')}
                    label={t('LastName')}
                    variant="outlined"
                    error={errors.name.first_name && errors.name.last_name}
                    value={data.last_name}
                    onChange={(e) => handleChange('last_name', e.target.value)}
                  />
                </div>
                {data.is_guest && (
                  <div className="input-content">
                    <TextField
                      type="text"
                      size="small"
                      color="secondary"
                      className="input"
                      placeholder={t('EmailOptional')}
                      label={t('Email')}
                      disabled
                      variant="outlined"
                      error={errors.email.isValid || actionError.email}
                      value={data.email}
                      onChange={(e) => handleChange('email', e.target.value)}
                      InputProps={
                              isSelectedEmail
                                ? {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineIcon fontSize="small" color={errors.email.isValid || actionError.email ? 'error' : 'secondary'} />
                                    </InputAdornment>
                                  ),
                                }
                                : {}
                            }
                      onFocus={() => setIsSelectedEmail(true)}
                      onBlur={() => setIsSelectedEmail(false)}
                    />
                    {errors.email.isValid ? (
                      <FormHelperText error>
                        {t('EmailValid')}
                      </FormHelperText>
                    ) : actionError.email ? (
                      <FormHelperText error>
                        {actionError.email}
                      </FormHelperText>
                    ) : null}
                  </div>
                )}
                <div className="input-content">
                  <CustomPhone handleChange={handleChange} ref={phoneRef} initialCode={data.phone_code} initialNumber={data.phone_number} />
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('AddressOptional')}
                    label={t('Address')}
                    variant="outlined"
                    error={errors.address.isValid}
                    value={data.address}
                    onChange={(e) => handleChange('address', e.target.value)}
                  />
                </div>
                <div className="input-content">
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                  >
                    <AppDatePicker
                      inputVariant="outlined"
                      selectedDate={data?.birthday || null}
                      onChange={(date) => handleChange('birthday', date)}
                      error={errors.birthday.isValid}
                      placeholder={t('DayOfBirthOptional')}
                      required={false}
                      className="birthPicker"
                      disableFuture
                    />
                  </FormControl>
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('NoteOptional')}
                    label={t('Note')}
                    variant="outlined"
                    error={errors.note.isValid}
                    value={data.note}
                    onChange={(e) => handleChange('note', e.target.value)}
                  />
                </div>
              </div>
              <div className="save-btn">
                <div>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 4,
                      border: '1px solid #A4A4A4',
                      backgroundColor: '#FFFFFF',
                      font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                      color: '#A4A4A4',
                      width: 100,
                      height: '36px',
                    }}
                    onClick={onClose}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <div>
                  <Button variant="contained" color="primary" onClick={save} style={{ width: '100px', height: '36px' }}>{t('Save')}</Button>
                </div>
              </div>
            </>
          )}
          {(selectedIndex === 0 || selectedIndex === 1) && !isUpdate && (
            <AppointmentBox
              id={selectedCustomer.id}
              selectedIndex={selectedIndex}
              title={t(selectedIndex === 1 ? 'UpcomingAppointments' : 'AppointmentsHistory')}
              data={selectedIndex === 1 ? upcomingAppointments : appointmentsHistory}
              count={selectedIndex === 1 ? upcomingAppointmentsCount : appointmentsHistoryCount}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default CustomerDrawer;

CustomerDrawer.propTypes = {
  selectedCustomer: PropTypes.object,
};

CustomerDrawer.defaultProps = {
  selectedCustomer: {},
};
