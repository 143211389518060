const resources = {
  pt: {
    translation: {
      Accept: 'Aceitar',
      Accepted: 'Aceito',
      AccountCreated: 'Conta criada.',
      Actions: 'Opções',
      Add: 'Adicionar',
      AddAppointment: 'Adicionar Agendamento',
      AddClosedDays: 'Adicionar Dias Fechados',
      AddCompany: 'Adicionar Empresa',
      AddCustomer: 'Adicionar Cliente',
      AddDayOff: 'Adicionar Dias de Folga',
      AddNewCustomer: 'Adicionar Novo Cliente',
      Address: 'Endereço',
      AddressOptional: 'Endereço (opcional)',
      AddService: 'Adicionar Serviço',
      AddShortDescOptional: 'Adicionar uma descrição breve (opcional)',
      AddSpecificWorkDays: 'Adicionar Dias de Trabalho Específicos',
      AddStaff: 'Adicionar Funcionário',
      All: 'Todos',
      AllAppointmentsCancelText: 'Todos os próximos agendamentos serão cancelados.',
      AlreadyHaveAccount: 'Já possui uma conta?',
      AMD: 'AMD',
      anIndividual: 'Um Indivíduo',
      Apply: 'Aplicar',
      ApplyToAll: 'Aplicar a Todos',
      'Appointment Count': 'Contagem de Agendamentos',
      AppointmentAccepted: 'Agendamento aceito com sucesso',
      AppointmentCanceled: 'Agendamento cancelado com sucesso',
      AppointmentCancelModalText: 'Tem certeza de que deseja cancelar seu agendamento?',
      AppointmentCreated: 'Agendamento criado com sucesso',
      AppointmentDeleted: 'Agendamento excluído com sucesso',
      AppointmentDeleteModalText: 'O agendamento desaparecerá do calendário e não será calculado nas estatísticas.',
      AppointmentDeleteQuestion: 'Tem certeza de que deseja excluir o agendamento?',
      AppointmentDetails: 'Detalhes do Agendamento',
      AppointmentHistory: 'Histórico de Agendamento',
      AppointmentNotes: 'Não visível para os clientes',
      AppointmentNotFiltered: 'O agendamento não está filtrado',
      AppointmentNotFound: 'Agendamento não encontrado.',
      AppointmentRescheduled: 'Agendamento remarcado com sucesso',
      Appointments: 'Agendamentos',
      AppointmentSaveDraft: 'Agendamento salvo como rascunho',
      AppointmentsHistory: 'Histórico de Agendamentos',
      AreYouSureCancel: 'Tem certeza de que deseja cancelar o',
      AreYouSureDelete: 'Tem certeza de que deseja excluir o',
      'Assigned Services': 'Serviços Designados',
      AtLeastOne: 'Pelo menos um ',
      AutoAccept: 'Aceitar automaticamente agendamentos',
      AutoAcceptLabel: 'Auto Aceitar',
      AutoAcceptTooltipText: 'Agendamentos recebidos da reserva online serão aceitos automaticamente.',
      AutoFinishLabel: 'Finalização automática',
      AutoFinish: 'Marcar automaticamente os agendamentos confirmados como concluídos quando o horário de término passar',
      AvailableHours: 'Horários disponíveis',
      BookingLink: 'Link de Reserva',
      BookingUrl: 'URL de Reserva',
      BreakEndTimeAfter: 'O horário de término do intervalo deve ser após o horário de início do intervalo.',
      BreakEndTimeRequired: 'O horário de término do intervalo é obrigatório.',
      Breaking: 'Intervalo',
      BreakStartTimeRequired: 'O horário de início do intervalo é obrigatório.',
      BufferTime: 'Tempo de Buffer',
      BufferTimeTooltipText: 'Adicionar tempo extra após o serviço, que não é mostrado ao usuário.',
      BusinessNotSaved: 'Detalhes da empresa não salvos.',
      BusinessSaved: 'Detalhes da empresa salvos.',
      businessInfo: 'Informações do Negócio',
      businessName: 'Nome do Negócio',
      ByDate: 'Por Data',
      ByStatus: 'Por Status',
      Calendar: 'Calendário',
      CalendarTimeSlot: 'Intervalo de Tempo',
      Cancel: 'Cancelar',
      CancelAllUpcomingAppointments: 'Cancelar todos os próximos agendamentos',
      CancelAppointment: 'Cancelar Agendamento',
      CancelInvitation: 'Cancelar Convite',
      Cancelled: 'Cancelado',
      CantInviteYourSelf: 'Você não pode convidar a si mesmo como cliente',
      Categories: 'Categorias',
      Category: 'Categoria',
      CategoryIsRequired: 'A categoria é obrigatória',
      Characters: 'caracteres',
      ChooseCustomer: 'Selecionar um cliente',
      ChooseHour: 'Escolher Hora',
      ChooseMinutes: 'Escolher Minutos',
      chooseOrCreateIndustry: 'Selecione ou adicione um setor',
      ChooseOrCreateService: 'Escolher ou Criar Serviço',
      ChooseOrCreateServiceCategory: 'Escolher ou Criar Categoria de Serviço',
      ChooseService: 'Selecionar um serviço',
      ChooseServiceCategory: 'Escolher Categoria de Serviço',
      ChooseServiceProvider: 'Escolher Prestador de Serviço',
      ChooseStaff: 'Selecionar um membro da equipe',
      City: 'Cidade',
      Close: 'Fechado',
      ClosedDayAdded: 'Dia(s) fechado(s) adicionado(s) com sucesso.',
      ClosedDays: 'Dias Fechados',
      ClosedDayUpdated: 'Dia(s) fechado(s) atualizado(s) com sucesso.',
      Code: 'Código do País',
      Companies: 'Empresas',
      Company: 'Empresa',
      CompanyDeleted: 'Empresa excluída com sucesso',
      CompanyEmail: 'Digite o endereço de e-mail da empresa',
      CompanyName: 'Nome da Empresa',
      CompanyOverview: 'Escreva uma breve visão geral da sua empresa',
      Confirm: 'Confirmar',
      ConfirmActionMassagesDelete: 'Tem certeza de que deseja remover esta empresa?',
      ConfirmActionMassagesDeleteError: 'Você não pode remover esta empresa ainda porque ela tem agendamentos futuros.',
      ConfirmActionMassagesLeave: 'Tem certeza de que deseja deixar a empresa?',
      ConfirmCancel: 'Confirmar Cancelamento',
      ConfirmDelete: 'Confirmar Exclusão',
      ConfirmDeletion: 'Confirmar Exclusão',
      ConfirmPassword: 'Confirmar Senha',
      continueDeleting: 'Continuar excluindo?',
      continueWithFacebook: 'Continuar com o Facebook',
      Country: 'País',
      CodeInvalidError: 'O comprimento do código deve ser 6.',
      Create: 'Criar',
      CreateAppointment: 'Criar Agendamento',
      CreateService: 'Criar Serviço',
      CurrentPassword: 'Senha Atual',
      Customer: 'Cliente',
      'Customer Name': 'Nome do Cliente',
      CustomerAdded: 'Cliente adicionado',
      CustomerDeleted: 'Cliente excluído com sucesso',
      CustomerDeleteText: 'Se houver algum agendamento com esses clientes, ele será cancelado.',
      CustomerDetails: 'Detalhes do Cliente',
      CustomerPreview: 'Visualização do Cliente',
      Customers: 'Clientes',
      CustomerUpdated: 'Cliente atualizado com sucesso',
      companyImageDesc:
        'Como empresa, você pode adicionar sua equipe, atribuir serviços e criar agendamentos.',
      Daily: 'Diariamente',
      Date: 'Data',
      DateAndTime: 'Data e Hora',
      DatePicker: 'Seletor de Data',
      DateTime: 'Data/Hora',
      DayAdded: 'Dia(s) de folga adicionado(s) com sucesso',
      DayOfBirth: 'Data de Nascimento',
      DayOfBirthOptional: 'Data de nascimento (opcional)',
      BirthDay: 'Aniversário',
      DayOffDeleted: 'Dia(s) de folga excluído(s) com sucesso.',
      DaysOff: 'Dias de Folga',
      DayUpdated: 'Dia(s) de folga atualizado(s) com sucesso.',
      Decline: 'Recusar',
      DefaultView: 'Visualização padrão do calendário',
      Delete: 'Excluir',
      DeleteCategoryText: 'Se você excluir esta categoria de serviço, todos os serviços abaixo dela também serão excluídos.',
      deleteMultipleStaffText: 'Se houver algum agendamento com esses funcionários, ele será cancelado.',
      Description: 'Descrição',
      Details: 'Detalhes',
      Digit: 'dígito',
      DontGetCode: 'Não recebeu o código?',
      DontHaveAnAccount: 'Não tem uma conta?',
      DownloadAndSignUp: 'Baixe o aplicativo e cadastre-se agora!',
      Duration: 'Duração',
      Edit: 'Editar',
      EditAppointment: 'Editar Agendamento',
      EditCategory: 'Editar Categoria',
      EditCustomer: 'Editar Cliente',
      EditService: 'Editar Serviço',
      EditSpecificWorkDays: 'Editar Dias de Trabalho Específicos',
      EditStaff: 'Editar Funcionário',
      Email: 'E-mail',
      EmailOptional: 'E-mail (opcional)',
      EmailRequired: 'O e-mail é obrigatório.',
      EmailResent: 'E-mail enviado com sucesso.',
      EmailValid: 'Por favor, insira um endereço de e-mail válido.',
      EndDate: 'Data de Término',
      EndDateRequired: 'A data de término é obrigatória.',
      EndTime: 'Horário de Término',
      EndTimeAfter: 'O horário de término deve ser depois do horário de início.',
      EnterCodeText: 'Digite o código de 6 dígitos enviado para o seu',
      EnterCustomerEmail: 'Digite o e-mail do cliente',
      EnterCustomerFirstName: 'Digite o primeiro nome do cliente',
      EnterCustomerLastName: 'Digite o sobrenome do cliente',
      EnterCustomerPhoneNumber: 'Digite o número de telefone do cliente',
      EnterSignInDetailsText: 'Por favor, insira seus detalhes para fazer login.',
      EnterStaffFirstName: 'Digite o primeiro nome do funcionário',
      EnterStaffLastName: 'Digite o sobrenome do funcionário',
      EnterStaffPhoneNumber: 'Digite o número de telefone do funcionário',
      EnterStaffProfession: 'Digite a profissão do funcionário',
      Facebook: 'URL da página do Facebook',
      FasterAndMoreOrganizedText: 'Vamos tornar o processo de reserva mais rápido e organizado.',
      Female: 'Feminino',
      FileNotSupported: 'O arquivo não é suportado',
      Filter: 'Filtro',
      FinalPriceText: 'Por favor, defina o preço final para este serviço.',
      Finish: 'Terminar',
      FinishAppointment: 'Terminar Agendamento',
      Finished: 'Finalizado',
      'First Visit': 'Primeira Visita',
      FirstName: 'Nome',
      FirstNameOrLastNameRequired: 'O primeiro nome ou sobrenome é obrigatório',
      Fixed: 'Fixo',
      FollowUp: 'Acompanhamento',
      ForgotPassword1: 'Esqueceu a senha?',
      ForgotPassword2: 'Esqueceu a senha',
      Free: 'Grátis',
      Fri: 'Sex',
      Friday: 'Sexta-feira',
      From: 'De',
      Gallery: 'Galeria',
      Gender: 'Gênero',
      General: 'Informações Gerais',
      GeneralSchedule: {
        BreakHours: 'Horário de Intervalo',
        Title: 'Horário Geral',
        WeekDays: 'Dias Úteis',
        WorkingHours: 'Horário de Trabalho',
      },
      H: 'h',
      haveOneStaff: 'Ter pelo menos 1 funcionário é obrigatório.',
      Hour: 'Hora',
      ImageDeleted: 'Imagem excluída com sucesso',
      IndustriesNotSaved: 'Detalhes das indústrias não salvos.',
      Industry: 'Indústria',
      IndustryDeleted: 'Indústria excluída com sucesso.',
      IndustryNotFound: 'Indústria não encontrada.',
      IndustryUpdated: 'Indústria atualizada com sucesso.',
      Info: 'Informações',
      InfoGeneral: 'Informações Gerais',
      InfoLinks: 'Links',
      InfoLocation: 'Localização',
      InfoTitle: 'Informações',
      Instagram: 'Nome de usuário do Instagram',
      InvalidData: 'Dados inválidos.',
      InvalidLoginOrPass: 'Login ou senha inválidos.',
      Invitation: 'Convite',
      InvitationAccepted: 'Convite aceito com sucesso',
      InvitationDeclined: 'Convite recusado.',
      InvitationDeleted: 'Convite excluído com sucesso',
      InvitationSent: 'Convite enviado.',
      InvitationUpdated: 'Convite atualizado com sucesso',
      Invite: 'Convidar',
      InviteCustomer: 'Convidar para Registrar',
      InviteCustomerDescription: 'Convide <0>{{name}}</0> para se registrar no SetTime.',
      Invited: 'Convidado',
      InviteToRegister: 'Convidar para registrar',
      IsNotValid: ' não é válido',
      IsRequired: ' é obrigatório',
      IsTooLong: ' é muito longo',
      individualImageDesc:
        'Como indivíduo, você fornece serviços e cria agendamentos para seus clientes.',
      Language: 'Idioma',
      'Last Visit': 'Última Visita',
      LastName: 'Sobrenome',
      LastNameOptional: 'Sobrenome (opcional)',
      Leave: 'Sair',
      LeaveCompany: 'Sair da empresa.',
      LoadDraft: 'Carregar Rascunho',
      Loading: 'Carregando',
      Location: 'Localização',
      Logout: 'Sair',
      Lowercase: 'minúsculo',
      lowercasePhoneNumber: 'Número de telefone',
      MaintenanceTime: 'Horário de Manutenção',
      Male: 'Masculino',
      Manager: 'Gerente',
      Min: 'min',
      Mon: 'Seg',
      Monday: 'Segunda-feira',
      Monthly: 'Mensalmente',
      MyAppointments: 'Agendamentos',
      MyCompany: 'Minha Empresa',
      MyProfile: 'Meu Perfil',
      Name: 'Nome',
      New: 'Novo',
      NewAppointment: 'Novo Agendamento',
      NewCategory: 'Nova Categoria',
      NewCompany: 'Nova Empresa',
      NewCustomer: 'Novo Cliente',
      NewPassMustBeDifferentText: 'Sua nova senha deve ser diferente da senha anteriormente utilizada',
      NewPassword: 'Nova Senha',
      NewService: 'Novo Serviço',
      NewStaff: 'Novo Funcionário',
      Next: 'Próximo',
      No: 'Não',
      NoAppointments: 'Nenhum agendamento ainda',
      NoNotifications: 'Nenhuma notificação ainda',
      NoOptions: 'Sem Opções',
      Note: 'Nota',
      NoteOptional: 'Nota (opcional)',
      Notes: 'Notas do Agendamento',
      Notification: 'Notificação',
      NotificationCanceled: 'Notificar os clientes quando o agendamento deles for cancelado',
      NotificationConfirmed: 'Notificar os clientes quando o agendamento deles for confirmado',
      NotificationRescheduled: 'Notificar os clientes quando o agendamento deles for reagendado',
      NotRegistered: 'Não registrado',
      Off: 'Desligado',
      On: 'Ligado',
      Open: 'Aberto',
      Order: 'Ordem de Agendamento',
      Password: 'Senha',
      PasswordRequired: 'A senha é obrigatória.',
      PasswordSettings: 'Alterar Senha',
      PasswordMinLength: 'Pelo menos 8 caracteres',
      PasswordLowercase: 'Conter uma letra minúscula',
      PasswordUppercase: 'Conter uma letra maiúscula',
      PasswordSpecial: 'Conter um número ou caractere especial',
      PasswordLengthRule: 'A senha deve conter pelo menos 8 caracteres',
      PasswordCapitalRule: 'A senha deve conter pelo menos 1 letra maiúscula',
      PasswordMismatch: 'As senhas não coincidem',
      PastDate: 'Permitir criar agendamentos em datas passadas',
      Pending: 'Pendente',
      Canceled: 'Cancelado',
      Request: 'Sob pedido',
      NoShow: 'Não compareceu',
      Permalink: 'Permalink',
      PersonalInformation: 'Informações Pessoais',
      Phone: 'Telefone',
      PhoneCode: 'Código do País',
      PhoneNumber: 'Número de Telefone',
      PhoneNumberOptional: 'Número de Telefone (opcional)',
      Position: 'Cargo',
      Price: 'Preço',
      PriceType: 'Tipo de Preço',
      Pricing: 'Preços',
      Profession: 'Profissão',
      ProfessionOptional: 'Profissão (opcional)',
      Range: 'Faixa',
      ReadAll: 'Ler Todos',
      Registered: 'Registrado',
      RemindCustomer: 'Lembrar o cliente',
      Reminder: 'Lembretes',
      Resend: 'Reenviar',
      ResendCode: 'Reenviar código',
      ResetPassword: 'Redefinir Senha',
      RowsPerPage: 'Linhas por Página',
      Sat: 'Sáb',
      Saturday: 'Sábado',
      Save: 'Salvar',
      SaveDraft: 'Salvar Rascunho',
      SaveDraftText: 'Gostaria de salvar este agendamento como rascunho?',
      SearchByCustomerOrStaff: 'Pesquisar por cliente ou funcionário',
      SearchCustomer: 'Pesquisar por nome do cliente',
      SearchService: 'Pesquisar por nome do serviço',
      SearchStaff: 'Pesquisar por nome do funcionário',
      SelectedDayNotWork: 'O dia selecionado não é um dia útil',
      Send: 'Enviar',
      Service: 'Serviço',
      'Service Name': 'Nome do Serviço',
      ServiceBased: 'Baseado em Serviço',
      ServiceCategory: 'Categoria de Serviço',
      ServiceDeleted: 'Serviço excluído com sucesso.',
      ServiceDeletionText: 'Este serviço não estará disponível para agendamento e, se houver algum agendamento, ele será cancelado.',
      ServiceDuration: 'Duração do Serviço',
      ServiceNotFound: 'Serviço não encontrado.',
      ServiceProvider: 'provedor de serviço',
      Services: 'Serviços',
      Settings: 'Configurações',
      ShortPassword: 'A senha é muito curta.',
      ShowLess: 'Mostrar Menos',
      ShowMore: 'Mostrar Mais',
      ShowOnWidget: 'Mostrar no Widget',
      ShowOnWidgetTooltipText: 'O serviço estará disponível para agendamento online.',
      SignIn: 'Entrar',
      SlotDuration: 'Duração do intervalo do calendário',
      SomethingWrong: 'Algo deu errado.',
      Source: 'Fonte',
      SpecificDayAdded: 'Dia(s) de trabalho específico(s) adicionado(s) com sucesso.',
      SpecificDayDeleted: 'Dia(s) de trabalho específico(s) excluído(s) com sucesso.',
      SpecificDaysTitle: 'Dias de Trabalho Específicos',
      SpecificDayUpdated: 'Dia(s) de trabalho específico(s) atualizado(s) com sucesso.',
      SpecificWorkDayDeleted: 'Dia(s) de trabalho específico(s) excluído(s) com sucesso.',
      Staff: 'Funcionário',
      'Staff Name': 'Nome do Funcionário',
      StaffBased: 'Baseado em Funcionário',
      StaffDetails: 'Detalhes do Funcionário',
      StaffNotFind: 'Funcionário não encontrado !',
      StaffPreview: 'Visualização do Funcionário',
      Staffs: 'Funcionários',
      StartDate: 'Data de Início',
      StartTime: 'Hora de Início',
      StartTimeEarlier: 'A hora de início deve ser anterior à hora de término.',
      StartTimeRequired: 'A hora de início é obrigatória.',
      State: 'Estado',
      Statistics: 'Estatísticas',
      Status: 'Status',
      Sun: 'Dom',
      Sunday: 'Domingo',
      signUp: 'Cadastrar-se',
      signUpWith: 'Ou cadastre-se com',
      signUpWithEmail: 'Cadastre-se com email',
      TableHeader: {
        Actions: 'Opções',
        BreakTime: 'Intervalo',
        Date: 'Datas',
        Description: 'Descrição',
        Staff: 'Funcionário',
        Time: 'Horário de Trabalho',
      },
      ThankYou: 'Enviar uma mensagem de agradecimento ao cliente',
      ThisMonth: 'Este mês',
      ThisServiceHas: 'Este serviço tem ',
      ThisWeek: 'Esta semana',
      Thu: 'Qui',
      Thursday: 'Quinta-feira',
      Time: 'Tempo',
      TimeZone: 'Fuso Horário',
      Tin: 'TIN',
      Title: 'Título',
      To: 'Para',
      Today: 'Hoje',
      Tomorrow: 'Amanhã',
      Total: 'Total',
      TotalPayments: 'Total de Pagamentos',
      Tue: 'Ter',
      Tuesday: 'Terça-feira',
      Upcoming: 'Próximos',
      UpcomingAppointments: 'Próximos Agendamentos',
      UpcomingAppointmentServiceModalText: ' agendamento(s) futuro(s).',
      upcomingAppointmentWith: 'Os próximos agendamentos com',
      Update: 'Atualizar',
      UpdatedSuccess: 'Atualizado com Sucesso.',
      Uppercase: 'maiúsculas',
      UserNotBreak: 'O usuário está em intervalo nas horas selecionadas',
      UserNotWork: 'O usuário não está trabalhando nas horas selecionadas',
      ValidAppointment: 'Campos de Agendamento Válidos',
      VerificationCode: 'Código de Verificação',
      VerifyCode: 'Verificar código',
      Visits: 'Visitas',
      WalkIn: 'Entrada Sem Agendamento',
      WalkInDesc: 'Escolha a entrada sem agendamento se desejar pular o cliente.',
      WebSite: 'URL do Website da Empresa',
      Wed: 'Qua',
      Wednesday: 'Quarta-feira',
      Weekly: 'Semanal',
      WeWillSendVerificationCode: 'Enviaremos a você o código de verificação de 6 dígitos.',
      EnterVerificationCode: 'Digite o código de 6 dígitos enviado para o seu ',
      willBeCancelled: ' será cancelado.',
      WithDrawInvitation: 'Cancelar Convite',
      'Work Schedule': 'Horário de Trabalho',
      WorkDateEndTimeRequired: 'O horário de término do dia de trabalho é obrigatório.',
      WorkDateStartTimeRequired: 'O horário de início do dia de trabalho é obrigatório.',
      WorkdayEnd: 'Fim do Dia de Trabalho',
      WorkdayStart: 'Início do Dia de Trabalho',
      WorkingScheduleTab: {
        Company: 'Empresa',
        General: 'Geral',
        Staff: 'Funcionários',
      },
      WorkSchedule: 'Horário de Trabalho',
      whatIsTypeBusiness: 'Selecione o tipo do seu negócio',
      Yes: 'Sim',
      ZipCode: 'Código Postal',
      timeOff: 'Folga',
      editTimeOff: 'Editar folga',
      newTimeOff: 'Nova folga',
      timeOffDescription: 'Bloqueie períodos específicos em que você não está disponível, garantindo que nenhum compromisso seja agendado nesses horários.',
      startTimeRequired: 'O horário de início é obrigatório',
      startTimeInvalid: 'O horário de início deve ser anterior ao horário de término.',
      endTimeInvalid: 'O horário de término deve ser posterior ao horário de início.',
      endTimeRequired: 'O horário de término é obrigatório',
      timeOffCreatedSuccess: 'Folga criada com sucesso.',
      timeOffCreatedFailure: 'Erro ao criar a folga',
      timeOffUpdatedSuccess: 'Folga atualizada com sucesso.',
      timeOffUpdatedFailure: 'Erro ao atualizar a folga',
      titleOptional: 'Título (opcional)',
      TimeOffDeleteModalText: 'Tem certeza de que deseja excluir o tempo livre?',
      TimeOffDeleted: 'Tempo livre excluído com sucesso',
    },
  },
};
export default resources;
