import { handleActions } from 'redux-actions';
import {
  getUserAccountRequest,
  getUserAccountSuccess,
  getUserAccountError,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailError,
  getUserLocationRequest,
  getUserLocationSuccess,
  getUserLocationError,
  verifyPhoneRequest,
  verifyPhoneSuccess,
  verifyPhoneError,
  savePersonalDetailsRequest,
  savePersonalDetailsSuccess,
  savePersonalDetailsError,
  saveBusinessDetailsRequest,
  saveBusinessDetailsSuccess,
  saveBusinessDetailsError,
  finishCompanyOnBoardingRequest,
  finishCompanyOnBoardingSuccess,
  finishCompanyOnBoardingError,
  createNewRoleAccountError,
  createNewRoleAccountRequest,
  createNewRoleAccountSuccess,
  switchToAccountRequest,
  switchToAccountSuccess,
  switchToAccountError,
  updateUserAccountRequest,
  updateUserAccountSuccess,
  updateUserAccountError,
  updateAccountAvatarRequest,
  updateAccountAvatarSuccess,
  updateAccountAvatarError,
  finishIndividualOnBoardingRequest,
  finishIndividualOnBoardingSuccess,
  finishIndividualOnBoardingError,
  sendVerificationCodeRequest,
  sendVerificationCodeSuccess,
  sendVerificationCodeError,
  sendVerificationLinkRequest,
  sendVerificationLinkSuccess,
  sendVerificationLinkError,
  resetNotificationsRequest,
  addAccountTypeRequest,
  addAccountTypeSuccess,
  addAccountTypeError,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  getUserSettingsRequest,
  getUserSettingsSuccess,
  getUserSettingsError,
  updateUserSettingsRequest,
  updateUserSettingsSuccess,
  getUserAccountNotificationsRequest,
  getUserAccountNotificationsSuccess,
  getUserAccountNotificationsError,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleError,
  syncGoogleCalendarRequest,
  syncGoogleCalendarSuccess,
  syncGoogleCalendarError,
  authenticateGoogleAccountRequest,
  authenticateGoogleAccountSuccess,
  authenticateGoogleAccountError,
  updateUserAccountInGlobalScope,
  getAvatarPreSignUrlRequest,
  getAvatarPreSignUrlSuccess,
  getAvatarPreSignUrlError,
  uploadAvatarToAmazonRequest,
  uploadAvatarToAmazonSuccess,
  uploadAvatarToAmazonError,
  checkUnsubscribeTokenExistRequest,
  checkUnsubscribeTokenExistSuccess,
  checkUnsubscribeTokenExistError,
  unsubscribeRequest,
  unsubscribeSuccess,
  unsubscribeError,
} from './actions';

const initialState = {
  isGetUserAccountSuccess: false,
  isGetUserAccountError: false,
  userAccount: {},
  isUpdateUserAccountSuccess: false,
  isUpdateUserAccountError: false,
  personalDetailsErrors: {},
  isUpdateAccountAvatarSuccess: false,
  isUpdateAccountAvatarError: false,
  accountAvatar: '',
  updateUserAccountErrors: {},
  updateAccountAvatarErrors: {},
  isVerifyEmailSuccess: false,
  isVerifyEmailError: false,
  verifyEmailErrors: {},
  isGetUserLocationSuccess: false,
  isGetUserLocationError: false,
  locationInfo: {},
  isVerifyPhoneSuccess: false,
  isVerifyPhoneError: false,
  isSavePersonalDetailsSuccess: false,
  isSavePersonalDetailsError: false,
  isSaveBusinessDetailsSuccess: false,
  isSaveBusinessDetailsError: false,
  businessDetailsErrors: [],
  isFinishCompanyOnBoardingSuccess: false,
  isFinishCompanyOnBoardingError: false,
  finishCompanyOnBoardingErrorMessage: '',
  isCreateNewRoleAccountSuccess: false,
  isCreateNewRoleAccountError: false,
  createNewRoleAccountErrorMessage: '',
  newPermissions: {},
  isSwitchToAccountSuccess: false,
  isSwitchToAccountError: false,
  switchToAccountErrorMessage: '',
  isFinishIndividualOnBoardingSuccess: false,
  isFinishIndividualOnBoardingError: false,
  finishIndividualOnBoardingErrorMessage: '',
  isSendVerificationCodeSuccess: false,
  isSendVerificationCodeError: false,
  sendVerificationCodeErrorMessage: '',
  verificationCodeSentMessage: '',
  isSendVerificationLinkSuccess: false,
  isSendVerificationLinkError: false,
  sendVerificationLinkErrorMessage: '',
  isForgotPasswordSuccess: false,
  isForgotPasswordCodeError: false,
  forgotPasswordErrorMessage: '',
  reSendEmailCodeSuccess: false,
  reSendEmailCodeError: false,
  reSendEmailCodeErrorMessage: '',
  confirmEmailCodeSuccess: false,
  confirmEmailCodeError: false,
  confirmEmailCodeErrorMessage: '',
  verificationLinkSentMessage: '',
  isResetNotificationsSuccess: false,
  isResetNotificationsError: false,
  isAddAccountTypeSuccess: false,
  isAddAccountTypeError: false,
  addAccountTypeErrorMessage: '',
  isChangePasswordSuccess: false,
  isChangePasswordError: false,
  changePasswordErrorMessage: '',
  isGetUserSettingsSuccess: false,
  isGetUserSettingsError: false,
  getUserSettingsErrorMessage: '',
  userSettings: {},
  isUpdateUserSettingsSuccess: false,
  isUpdateUserSettingsError: false,
  updateUserSettingsErrorMessage: '',
  isGetUserAccountNotificationsSuccess: false,
  isGetUserAccountNotificationsError: false,
  accountNotifications: [],
  notificationsCount: 0,
  isGetDeactivateRoleSuccess: false,
  isGetDeactivateRoleError: false,
  isSyncGoogleCalendarSuccess: false,
  isSyncGoogleCalendarError: false,
  oauthUrl: '',
  isAuthenticateGoogleAccountSuccess: false,
  isAuthenticateGoogleAccountError: false,
  isGetAvatarPreSignedUrlSuccess: false,
  isGetAvatarPreSignedUrlError: false,
  avatarPreSignUrl: '',
  isUploadAvatarToAmazonSuccess: false,
  isUploadAvatarToAmazonError: false,
  isCheckUnsubscribeTokenExistSuccess: false,
  isCheckUnsubscribeTokenExistError: false,
  unsubscribeErrors: {},
  isUnsubscribeSuccess: false,
  isUnsubscribeError: false,
  isGetUserCountrySuccess: false,
  isGetUserCountryError: false,
  userCountryInfo: {},
};

const reducer = handleActions(
  {
    [getUserAccountRequest]: (state) => ({
      ...state,
      userAccount: {},
      isGetUserAccountSuccess: false,
      isGetUserAccountError: false,
    }),
    [getUserAccountSuccess]: (state, { payload }) => ({
      ...state,
      userAccount: payload.data,
      isGetUserAccountSuccess: true,
      isGetUserAccountError: false,
    }),
    [getUserAccountError]: (state) => ({
      ...state,
      isGetUserAccountSuccess: false,
      isGetUserAccountError: true,
      userAccount: {},
    }),
    [updateUserAccountRequest]: (state) => ({
      ...state,
      isUpdateUserAccountSuccess: false,
      isUpdateUserAccountError: false,
    }),
    [updateUserAccountSuccess]: (state, { payload }) => ({
      ...state,
      userAccount: payload.data,
      isUpdateUserAccountSuccess: true,
      isUpdateUserAccountError: false,
    }),
    [updateUserAccountError]: (state, { payload }) => ({
      ...state,
      isUpdateUserAccountSuccess: false,
      isUpdateUserAccountError: true,
      updateUserAccountErrors: payload.errors,
    }),
    [updateAccountAvatarRequest]: (state) => ({
      ...state,
      isUpdateAccountAvatarSuccess: false,
      isUpdateAccountAvatarError: false,
      accountAvatar: '',
      updateAccountAvatarErrors: {},
    }),
    [updateAccountAvatarSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateAccountAvatarSuccess: true,
      isUpdateAccountAvatarError: false,
      accountAvatar: payload.data.avatar,
      updateAccountAvatarErrors: {},
    }),
    [updateAccountAvatarError]: (state, { payload }) => ({
      ...state,
      isUpdateAccountAvatarSuccess: false,
      isUpdateAccountAvatarError: true,
      accountAvatar: '',
      updateAccountAvatarErrors: payload.errors,
    }),
    [verifyEmailRequest]: (state) => ({
      ...state,
      isVerifyEmailSuccess: false,
      isVerifyEmailError: false,
      verifyEmailErrors: {},
    }),
    [verifyEmailSuccess]: (state, { payload }) => ({
      ...state,
      userAccount: payload,
      isVerifyEmailSuccess: true,
      isVerifyEmailError: false,
      verifyEmailErrors: {},
    }),
    [verifyEmailError]: (state, { payload }) => ({
      ...state,
      isVerifyEmailSuccess: false,
      isVerifyEmailError: true,
      verifyEmailErrors: payload.errors,
    }),
    [getUserLocationRequest]: (state) => ({
      ...state,
      isGetUserLocationSuccess: false,
      isGetUserAccountError: false,
      locationInfo: {},
    }),
    [getUserLocationSuccess]: (state, { payload }) => ({
      ...state,
      isGetUserLocationSuccess: true,
      isGetUserLocationError: false,
      locationInfo: payload,
    }),
    [getUserLocationError]: (state) => ({
      ...state,
      isGetUserLocationSuccess: false,
      isGetUserLocationError: true,
      locationInfo: {},
    }),
    [verifyPhoneRequest]: (state) => ({
      ...state,
      isVerifyPhoneSuccess: false,
      isVerifyPhoneError: false,
    }),
    [verifyPhoneSuccess]: (state) => ({
      ...state,
      isVerifyPhoneSuccess: true,
      isVerifyPhoneError: false,
    }),
    [verifyPhoneError]: (state) => ({
      ...state,
      isVerifyPhoneSuccess: false,
      isVerifyPhoneError: true,
    }),
    [savePersonalDetailsRequest]: (state) => ({
      ...state,
      isSavePersonalDetailsSuccess: false,
      isSavePersonalDetailsError: false,
    }),
    [savePersonalDetailsSuccess]: (state, { payload }) => ({
      ...state,
      isSavePersonalDetailsSuccess: true,
      isSavePersonalDetailsError: false,
      userAccount: payload.data,
    }),
    [savePersonalDetailsError]: (state, { payload }) => ({
      ...state,
      isSavePersonalDetailsSuccess: false,
      isSavePersonalDetailsError: true,
      personalDetailsErrors: payload.errors,
    }),
    [saveBusinessDetailsRequest]: (state) => ({
      ...state,
      isSaveBusinessDetailsSuccess: false,
      isSaveBusinessDetailsError: false,
      businessDetailsErrorMessage: '',
      userAccount: {},
    }),
    [saveBusinessDetailsSuccess]: (state, { payload }) => ({
      ...state,
      isSaveBusinessDetailsSuccess: true,
      isSaveBusinessDetailsError: false,
      userAccount: payload.data,
    }),
    [saveBusinessDetailsError]: (state, { payload }) => ({
      ...state,
      isSaveBusinessDetailsSuccess: false,
      isSaveBusinessDetailsError: true,
      businessDetailsErrorMessage: payload.message,
    }),
    [finishCompanyOnBoardingRequest]: (state) => ({
      ...state,
      isFinishCompanyOnBoardingSuccess: false,
      isFinishCompanyOnBoardingError: false,
      finishCompanyOnBoardingErrorMessage: '',
    }),
    [finishCompanyOnBoardingSuccess]: (state) => ({
      ...state,
      isFinishCompanyOnBoardingSuccess: true,
      isFinishCompanyOnBoardingError: false,
      finishCompanyOnBoardingErrorMessage: '',
    }),
    [finishCompanyOnBoardingError]: (state, { payload }) => ({
      ...state,
      isFinishCompanyOnBoardingSuccess: false,
      isFinishCompanyOnBoardingError: true,
      finishCompanyOnBoardingErrorMessage: payload.data,
    }),
    [createNewRoleAccountRequest]: (state) => ({
      ...state,
      isCreateNewRoleAccountSuccess: false,
      isCreateNewRoleAccountError: false,
      createNewRoleAccountErrorMessage: '',
      newPermissions: {},
    }),
    [createNewRoleAccountSuccess]: (state, { payload }) => ({
      ...state,
      isCreateNewRoleAccountSuccess: true,
      isCreateNewRoleAccountError: false,
      createNewRoleAccountErrorMessage: '',
      newPermissions: payload.data,
    }),
    [createNewRoleAccountError]: (state, { payload }) => ({
      ...state,
      isCreateNewRoleAccountSuccess: false,
      isCreateNewRoleAccountError: true,
      createNewRoleAccountErrorMessage: payload.data.message,
      newPermissions: {},
    }),
    [switchToAccountRequest]: (state) => ({
      ...state,
      isSwitchToAccountSuccess: false,
      isSwitchToAccountError: false,
      switchToAccountErrorMessage: '',
    }),
    [switchToAccountSuccess]: (state) => ({
      ...state,
      isSwitchToAccountSuccess: true,
      isSwitchToAccountError: false,
      switchToAccountErrorMessage: '',
    }),
    [switchToAccountError]: (state, { payload }) => ({
      ...state,
      isSwitchToAccountSuccess: false,
      isSwitchToAccountError: true,
      switchToAccountErrorMessage: payload.data.message,
    }),
    [finishIndividualOnBoardingRequest]: (state) => ({
      ...state,
      isFinishIndividualOnBoardingSuccess: false,
      isFinishIndividualOnBoardingError: false,
      finishIndividualOnBoardingErrorMessage: '',
    }),
    [finishIndividualOnBoardingSuccess]: (state) => ({
      ...state,
      isFinishIndividualOnBoardingSuccess: true,
      isFinishIndividualOnBoardingError: false,
      finishIndividualOnBoardingErrorMessage: '',
    }),
    [finishIndividualOnBoardingError]: (state, { payload: { message } }) => ({
      ...state,
      isFinishIndividualOnBoardingSuccess: false,
      isFinishIndividualOnBoardingError: true,
      finishIndividualOnBoardingErrorMessage: message,
    }),
    [sendVerificationCodeRequest]: (state) => ({
      ...state,
      isSendVerificationCodeSuccess: false,
      isSendVerificationCodeError: false,
      verificationCodeSentMessage: '',
    }),
    [sendVerificationCodeSuccess]: (state, { payload }) => ({
      ...state,
      isSendVerificationCodeSuccess: true,
      isSendVerificationCodeError: false,
      verificationCodeSentMessage: payload,
    }),
    [sendVerificationCodeError]: (state, { payload: { message } }) => ({
      ...state,
      isSendVerificationCodeSuccess: false,
      isSendVerificationCodeError: true,
      sendVerificationCodeErrorMessage: message,
    }),
    [sendVerificationLinkRequest]: (state) => ({
      ...state,
      isSendVerificationLinkSuccess: false,
      isSendVerificationLinkError: false,
      verificationLinkSentMessage: '',
    }),
    [sendVerificationLinkSuccess]: (state, { payload }) => ({
      ...state,
      isSendVerificationLinkSuccess: true,
      isSendVerificationLinkError: false,
      verificationLinkSentMessage: payload,
    }),
    [sendVerificationLinkError]: (state, { payload: { message } }) => ({
      ...state,
      isSendVerificationLinkSuccess: false,
      isSendVerificationLinkError: true,
      sendVerificationLinkErrorMessage: message,
    }),
    [resetNotificationsRequest]: (state, { payload }) => ({
      ...state,
      accountNotifications: [...payload],
    }),
    [addAccountTypeRequest]: (state) => ({
      ...state,
      isAddAccountTypeSuccess: false,
      isAddAccountTypeError: false,
      addAccountTypeErrorMessage: '',
    }),
    [addAccountTypeSuccess]: (state, { payload }) => ({
      ...state,
      userAccount: {
        ...state.userAccount,
        activeRole: payload.data,
      },
      isAddAccountTypeSuccess: true,
      isAddAccountTypeError: false,
      addAccountTypeErrorMessage: '',
    }),
    [addAccountTypeError]: (state, { payload }) => ({
      ...state,
      isAddAccountTypeSuccess: false,
      isAddAccountTypeError: true,
      addAccountTypeErrorMessage: payload.message,
    }),
    [changePasswordRequest]: (state) => ({
      ...state,
      isChangePasswordSuccess: false,
      isChangePasswordError: false,
      changePasswordErrorMessage: '',
    }),
    [changePasswordSuccess]: (state) => ({
      ...state,
      isChangePasswordSuccess: true,
      isChangePasswordError: false,
      changePasswordErrorMessage: '',
    }),
    [changePasswordError]: (state, { payload }) => ({
      ...state,
      isChangePasswordSuccess: false,
      isChangePasswordError: true,
      changePasswordErrorMessage: payload.message,
    }),
    [getUserSettingsRequest]: (state) => ({
      ...state,
      isGetUserSettingsSuccess: false,
      isGetUserSettingsError: false,
      getUserSettingsErrorMessage: '',
      userSettings: {},
    }),
    [getUserSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetUserSettingsSuccess: true,
      isGetUserSettingsError: false,
      getUserSettingsErrorMessage: '',
      userSettings: payload.data,
    }),
    [getUserSettingsError]: (state, { payload }) => ({
      ...state,
      isGetUserSettingsSuccess: false,
      isGetUserSettingsError: true,
      getUserSettingsErrorMessage: payload.message,
      userSettings: {},
    }),
    [updateUserSettingsRequest]: (state) => ({
      ...state,
      isUpdateUserSettingsSuccess: false,
      isUpdateUserSettingsError: false,
      updateUserSettingsErrorMessage: '',
    }),
    [updateUserSettingsSuccess]: (state) => ({
      ...state,
      isUpdateUserSettingsSuccess: true,
      isUpdateUserSettingsError: false,
      updateUserSettingsErrorMessage: '',
    }),
    [updateUserAccountError]: (state, { payload }) => ({
      ...state,
      isUpdateUserSettingsSuccess: false,
      isUpdateUserSettingsError: true,
      updateUserSettingsErrorMessage: payload.message,
    }),
    [getUserAccountNotificationsRequest]: (state) => ({
      ...state,
      accountNotifications: [],
      notificationsCount: 0,
      isGetUserAccountNotifications: true,
      isGetUserAccountNotificationsSuccess: false,
      isGetUserAccountNotificationsError: false,
    }),
    [getUserAccountNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      accountNotifications: payload.data,
      notificationsCount: payload.total,
      isGetUserAccountNotifications: false,
      isGetUserAccountNotificationsSuccess: true,
      isGetUserAccountNotificationsError: false,
    }),
    [getUserAccountNotificationsError]: (state) => ({
      ...state,
      accountNotifications: [],
      notificationsCount: 0,
      isGetUserAccountNotifications: false,
      isGetUserAccountNotificationsSuccess: false,
      isGetUserAccountNotificationsError: true,
    }),
    [deactivateRoleRequest]: (state) => ({
      ...state,
      isGetDeactivateRoleSuccess: false,
      isGetDeactivateRoleError: false,
    }),
    [deactivateRoleSuccess]: (state) => ({
      ...state,
      isGetDeactivateRoleSuccess: true,
      isGetDeactivateRoleError: false,
    }),
    [deactivateRoleError]: (state) => ({
      ...state,
      isGetDeactivateRoleSuccess: false,
      isGetDeactivateRoleError: true,
    }),
    [syncGoogleCalendarRequest]: (state) => ({
      ...state,
      isSyncGoogleCalendarSuccess: false,
      isSyncGoogleCalendarError: false,
      oauthUrl: '',
    }),
    [syncGoogleCalendarSuccess]: (state, { payload }) => ({
      ...state,
      isSyncGoogleCalendarSuccess: true,
      isSyncGoogleCalendarError: false,
      oauthUrl: payload,
    }),
    [syncGoogleCalendarError]: (state) => ({
      ...state,
      isSyncGoogleCalendarSuccess: false,
      isSyncGoogleCalendarError: true,
      oauthUrl: '',
    }),
    [authenticateGoogleAccountRequest]: (state) => ({
      ...state,
      isAuthenticateGoogleAccountSuccess: false,
      isAuthenticateGoogleAccountError: false,
    }),
    [authenticateGoogleAccountSuccess]: (state) => ({
      ...state,
      isAuthenticateGoogleAccountSuccess: true,
      isAuthenticateGoogleAccountError: false,
    }),
    [authenticateGoogleAccountError]: (state) => ({
      ...state,
      isAuthenticateGoogleAccountSuccess: false,
      isAuthenticateGoogleAccountError: true,
    }),
    [updateUserAccountInGlobalScope]: (state, { payload }) => ({
      ...state,
      userAccount: { ...state.userAccount, ...payload },
    }),
    [getAvatarPreSignUrlRequest]: (state) => ({
      ...state,
      isGetAvatarPreSignedUrlSuccess: false,
      isGetAvatarPreSignedUrlError: false,
      avatarPreSignUrl: '',
    }),
    [getAvatarPreSignUrlSuccess]: (state, { payload }) => ({
      ...state,
      isGetAvatarPreSignedUrlSuccess: true,
      isGetAvatarPreSignedUrlError: false,
      avatarPreSignUrl: payload.url,
    }),
    [getAvatarPreSignUrlError]: (state) => ({
      ...state,
      isGetAvatarPreSignedUrlSuccess: false,
      isGetAvatarPreSignedUrlError: true,
      avatarPreSignUrl: '',
    }),
    [uploadAvatarToAmazonRequest]: (state) => ({
      ...state,
      isUploadAvatarToAmazonSuccess: false,
      isUploadAvatarToAmazonError: false,
    }),
    [uploadAvatarToAmazonSuccess]: (state) => ({
      ...state,
      isUploadAvatarToAmazonSuccess: true,
      isUploadAvatarToAmazonError: false,
    }),
    [uploadAvatarToAmazonError]: (state) => ({
      ...state,
      isUploadAvatarToAmazonSuccess: false,
      isUploadAvatarToAmazonError: true,
    }),
    [checkUnsubscribeTokenExistRequest]: (state) => ({
      ...state,
      isCheckUnsubscribeTokenExistSuccess: false,
      isCheckUnsubscribeTokenExistError: false,
      unsubscribeErrors: {},
    }),
    [checkUnsubscribeTokenExistSuccess]: (state) => ({
      ...state,
      isCheckUnsubscribeTokenExistSuccess: true,
      isCheckUnsubscribeTokenExistError: false,
      unsubscribeErrors: {},
    }),
    [checkUnsubscribeTokenExistError]: (state, { payload }) => ({
      ...state,
      isCheckUnsubscribeTokenExistSuccess: false,
      isCheckUnsubscribeTokenExistError: true,
      unsubscribeErrors: payload.errors,
    }),
    [unsubscribeRequest]: (state) => ({
      ...state,
      isUnsubscribeSuccess: false,
      isUnsubscribeError: false,
    }),
    [unsubscribeSuccess]: (state) => ({
      ...state,
      isUnsubscribeSuccess: true,
      isUnsubscribeError: false,
    }),
    [unsubscribeError]: (state) => ({
      ...state,
      isUnsubscribeSuccess: false,
      isUnsubscribeError: true,
    }),
  },
  initialState,
);

export default reducer;
