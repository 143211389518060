import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Box, Button,
  FormControl,
  Grid, IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from 'assets/img/logo.svg';
import usePrevious from '../../../../CustomHooks/usePrevious';
import { changePasswordRequest } from '../../../../redux/account/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function SetNewPassword(props) {
  const history = useHistory();

  const {
    user,
    changePassword,
    isChangePasswordSuccess,
    isChangePasswordError,
    changePasswordErrorMessage,
  } = props;
  const prevIsChangePasswordSuccess = usePrevious(isChangePasswordSuccess);
  const prevIsChangePasswordError = usePrevious(isChangePasswordError);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    newPassword: false,
    newPasswordLength: false,
    newPasswordFormat: false,
    confirmPassword: false,
    passwordsMatch: false,
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Change password request
  useEffect(() => {
    if (prevIsChangePasswordSuccess === false && isChangePasswordSuccess) {
      snackBarAlert(true, 'Password changed', 'success');
      setLoading(false);
      history.push('/onboarding');
    } else if (prevIsChangePasswordError === false && isChangePasswordError) {
      snackBarAlert(true, changePasswordErrorMessage, 'error');
      history.push('/login');
    }
  }, [isChangePasswordSuccess, isChangePasswordError]);

  const validateForm = () => {
    const {
      newPassword,
      confirmPassword,
    } = data;

    const formErrorsCopy = { ...formErrors };

    formErrorsCopy.newPassword = !(newPassword.length > 0);
    formErrorsCopy.newPasswordLength = !(newPassword.length >= 8);
    formErrorsCopy.newPasswordFormat = !(newPassword.match(/(?=.*[A-Z])/));

    formErrorsCopy.confirmPassword = !(confirmPassword.length > 0);
    formErrorsCopy.passwordsMatch = !(confirmPassword === newPassword);

    const keys = Object.keys(formErrorsCopy);
    setFormErrors(formErrorsCopy);

    return keys.findIndex((key) => formErrorsCopy[key]) === -1;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      changePassword({
        password: data.newPassword,
        password_confirmation: data.confirmPassword,
        old_password: user.old_password,
      });
    }
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      <div className="auth">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <img src={Logo} alt="Logo" />
              </Box>
            </Toolbar>
          </AppBar>
          <Grid container justify="center">
            <Grid direction="row" justify="center" xs={12} sm={7} md={4}>
              <div className="auth-box">
                <Box px="40px" py="30px">
                  <Box mb="30px">
                    <Typography align="center" variant="h4">Set your password</Typography>
                  </Box>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <Box pt="15px" pb="7px">
                        <Typography align="left" color="secondary" className="accept-checkbox">
                          Your password must contain at least 8 characters and capital letter.
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                            <OutlinedInput
                              type={showPassword ? 'text' : 'password'}
                              id="password-icon"
                              name="newPassword"
                              label="New password"
                              value={data.newPassword}
                              onChange={handleInputChange}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />
                          </FormControl>
                          { formErrors.newPassword && <p data-cy="required-pass-message" className="error-message"> New password is required </p> }
                          { formErrors.newPasswordLength && !formErrors.newPassword && <p data-cy="short-pass-message" className="error-message"> Password must contain at least 8 characters </p> }
                          { formErrors.newPasswordFormat && !formErrors.newPassword && !formErrors.newPasswordLength && <p className="error-message"> Password must be contain least 1 capital letter</p> }
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
                            <OutlinedInput
                              type={showConfirmPassword ? 'text' : 'password'}
                              id="icon-password"
                              name="confirmPassword"
                              label="Confirm password"
                              value={data.confirmPassword}
                              onChange={handleInputChange}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />
                          </FormControl>
                          { formErrors.confirmPassword && <p data-cy="required-confirmPass-message" className="error-message"> Confirm password is required </p> }
                          { !formErrors.confirmPassword && formErrors.passwordsMatch && <p data-cy="required-confirmPass-message" className="error-message"> Password confirmation does not match </p> }
                        </Grid>
                      </Grid>
                    </div>
                    <div />
                    <Box my="30px" align="center">
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

SetNewPassword.propTypes = {
  user: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  isChangePasswordSuccess: PropTypes.bool.isRequired,
  isChangePasswordError: PropTypes.bool.isRequired,
  changePasswordErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isChangePasswordSuccess: state.account.isChangePasswordSuccess,
  isChangePasswordError: state.account.isChangePasswordError,
  changePasswordErrorMessage: state.account.changePasswordErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePasswordRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
