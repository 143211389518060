import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  AppBar,
  TextField,
  Box,
  Grid,
  Button,
  Toolbar,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import i18n from 'i18next';
import moment from 'moment';
import 'moment-timezone';

import Logo from 'assets/img/logo.svg';
import countryCodeOptions from '../../../../Modules/countryCodeOptions';
import { saveBusinessDetailsRequest } from '../../../../redux/account/actions';
import { getIndustriesRequest } from '../../../../redux/industry/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

// constants
const momentTimezones = moment.tz.names();

const muiFilter = createFilterOptions();

const initialUserMetaData = {
  // TODO: Change this value to web and add it to api
  device: 'android',
  access_token: null,
  user_agent: null,
  device_info: null,
};

const initialProviderDetails = {
  minNameLength: false,
  address: '',
  phoneNumber: '',
  accountType: 'business',
};

const initialFormErrors = {
  name: false,
  industry: false,
  country: false,
  timezone: false,
};

const validators = {
  name: (businessName) => businessName.length === 0,
  industry: (industry) => !industry.value,
  country: (country) => [null, undefined].includes(country.id),
  timezone: (timezone) => timezone === '',
};

// helper functions
const createPayload = (
  userAccount,
  businessName,
  industryOption,
  countryOption,
  timezoneOption,
  isStaffBased,
) => {
  return {
    provider: {
      name: businessName.trim(),
      minNameLength: initialProviderDetails.minNameLength,
      address: initialProviderDetails.address,
      phone_number: initialProviderDetails.phoneNumber,
      account_type: initialProviderDetails.accountType,
      industry_id: industryOption.id ?? '',
      industry_name: !industryOption.id ? industryOption.value : '',
      country_id: countryOption.id,
      is_staff_based: isStaffBased,
      timezone: timezoneOption.split(')').at(-1),
    },
    user: {
      ...initialUserMetaData,
      id: userAccount.id,
      first_name: userAccount.first_name,
      last_name: userAccount.last_name,
      password: userAccount.password,
      email: userAccount.email,
      from_social: userAccount.from_social,
    },
  };
};

const filterIndustries = (options, params) => {
  const filtered = muiFilter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      inputValue: params.inputValue,
      label: `Add "${params.inputValue}"`,
      value: params.inputValue,
    });
  }

  return filtered;
};

const getIndustryOptions = (option) => {
  if (option.inputValue) {
    return option.inputValue;
  }
  return option.label;
};

const renderIndustryOption = (option) => (
  <div className="input-menu" key={option.id} value={option.value}>
    {option.label}
  </div>
);

const snackBarAlert = (setter, SnackMessage, SnackType) => {
  setter({
    isOpen: true,
    type: SnackType,
    message: SnackMessage,
  });
};

// Component
const Index = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userAccount, countries } = props;

  // Redux states
  const {
    isSaveBusinessDetailsSuccess,
    isSaveBusinessDetailsError,
    businessDetailsErrorMessage,
    locationInfo,
  } = useSelector((state) => state.account);

  const {
    isGetIndustriesSuccess,
    isGetIndustriesError,
    industries,
  } = useSelector((state) => state.industry);

  // States
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({ ...initialFormErrors });
  const [changedField, setChangedField] = useState('');

  const [countryOptions, setCountryOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  const [isStaffBased, setIsStaffBased] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = useState('');

  const [snackBar, setSnackBar] = useState({ isOpen: false, type: '', message: '' });

  const handleIndustrySelectChange = (event, industryName) => {
    const selectedIndustry = industryOptions.find((item) => item.value === industryName);

    if (selectedIndustry && selectedIndustry.id) {
      setSelectedIndustryOption({
        id: selectedIndustry.id,
        label: selectedIndustry.label,
        value: selectedIndustry.value,
      });
    } else {
      setSelectedIndustryOption({
        id: '',
        label: industryName,
        value: industryName,
      });
    }
  };

  const handleCountrySelectChange = (event, selectedCountry) => {
    if (selectedCountry && selectedCountry.id) {
      setSelectedCountryOption(selectedCountry);
    } else {
      setSelectedCountryOption({});
    }
  };

  const handleTimeZoneSelectChange = (e, selectedTimeZone) => {
    if (selectedTimeZone) {
      setSelectedTimeZoneOption(selectedTimeZone);
    } else {
      setSelectedTimeZoneOption('');
    }
  };

  const isFormInvalid = (field) => {
    if (field) {
      field = field.trim();
    }

    const formErrorsCopy = { ...formErrors };

    const fields = {
      name: businessName,
      industry: selectedIndustryOption,
      country: selectedCountryOption,
      timezone: selectedTimeZoneOption,
    };

    for (const [key, val] of (field ? Object.entries({ [field]: fields[field] }) : Object.entries(fields))) {
      formErrorsCopy[key] = validators[key](val);
    }

    setFormErrors(formErrorsCopy);
    return Object.values(formErrorsCopy).some((error) => error === true);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!isFormInvalid()) {
      setLoading(true);
      dispatch(saveBusinessDetailsRequest(createPayload(
        userAccount,
        businessName,
        selectedIndustryOption,
        selectedCountryOption,
        selectedTimeZoneOption,
        isStaffBased,
      )));
    }
  };

  const changeField = (e, additionalData, field) => {
    const handlers = {
      name: (e) => setBusinessName(e.target.value),
      industry: handleIndustrySelectChange,
      country: handleCountrySelectChange,
      timezone: handleTimeZoneSelectChange,
    };

    handlers[field](e, additionalData);
    setChangedField((c) => c === field ? ` ${field} ` : field);
  };

  useEffect(() => {
    const timeZoneNamesList = [];

    momentTimezones.forEach((item, i) => timeZoneNamesList.push(
      `(GMT${moment.tz(momentTimezones[i]).format('Z')})${momentTimezones[i]}`,
    ));

    setTimeZoneOptions(timeZoneNamesList);
  }, []);

  useEffect(() => {
    if (changedField) {
      isFormInvalid(changedField);
    }
  }, [changedField]);

  useEffect(() => {
    dispatch(getIndustriesRequest());
    const options = countryCodeOptions.getCountryOptions(countries);
    setCountryOptions(options);

    const realCountry = locationInfo?.real_country ?? sessionStorage.getItem('realCountry');
    const selectedByReal = options.find((option) => option.label === realCountry);

    if (selectedByReal) {
      setSelectedCountryOption(selectedByReal);

      const timezoneOption = timeZoneOptions.find((item) => item.includes(selectedByReal.capital));
      setSelectedTimeZoneOption(timezoneOption);
    }
  }, [countries]);

  useEffect(() => {
    if (isSaveBusinessDetailsSuccess) {
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', userAccount.token);
      }

      if (sessionStorage.getItem('currentVerificationEmail')) {
        sessionStorage.removeItem('currentVerificationEmail');
      }

      window.location.reload();
    } else if (isSaveBusinessDetailsError) {
      setLoading(false);
      snackBarAlert(setSnackBar, businessDetailsErrorMessage, 'error');
    }
  }, [isSaveBusinessDetailsSuccess, isSaveBusinessDetailsError]);

  useEffect(() => {
    if (isGetIndustriesSuccess) {
      const industryOptions = countryCodeOptions.getIndustryOptions(industries);
      setIndustryOptions(industryOptions);
    } else if (isGetIndustriesError) {
      snackBarAlert(setSnackBar, t('IndustriesNotSaved'), 'error');
    }
  }, [isGetIndustriesSuccess, isGetIndustriesError]);

  return (
    <div className="auth page-base">
      <Container>
        <AppBar color="transparent" position="static" elevation={0} className="login-header">
          <Toolbar>
            <Box mx="auto" className="auth-logo">
              <Link to="/login">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        <div className="form-container" style={{ marginTop: 0 }}>
          <Box px="40px" py="30px" className="form-box auth-box">
            <Link to="/register">
              <KeyboardBackspaceRoundedIcon style={{ color: '#393939' }} />
            </Link>
            <p className="title">
              {t('businessInfo')}
            </p>
            <Grid container spacing={2} alignItems="center" className="business-container">
              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('whatIsTypeBusiness')}</FormLabel>
                  <RadioGroup
                    aria-label={t('whatIsTypeBusiness')}
                    name="business_type"
                    value={Number(isStaffBased).toString()}
                    onChange={(e) => setIsStaffBased(Boolean(Number(e.target.value)))}
                  >
                    <FormControlLabel value="0" control={<Radio color="primary" />} label={t('anIndividual')} />
                    <p className="sub-title" style={{ marginBottom: 0, textAlign: 'left' }}>
                      {t('individualImageDesc')}
                    </p>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label={t('Company')} />
                    <p className="sub-title" style={{ marginBottom: 0, textAlign: 'left' }}>
                      {t('companyImageDesc')}
                    </p>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid style={{ marginTop: '10px' }} item xs={12} sm={12}>
                <FormControl fullWidth variant="outlined" margin="dense" error={!!formErrors.name}>
                  <TextField
                    fullWidth
                    autoFocus
                    error={!!formErrors.name}
                    id="name"
                    name="name"
                    value={businessName}
                    onChange={(e, data) => changeField(e, data, 'name')}
                    label={t('businessName')}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      autoComplete: 'new-password',
                      maxLength: 60,
                    }}
                  />

                  {formErrors.name && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{t('CompanyName') + t('IsRequired')}</FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" margin="dense" error={!!formErrors.industry}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    freeSolo
                    options={industryOptions}
                    getOptionLabel={getIndustryOptions}
                    filterOptions={filterIndustries}
                    renderOption={renderIndustryOption}
                    onInputChange={(e, data) => changeField(e, data, 'industry')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('chooseOrCreateIndustry')}
                        variant="outlined"
                        error={!!formErrors.industry}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  {formErrors.industry && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{t('Industry') + t('IsRequired')}</FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" margin="dense" error={!!formErrors.country}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    value={selectedCountryOption}
                    onChange={(e, data) => changeField(e, data, 'country')}
                    options={countryOptions}
                    getOptionLabel={(option) => option.label || ''}
                    renderOption={(option) => (
                      <div className="input-menu" key={option.id} value={option.id}>
                        {option.label}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label={t('Country')}
                        variant="outlined"
                        name="country"
                        error={!!formErrors.country}
                      />
                    )}
                  />

                  {formErrors.country && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{t('Country') + t('IsRequired')}</FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" margin="dense" error={!!formErrors.timezone}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    onChange={(e, data) => changeField(e, data, 'timezone')}
                    value={selectedTimeZoneOption || ''}
                    options={timeZoneOptions}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <>
                        <span>(UTC)</span>
                        {option}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label={t('TimeZone')}
                        variant="outlined"
                        name="timezone"
                        error={!!formErrors.timezone}
                      />
                    )}
                  />
                  {formErrors.timezone && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>{t('TimeZone') + t('IsRequired')}</FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Box mt={2} width="100%">
                <Button
                  fullWidth
                  disabled={loading}
                  color="primary"
                  onClick={onSubmit}
                  variant="contained"
                  data-cy="submit"
                  type="submit"
                >
                  {loading && <CircularProgress color="white" size={20} />}
                  {!loading && i18n.t('signUp')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </div>
      </Container>
      <SnackbarToast
        message={snackBar.message}
        type={snackBar.type}
        open={snackBar.isOpen}
        onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
      />
    </div>
  );
};

Index.propTypes = {
  userAccount: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default Index;
