const resources = {
  it: {
    translation: {
      Accept: 'Accetta',
      Accepted: 'Accettato',
      AccountCreated: 'Account creato.',
      Actions: 'Opzioni',
      Add: 'Aggiungi',
      AddAppointment: 'Aggiungi Appuntamento',
      AddClosedDays: 'Aggiungi Giorni Chiusura',
      AddCompany: 'Aggiungi Azienda',
      AddCustomer: 'Aggiungi Cliente',
      AddDayOff: 'Aggiungi Giorni Liberi',
      AddNewCustomer: 'Aggiungi Nuovo Cliente',
      Address: 'Indirizzo',
      AddService: 'Aggiungi Servizio',
      AddShortDescOptional: 'Aggiungi una breve descrizione (opzionale)',
      AddSpecificWorkDays: 'Aggiungi Giorni Lavorativi Specifici',
      AddStaff: 'Aggiungi Personale',
      All: 'Tutti',
      AllAppointmentsCancelText: 'Tutti gli appuntamenti futuri verranno cancellati.',
      AlreadyHaveAccount: 'Hai già un account?',
      AMD: 'AMD',
      anIndividual: 'Un Privato',
      Apply: 'Applica',
      ApplyToAll: 'Applica a Tutti',
      'Appointment Count': 'Conteggio Appuntamenti',
      AppointmentAccepted: 'Appuntamento accettato con successo',
      AppointmentCanceled: 'Appuntamento cancellato con successo',
      AppointmentCancelModalText: 'Sei sicuro di voler cancellare il tuo appuntamento?',
      AppointmentCreated: 'Appuntamento creato con successo',
      AppointmentDeleted: 'Appuntamento eliminato con successo',
      AppointmentDeleteModalText: "L'appuntamento scomparirà dal calendario e non verrà considerato nelle Statistiche.",
      AppointmentDeleteQuestion: 'Sei sicuro di voler eliminare l\'appuntamento?',
      AppointmentDetails: 'Dettagli Appuntamento',
      AppointmentHistory: 'Cronologia Appuntamenti',
      AppointmentNotes: 'Non visibile ai clienti',
      AppointmentNotFiltered: 'Appuntamento non filtrato',
      AppointmentNotFound: 'Appuntamento non trovato.',
      AppointmentRescheduled: 'Appuntamento riprogrammato con successo',
      Appointments: 'Appuntamenti',
      AppointmentSaveDraft: 'Appuntamento salvato come bozza',
      AppointmentsHistory: 'Cronologia Appuntamenti',
      AreYouSureCancel: 'Sei sicuro di voler cancellare il',
      AreYouSureDelete: 'Sei sicuro di voler eliminare il',
      'Assigned Services': 'Servizi Assegnati',
      AtLeastOne: 'Almeno un ',
      AutoAccept: 'Accetta automaticamente gli appuntamenti',
      AutoAcceptLabel: 'Accettazione Automatica',
      AutoAcceptTooltipText: 'Gli appuntamenti ricevuti dalle prenotazioni online verranno accettati automaticamente.',
      AutoFinishLabel: 'Completamento Automatico',
      AutoFinish: 'Segna automaticamente come completati gli appuntamenti confermati dopo l\'orario di fine',
      AvailableHours: 'Orari disponibili',
      BookingLink: 'Link prenotazione',
      BookingUrl: 'URL prenotazione',
      BreakEndTimeAfter: 'L\'orario di fine pausa deve essere successivo all\'orario di inizio.',
      BreakEndTimeRequired: 'È richiesto l\'orario di fine pausa.',
      Breaking: 'Pausa',
      BreakStartTimeRequired: 'È richiesto l\'orario di inizio pausa.',
      BufferTime: 'Tempo di buffer',
      BufferTimeTooltipText: 'Aggiunge tempo extra dopo il servizio, non visibile all\'utente.',
      BusinessNotSaved: 'Dettagli azienda non salvati.',
      BusinessSaved: 'Dettagli azienda salvati.',
      businessInfo: 'Informazioni Azienda',
      businessName: 'Nome azienda',
      ByDate: 'Per Data',
      ByStatus: 'Per Stato',
      Calendar: 'Calendario',
      Category: 'Categoria',
      CategoryIsRequired: 'La categoria è obbligatoria',
      CalendarTimeSlot: 'Fascia oraria',
      Cancel: 'Annulla',
      CancelAllUpcomingAppointments: 'Annulla tutti gli appuntamenti futuri',
      CancelAppointment: 'Annulla Appuntamento',
      CancelInvitation: 'Annulla Invito',
      Cancelled: 'Annullato',
      CantInviteYourSelf: 'Non puoi invitare te stesso come cliente',
      Categories: 'Categorie',
      Characters: 'caratteri',
      ChooseCustomer: 'Seleziona un cliente',
      ChooseHour: 'Scegli Ora',
      ChooseMinutes: 'Scegli Minuti',
      chooseOrCreateIndustry: 'Seleziona o aggiungi un settore',
      ChooseOrCreateService: 'Scegli o Crea Servizio',
      CreateService: 'Crea Servizio',
      ChooseOrCreateServiceCategory: 'Scegli o Crea Categoria Servizio',
      ChooseServiceCategory: 'Scegli Categoria Servizio',
      ChooseService: 'Seleziona un servizio',
      ChooseServiceProvider: 'Scegli Fornitore di Servizi',
      ChooseStaff: 'Seleziona un membro dello staff',
      City: 'Città',
      Close: 'Chiuso',
      ClosedDayAdded: 'Giorni di chiusura aggiunti con successo.',
      ClosedDays: 'Giorni Chiusura',
      ClosedDayUpdated: 'Giorni di chiusura aggiornati con successo.',
      Code: 'Codice paese',
      Companies: 'Aziende',
      Company: 'Azienda',
      CompanyDeleted: 'Azienda eliminata con successo',
      CompanyEmail: 'Inserisci l\'email aziendale',
      CompanyName: 'Nome azienda',
      CompanyOverview: 'Scrivi una breve descrizione della tua azienda',
      Confirm: 'Conferma',
      ConfirmActionMassagesDelete: 'Sei sicuro di voler rimuovere questa azienda?',
      ConfirmActionMassagesDeleteError: 'Non puoi rimuovere questa azienda perché ha appuntamenti futuri.',
      ConfirmActionMassagesLeave: 'Sei sicuro di voler lasciare l\'azienda?',
      ConfirmCancel: 'Conferma Annullamento',
      ConfirmDelete: 'Conferma Eliminazione',
      ConfirmDeletion: 'Conferma Eliminazione',
      ConfirmPassword: 'Conferma password',
      continueDeleting: 'Continuare con l\'eliminazione?',
      continueWithFacebook: 'Continua con Facebook',
      Country: 'Paese',
      CodeInvalidError: 'Il codice deve essere di 6 cifre.',
      Create: 'Crea',
      CreateAppointment: 'Crea Appuntamento',
      CurrentPassword: 'Password attuale',
      Customer: 'Cliente',
      'Customer Name': 'Nome Cliente',
      CustomerAdded: 'Cliente aggiunto',
      CustomerDeleted: 'Cliente eliminato con successo',
      CustomerDeleteText: 'Eventuali appuntamenti con questi clienti verranno annullati.',
      CustomerDetails: 'Dettagli Cliente',
      CustomerPreview: 'Anteprima Cliente',
      Customers: 'Clienti',
      CustomerUpdated: 'Cliente aggiornato con successo',
      companyImageDesc:
        'Come azienda puoi aggiungere personale, assegnare servizi e creare appuntamenti.',
      Daily: 'Giornaliero',
      Date: 'Data',
      DateAndTime: 'Data & Ora',
      DatePicker: 'Selettore Data',
      DateTime: 'Data/Ora',
      DayAdded: 'Giorno(i) libero(i) aggiunto(i) con successo',
      DayOfBirth: 'Data di nascita',
      DayOffDeleted: 'Giorno(i) libero(i) eliminato(i) con successo.',
      DaysOff: 'Giorni Liberi',
      DayUpdated: 'Giorno(i) libero(i) aggiornato(i) con successo.',
      Decline: 'Rifiuta',
      DefaultView: 'Visualizzazione calendario predefinita',
      Delete: 'Elimina',
      DeleteCategoryText: 'Se elimini questa categoria di servizio, tutti i servizi ad essa associati verranno eliminati.',
      deleteMultipleStaffText: 'Eventuali appuntamenti con questo personale verranno annullati.',
      Description: 'Descrizione',
      Details: 'Dettagli',
      Digit: 'cifra',
      DontGetCode: 'Non hai ricevuto il codice?',
      DontHaveAnAccount: 'Non hai un account?',
      DownloadAndSignUp: 'Scarica l\'app e registrati ora!',
      Duration: 'Durata',
      Edit: 'Modifica',
      EditAppointment: 'Modifica Appuntamento',
      EditCategory: 'Modifica Categoria',
      EditCustomer: 'Modifica Cliente',
      EditService: 'Modifica Servizio',
      EditSpecificWorkDays: 'Modifica Giorni Lavorativi Specifici',
      EditStaff: 'Modifica Personale',
      Email: 'Email',
      EmailOptional: 'Email (opzionale)',
      EmailRequired: 'L\'email è obbligatoria.',
      EmailResent: 'Email inviata nuovamente con successo.',
      EmailValid: 'Inserisci un indirizzo email valido.',
      EndDate: 'Data fine',
      EndDateRequired: 'La data di fine è obbligatoria.',
      EndTime: 'Ora fine',
      EndTimeAfter: 'L\'ora di fine deve essere successiva all\'ora di inizio.',
      EnterCodeText: 'Inserisci il codice a 6 cifre inviato al tuo',
      EnterCustomerEmail: 'Inserisci email cliente',
      EnterCustomerFirstName: 'Inserisci nome cliente',
      EnterCustomerLastName: 'Inserisci cognome cliente',
      EnterCustomerPhoneNumber: 'Inserisci numero di telefono cliente',
      EnterSignInDetailsText: 'Inserisci i tuoi dati per accedere.',
      EnterStaffFirstName: 'Inserisci nome del personale',
      EnterStaffLastName: 'Inserisci cognome del personale',
      EnterStaffPhoneNumber: 'Inserisci numero di telefono del personale',
      EnterStaffProfession: 'Inserisci professione del personale',
      Facebook: 'URL pagina Facebook',
      FasterAndMoreOrganizedText: 'Rendiamo il processo di prenotazione più veloce e organizzato.',
      Female: 'Femmina',
      FileNotSupported: 'Il file non è supportato',
      Filter: 'Filtra',
      FinalPriceText: 'Definisci il prezzo finale per questo servizio.',
      Finish: 'Termina',
      FinishAppointment: 'Termina Appuntamento',
      Finished: 'Completato',
      'First Visit': 'Prima Visita',
      FirstName: 'Nome',
      FirstNameOrLastNameRequired: 'Nome o cognome obbligatorio',
      Fixed: 'Fisso',
      FollowUp: 'Follow-up',
      ForgotPassword1: 'Password dimenticata?',
      ForgotPassword2: 'Recupera password',
      Free: 'Gratuito',
      Fri: 'Ven',
      Friday: 'Venerdì',
      From: 'Da',
      Gallery: 'Galleria',
      Gender: 'Genere',
      General: 'Informazioni Generali',
      GeneralSchedule: {
        BreakHours: 'Orario di pausa',
        Title: 'Orario Generale',
        WeekDays: 'Giorni lavorativi',
        WorkingHours: 'Orario di lavoro',
      },
      H: 'h',
      haveOneStaff: 'È richiesto almeno 1 membro del personale.',
      Hour: 'Ora',
      ImageDeleted: 'Immagine eliminata con successo',
      IndustriesNotSaved: 'Dettagli settore non salvati.',
      Industry: 'Settore',
      IndustryDeleted: 'Settore eliminato con successo.',
      IndustryNotFound: 'Settore non trovato.',
      IndustryUpdated: 'Settore aggiornato con successo.',
      Info: 'Info',
      InfoGeneral: 'Informazioni Generali',
      InfoLinks: 'Link',
      InfoLocation: 'Posizione',
      InfoTitle: 'Informazioni',
      Instagram: 'Handle Instagram',
      InvalidData: 'Dati non validi.',
      InvalidLoginOrPass: 'Login o Password non validi.',
      Invitation: 'Invito',
      InvitationAccepted: 'Invito accettato con successo',
      InvitationDeclined: 'Invito rifiutato.',
      InvitationDeleted: 'Invito eliminato con successo',
      InvitationSent: 'Invito inviato.',
      InvitationUpdated: 'Invito aggiornato con successo',
      Invite: 'Invita',
      InviteCustomer: 'Invita a Registrarsi',
      InviteCustomerDescription: 'Invita <0>{{name}}</0> a registrarsi su SetTime.',
      Invited: 'Inviato',
      InviteToRegister: 'Invita a registrarsi',
      IsNotValid: ' non è valido',
      IsRequired: ' è obbligatorio',
      IsTooLong: ' è troppo lungo',
      individualImageDesc:
        'Come privato puoi offrire servizi e creare appuntamenti per i tuoi clienti.',
      Language: 'Lingua',
      'Last Visit': 'Ultima Visita',
      LastName: 'Cognome',
      LastNameOptional: 'Cognome (opzionale)',
      Leave: 'Esci',
      LeaveCompany: 'Esci dall\'azienda.',
      LoadDraft: 'Carica Bozza',
      Loading: 'Caricamento',
      Location: 'Posizione',
      Logout: 'Esci',
      Lowercase: 'minuscolo',
      lowercasePhoneNumber: 'Numero di telefono',
      MaintenanceTime: 'Tempo di manutenzione',
      Male: 'Maschio',
      Manager: 'Manager',
      Min: 'min',
      Mon: 'Lun',
      Monday: 'Lunedì',
      Monthly: 'Mensile',
      MyAppointments: 'Appuntamenti',
      MyCompany: 'La Mia Azienda',
      MyProfile: 'Il Mio Profilo',
      Name: 'Nome',
      New: 'Nuovo',
      NewAppointment: 'Nuovo Appuntamento',
      NewCategory: 'Nuova Categoria',
      NewCompany: 'Nuova Azienda',
      NewCustomer: 'Nuovo Cliente',
      NewPassMustBeDifferentText: 'La nuova password deve essere diversa da quella precedente',
      NewPassword: 'Nuova password',
      NewService: 'Nuovo Servizio',
      NewStaff: 'Nuovo Personale',
      Next: 'Avanti',
      No: 'No',
      NoAppointments: 'Nessun appuntamento ancora',
      NoNotifications: 'Nessuna notifica ancora',
      NoOptions: 'Nessuna Opzione',
      Note: 'Nota',
      Notes: 'Note appuntamento',
      Notification: 'Notifica',
      NotificationCanceled: 'Notifica i clienti quando il loro appuntamento viene annullato',
      NotificationConfirmed: 'Notifica i clienti quando il loro appuntamento viene confermato',
      NotificationRescheduled: 'Notifica i clienti quando il loro appuntamento viene riprogrammato',
      NotRegistered: 'Non registrato',
      Off: 'Off',
      On: 'On',
      Open: 'Aperto',
      Order: 'Ordine di Prenotazione',
      Password: 'Password',
      PasswordRequired: 'La password è obbligatoria.',
      PasswordSettings: 'Cambia Password',
      PasswordMinLength: 'Almeno 8 caratteri',
      PasswordLowercase: 'Contiene una lettera minuscola.',
      PasswordUppercase: 'Contiene una lettera maiuscola.',
      PasswordSpecial: 'Contiene un numero o carattere speciale.',
      PasswordLengthRule: 'La password deve contenere almeno 8 caratteri',
      PasswordCapitalRule: 'La password deve contenere almeno 1 lettera maiuscola',
      PasswordMismatch: 'Le password non coincidono',
      PastDate: 'Consenti creazione di appuntamenti in date passate',
      Pending: 'In Attesa',
      Canceled: 'Annullato',
      Request: 'Su Richiesta',
      NoShow: 'Assente',
      Permalink: 'Permalink',
      PersonalInformation: 'Informazioni Personali',
      Phone: 'Telefono',
      PhoneCode: 'Codice paese',
      PhoneNumber: 'Numero di Telefono',
      PhoneNumberOptional: 'Numero di Telefono (opzionale)',
      Position: 'Posizione',
      Price: 'Prezzo',
      PriceType: 'Tipo di prezzo',
      Pricing: 'Prezzi',
      Profession: 'Professione',
      ProfessionOptional: 'Professione (opzionale)',
      Range: 'Intervallo',
      ReadAll: 'Leggi Tutto',
      Registered: 'Registrato',
      RemindCustomer: 'Ricorda al cliente',
      Reminder: 'Promemoria',
      Resend: 'Reinvia',
      ResendCode: 'Reinvia Codice',
      ResetPassword: 'Reimposta Password',
      RowsPerPage: 'Righe per pagina',
      Sat: 'Sab',
      Saturday: 'Sabato',
      Save: 'Salva',
      SaveDraft: 'Salva Bozza',
      SaveDraftText: 'Vuoi salvare questo appuntamento come bozza?',
      SearchByCustomerOrStaff: 'Cerca per cliente o personale',
      SearchCustomer: 'Cerca per nome cliente',
      SearchService: 'Cerca per nome servizio',
      SearchStaff: 'Cerca per nome personale',
      SelectedDayNotWork: 'Il giorno selezionato non è lavorativo',
      Send: 'Invia',
      Service: 'Servizio',
      'Service Name': 'Nome Servizio',
      ServiceBased: 'Basato su Servizio',
      ServiceCategory: 'Categoria Servizio',
      ServiceDeleted: 'Servizio eliminato con successo.',
      ServiceDeletionText: 'Questo servizio non sarà disponibile per le prenotazioni e eventuali appuntamenti verranno annullati.',
      ServiceDuration: 'Durata del servizio',
      ServiceNotFound: 'Servizio non trovato.',
      ServiceProvider: 'fornitore di servizi',
      Services: 'Servizi',
      Settings: 'Impostazioni',
      ShortPassword: 'Password troppo corta.',
      ShowLess: 'Mostra Meno',
      ShowMore: 'Mostra Più',
      ShowOnWidget: 'Mostra nel Widget',
      ShowOnWidgetTooltipText: 'Il servizio sarà disponibile per la prenotazione online.',
      SignIn: 'Accedi',
      SlotDuration: 'Durata slot calendario',
      SomethingWrong: 'Qualcosa è andato storto.',
      Source: 'Fonte',
      SpecificDayAdded: 'Giorno(i) lavorativo(i) specifico(i) aggiunto(i) con successo.',
      SpecificDayDeleted: 'Giorno(i) lavorativo(i) specifico(i) eliminato(i) con successo.',
      SpecificDaysTitle: 'Giorni Lavorativi Specifici',
      SpecificDayUpdated: 'Giorno(i) lavorativo(i) specifico(i) aggiornato(i) con successo.',
      SpecificWorkDayDeleted: 'Giorno(i) lavorativo(i) specifico(i) eliminato(i) con successo.',
      Staff: 'Personale',
      'Staff Name': 'Nome Personale',
      StaffBased: 'Basato su Personale',
      StaffDetails: 'Dettagli Personale',
      StaffNotFind: 'Personale non trovato!',
      StaffPreview: 'Anteprima Personale',
      Staffs: 'Personale',
      StartDate: 'Data inizio',
      StartTime: 'Ora inizio',
      StartTimeEarlier: 'L\'ora di inizio deve essere precedente all\'ora di fine.',
      StartTimeRequired: 'L\'ora di inizio è obbligatoria.',
      State: 'Stato/Provincia',
      Statistics: 'Statistiche',
      Status: 'Stato',
      Sun: 'Dom',
      Sunday: 'Domenica',
      signUp: 'Registrati',
      signUpWith: 'Oppure Registrati Con',
      signUpWithEmail: 'Registrati con Email',
      TableHeader: {
        Actions: 'Opzioni',
        BreakTime: 'Pausa',
        Date: 'Date',
        Description: 'Descrizione',
        Staff: 'Personale',
        Time: 'Orario di lavoro',
      },
      ThankYou: 'Invia un messaggio di ringraziamento al cliente',
      ThisMonth: 'Questo mese',
      ThisServiceHas: 'Questo servizio ha ',
      ThisWeek: 'Questa settimana',
      Thu: 'Gio',
      Thursday: 'Giovedì',
      Time: 'Ora',
      TimeZone: 'Fuso orario',
      Tin: 'Partita IVA',
      Title: 'Titolo',
      To: 'A',
      Today: 'Oggi',
      Tomorrow: 'Domani',
      Total: 'Totale',
      TotalPayments: 'Ricavi',
      Tue: 'Mar',
      Tuesday: 'Martedì',
      Upcoming: 'Imminenti',
      UpcomingAppointments: 'Appuntamenti Imminenti',
      UpcomingAppointmentServiceModalText: ' appuntamento(i) imminenti.',
      upcomingAppointmentWith: 'Gli appuntamenti imminenti con',
      Update: 'Aggiorna',
      UpdatedSuccess: 'Aggiornato con successo.',
      Uppercase: 'maiuscolo',
      UserNotBreak: 'L\'utente è in pausa nelle ore selezionate',
      UserNotWork: 'L\'utente non lavora nelle ore selezionate',
      ValidAppointment: 'Campi appuntamento validi',
      VerificationCode: 'Codice di verifica',
      VerifyCode: 'Verifica Codice',
      Visits: 'Visite',
      WalkIn: 'Walk-in',
      WalkInDesc: 'Scegli walk-in se vuoi saltare la selezione del cliente.',
      WebSite: 'URL sito web aziendale',
      Wed: 'Mer',
      Wednesday: 'Mercoledì',
      Weekly: 'Settimanale',
      WeWillSendVerificationCode: 'Ti invieremo un codice di verifica a 6 cifre.',
      EnterVerificationCode: 'Inserisci il codice a 6 cifre inviato al tuo ',
      willBeCancelled: ' verrà annullato.',
      WithDrawInvitation: 'Annulla Invito',
      'Work Schedule': 'Orario di lavoro',
      WorkDateEndTimeRequired: 'L\'orario di fine giornata lavorativa è obbligatorio.',
      WorkDateStartTimeRequired: 'L\'orario di inizio giornata lavorativa è obbligatorio.',
      WorkdayEnd: 'Fine giornata lavorativa',
      WorkdayStart: 'Inizio giornata lavorativa',
      WorkingScheduleTab: {
        Company: 'Azienda',
        General: 'Generale',
        Staff: 'Personale',
      },
      WorkSchedule: 'Orario Lavorativo',
      whatIsTypeBusiness: 'Seleziona il tipo di attività',
      Yes: 'Sì',
      ZipCode: 'CAP',
      timeOff: 'Tempo Libero',
      editTimeOff: 'Modifica Tempo Libero',
      newTimeOff: 'Nuovo Tempo Libero',
      timeOffDescription: 'Blocca fasce orarie specifiche quando non sei disponibile, evitando prenotazioni in quei periodi.',
      startTimeRequired: 'L\'orario di inizio è obbligatorio',
      startTimeInvalid: 'L\'orario di inizio deve essere precedente a quello di fine.',
      endTimeRequired: 'L\'orario di fine è obbligatorio',
      endTimeInvalid: 'L\'orario di fine deve essere successivo a quello di inizio',
      timeOffCreatedSuccess: 'Tempo libero creato con successo.',
      timeOffCreatedFailure: 'Errore durante la creazione del tempo libero',
      timeOffUpdatedSuccess: 'Tempo libero aggiornato con successo.',
      timeOffUpdatedFailure: 'Errore durante l\'aggiornamento del tempo libero',
      titleOptional: 'Titolo (opzionale)',
      TimeOffDeleteModalText: 'Sei sicuro di voler eliminare questo tempo libero?',
      TimeOffDeleted: 'Tempo libero eliminato con successo',
    },
  },
};

export default resources;
