import React, {
  useState, useImperativeHandle, forwardRef, useEffect,
} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormHelperText } from '@material-ui/core';
import { useSelector } from 'react-redux';

const CustomPhone = forwardRef(({ handleChange, initialCode, initialNumber }, ref) => {
  const { t } = useTranslation();
  const { companyGot } = useSelector((state) => state.company);
  const [phone, setPhone] = useState(initialNumber || '');
  const [isValid, setIsValid] = useState(true);
  const [countryData, setCountryData] = useState(null);
  const [defaultCountry, setDefaultCountry] = useState(companyGot?.country_code.toLowerCase() ?? 'us');

  useEffect(() => {
    if (initialCode) {
      setDefaultCountry(initialCode.toLowerCase());
    }
  }, [initialCode]);

  const onChange = (value, data) => {
    const code = `+${data.dialCode}`;
    setPhone(value);
    setCountryData(data);
    setIsValid(true); // reset validation

    handleChange('phone_code', code);
    handleChange('phone_number', value);
  };

  useImperativeHandle(ref, () => ({
    validatePhone() {
      const fullNumber = `+${phone}`;
      let valid = false;

      try {
        const parsed = parsePhoneNumberFromString(fullNumber, countryData?.countryCode);
        valid = parsed?.isValid() ?? false;
      } catch {
        valid = false;
      }

      setIsValid(valid);
      handleChange('phone_valid', valid);
      return valid;
    },
  }));

  return (
    <div>
      <PhoneInput
        country={defaultCountry}
        value={phone}
        onChange={onChange}
        placeholder={t('PhoneNumberOptional')}
        inputStyle={{ borderColor: isValid ? '#ccc' : 'red' }}
      />
      {!isValid && (
        <FormHelperText error>
          {t('lowercasePhoneNumber') + t('IsNotValid')}
        </FormHelperText>
      )}
    </div>
  );
});

CustomPhone.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initialCode: PropTypes.string,
  initialNumber: PropTypes.string,
};

export default CustomPhone;
