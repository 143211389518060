import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  AppBar,
  Button,
  Toolbar,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Logo from 'assets/img/logo.svg';
import industriesLogo from 'assets/industriesLogo';
import Loading from 'Components/Loading/loading';
import { getIndustriesRequest } from 'redux/industry/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FavouriteIndustry = (props) => {
  const {
    getIndustries,
  } = props;
  const classes = useStyles();
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const loading = false;
  const industryOptions = [];

  useEffect(() => {
    getIndustries();
  }, []);

  const handleIndustrySelectChange = (event) => {
    setSelectedIndustryOption(event.target.value);
  };

  return !loading ? (
    <Box className="auth">
      <Container>
        <AppBar color="transparent" position="static" elevation={0} className="login-header">
          <Toolbar>
            <Box mx="auto" className="auth-logo">
              <img src={Logo} alt="Logo" />
            </Box>
          </Toolbar>
        </AppBar>
        <Grid container justify="center">
          <Grid item direction="row" justify="center" md={6} sm={9}>
            <div className="auth-box">
              <Box display="flex" height="100%">
                <Box alignSelf="center" mx="auto">
                  <Box px="40px" py="30px">
                    <Box mb="30px">
                      <Typography align="center" variant="h4">Choose your favourite industries</Typography>
                    </Box>
                    <Grid container spacing={2} alignItems="center" xs={12} className="personal-container">
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-mutiple-chip-label">Industries</InputLabel>
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={selectedIndustryOption.value}
                          onChange={handleIndustrySelectChange}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(industryOptions) => (
                            <div className={classes.chips}>
                              {industryOptions.map((option) => (
                                <Chip key={option} label={option} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {industryOptions.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.value}
                            >
                              <span className="option-logo">
                                <img src={industriesLogo[option.label]} alt="industryLogo" />
                              </span>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" width="100%" mt="22px">
                      <Box alignSelf="center">
                        <Link to="/personalDetails">
                          <Button
                            color="secondary"
                            startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} color="secondary" />}
                          >
                            Back
                          </Button>
                        </Link>
                      </Box>
                      <div>
                        <Button size="small" className="primary-btn" color="primary" variant="contained" data-cy="submit" type="submit">
                          Next
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  ) : <Loading />;
};

FavouriteIndustry.propTypes = {
  getIndustries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // Get all Industries
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteIndustry);
